import React from 'react';
import { Link } from 'react-router-dom';
import Logos3 from '../../components/logos/logos-3';
import Box from '../../layout/box';
import StandardInsert from '../../layout/standard-insert';
import { getRulesRoute } from '../routes';
import './style.scss';


export default function HomePage() {

    return (
        <div className='page home-page'>
            <Box>
                <StandardInsert>
                    <h1>Halo{'\u00a0'}halo, słyszymy{'\u00a0'}się?</h1>
                    <p>Tu Ryjówka, najmniejszy kapitan planety Ziemia. Parę minut temu nawiązaliśmy kontakt z naukowcem z przyszłości - Elektro3000. Elektro przekazuje:</p>
                    <p className="quote">To ostatni moment, by zmienić bieg historii oraz zadbać o piękny, zielony Świat. Wierzę, że to w Waszych rękach leży przyszłość, dlatego właśnie Wam powierzam wyjątkową misję. Misję, w której trzeba zadbać o najmniejszych, bezbronnych mieszkańców lasu, troszczyć się o siebie nawzajem oraz o przyrodę. Redukować ilość wytwarzanych śmieci oraz je segregować, by mogły być odpowiednio przetworzone.</p>
                    <div className="mobile-container">
                        <p className="quote">Przesyłam Wam wyjątkową mapę, która pomoże Wam zdobyć 4 bohaterskie odznaki umiejętności - PRAWIDŁOWO SEGREGUJĘ ODPADY!, WIEM CO TO RECYKLING!, NIE PORZUCAM ŚMIECI W LESIE! oraz CHRONIĘ MIESZKAŃCÓW LASU! Za każde wykonane zadanie otrzymasz ciekawy element, który pozwoli Ci stworzyć wyjątkową, Twoją własną leśną polanę. Postawisz huśtawki, wybierzesz rośliny i zwierzęta, które zagoszczą w tym niepowtarzalnym miejscu. Do zobaczenia na trasie!</p>
                        <p>Kapitan Ryjówka zgłasza się do misji, a Ty, ruszasz z nami?</p>
                        <nav className="buttons">
                            <Link className="button start-button" to={getRulesRoute()} >Rozpocznij misję!</Link>
                        </nav>
                    </div>
                </StandardInsert>
            </Box>
            <Box className='bg-white no-padding'>
                <Logos3 />
            </Box>
        </div>

    )
}
