import React, { useMemo } from "react";
import PlaceholderWidget from "./tasks/placeholder";
import Task101Widget from "./tasks/task-101";
import Task102Widget from "./tasks/task-102";
import Task103Widget from "./tasks/task-103";
import Task104Widget from "./tasks/task-104";
import Task105Widget from "./tasks/task-105";
import Task201Widget from "./tasks/task-201";
import Task202Widget from "./tasks/task-202";
import Task203Widget from "./tasks/task-203";
import Task204Widget from "./tasks/task-204";
import Task205Widget from "./tasks/task-205";
import Task301Widget from "./tasks/task-301";
import Task302Widget from "./tasks/task-302";
import Task303Widget from "./tasks/task-303";
import Task304Widget from "./tasks/task-304";
import Task305Widget from "./tasks/task-305";
import Task401Widget from "./tasks/task-401";
import Task402Widget from "./tasks/task-402";
import Task403Widget from "./tasks/task-403";
import Task404Widget from "./tasks/task-404";
import Task405Widget from "./tasks/task-405";

type Props = {
    id: string,
    onComplete: ()=>void
}

const TaskWidget: React.FC<Props> = ({id, onComplete}) => {

    const TaskWidget = useMemo(() => {

        if (!id) {
            return <></>
        }

        switch (id) {
            case 'widget-101':
                return <Task101Widget onComplete={onComplete} />;
            case 'widget-102':
                return <Task102Widget onComplete={onComplete} />;
            case 'widget-103':
                return <Task103Widget onComplete={onComplete} />;
            case 'widget-104':
                return <Task104Widget onComplete={onComplete} />;
            case 'widget-105':
                return <Task105Widget onComplete={onComplete} />;


            case 'widget-201':
                return <Task201Widget onComplete={onComplete} />;
            case 'widget-202':
                return <Task202Widget onComplete={onComplete} />;
            case 'widget-203':
                return <Task203Widget onComplete={onComplete} />;
            case 'widget-204':
                return <Task204Widget onComplete={onComplete} />;
            case 'widget-205':
                return <Task205Widget onComplete={onComplete} />;


            case 'widget-301':
                return <Task301Widget onComplete={onComplete} />;
            case 'widget-302':
                return <Task302Widget onComplete={onComplete} />;
            case 'widget-303':
                return <Task303Widget onComplete={onComplete} />;
            case 'widget-304':
                return <Task304Widget onComplete={onComplete} />;
            case 'widget-305':
                return <Task305Widget onComplete={onComplete} />;


            case 'widget-401':
                return <Task401Widget onComplete={onComplete} />;
            case 'widget-402':
                return <Task402Widget onComplete={onComplete} />;
            case 'widget-403':
                return <Task403Widget onComplete={onComplete} />;
            case 'widget-404':
                return <Task404Widget onComplete={onComplete} />;
            case 'widget-405':
                return <Task405Widget onComplete={onComplete} />;

            default:
                return <PlaceholderWidget onComplete={onComplete} />;
        }
    }, [id, onComplete]);

    return TaskWidget;
}

export default TaskWidget;
