import { GameConfig } from '../game';
import * as PIXI from 'pixi.js-legacy';
import { AlbumButton } from './album-button';
import { Sticker } from './sticker';
import { Board } from './board';
import { TrashBin } from './trash-bin';


const ALBUM_COLOR = 0xf4eec2;
const ALBUM_BORDER_COLOR = 0xbf4c1c;
const ALBUM_HEIGHT = 250;
const ALBUM_BORDER = 10;
const ALBUM_WIDTH = 1130;

const ALBUM_BUTTON_SIZE = 165;
const BUTTONS_ON_LIST = 5;

const ALBUM_Y = 850;
const BUTTON_SPACESING = 25;

const URLS = {
    BOARD: '/assets/generator/polana.png',
    TRASH_OPEN: '/assets/generator/bin.png'
}

export default class BaordWithStickers extends PIXI.Application {
    public baseWidth: number;
    public baseHeight: number;

    private board?: Board;

    private trash?: TrashBin;

    private isDragging: boolean = false

    public resources?: Partial<Record<string, PIXI.LoaderResource>>;

    constructor(width: number, height: number, private config: GameConfig) {
        super({
            width,
            height,
            transparent: true,
            antialias: true
        });

        this.renderer.plugins.interaction.autoPreventDefault = false;
        this.renderer.view.style.touchAction = 'auto';

        this.baseHeight = height;
        this.baseWidth = width;

        this.loadTextures().then((resources) => {
            this.resources = resources;
            this.initGame()
        })

        this.renderer.view.addEventListener('touchmove', (e) => {
            if (this.isDragging) {
                e.preventDefault();
            }
        })
    }

    public reset() {
        this.stickers.forEach(s => s.destroy())
        this.stickers = [];

        this.sendOnChange();
    }

    private loadTextures(): Promise<Partial<Record<string, PIXI.LoaderResource>>> {
        return new Promise((resolve) => {
            this.config.objects.forEach(o => {
                this.loader.add(o.key, o.src)
                this.loader.add(`icon_${o.key}`, o.iconSrc);
            });

            this.loader.add('castle', URLS.BOARD)
            this.loader.add('trash_open', URLS.TRASH_OPEN)

            this.loader.load((loader, resource) => {
                resolve(resource)
            })

            this.loader.onError.add((...args: any[]) => {
                console.error(...args);
            })
        })
    }

    private initGame() {

        if (this.resources?.logo) {
            const logo = new PIXI.Sprite(this.resources.logo.texture);
            logo.anchor.set(1, 0);
            logo.x = this.baseWidth - 75;
            logo.y = 0;
            logo.scale.set(175 / logo.width);
            this.stage.addChild(logo)
        }

        this.initTrash();
        this.initAlbum();
        this.initBoard();
        this.initStickers();
        this.initTooltip();

        /* DEV */
        // this.getSceenBlob();

    }

    private initTooltip() {
        this.stage.addChild(this.tooltip);
        this.hideTooltip();
    }
    private initTrash() {
        if (this.resources) {
            this.trash = new TrashBin({
                overTexture: PIXI.Texture.from('trash_open'),
                normalTexture: PIXI.Texture.from('trash_open')
            });
            this.stage.addChild(this.trash);

            this.trash.x = this.baseWidth - 380;
            // this.trash.height = ALBUM_HEIGHT;
            this.trash.y = ALBUM_Y + 20;
        }
    }

    private initBoard() {
        if (this.resources?.castle) {
            this.board = new Board({
                texture: this.resources.castle.texture,
                onChange: () => this.sendOnChange()
            })

            this.board.y = 0;
            this.board.x = 0;

            this.stage.addChild(this.board);
        }
    }

    private albumContainer?: PIXI.Graphics;
    private albumList?: PIXI.Container;
    private arrowLeft?: PIXI.Graphics;
    private arrowRight?: PIXI.Graphics;

    private initAlbum() {


        const albumWidth = ALBUM_WIDTH;
        const listWidth = BUTTONS_ON_LIST * ALBUM_BUTTON_SIZE + BUTTON_SPACESING * (BUTTONS_ON_LIST - 1);
        const arrowWidth = (albumWidth - listWidth) / 2;

        this.albumContainer = new PIXI.Graphics();
        this.albumContainer.height = ALBUM_HEIGHT;
        this.albumContainer.width = albumWidth;
        this.albumContainer.y = ALBUM_Y;
        this.albumContainer.x = 210;

        this.albumContainer.beginFill(ALBUM_BORDER_COLOR);
        this.albumContainer.drawRoundedRect(0, 0, ALBUM_WIDTH, ALBUM_HEIGHT, 20);
        this.albumContainer.beginFill(ALBUM_COLOR);
        this.albumContainer.drawRoundedRect(ALBUM_BORDER, ALBUM_BORDER, ALBUM_WIDTH - 2 * ALBUM_BORDER, ALBUM_HEIGHT - 2 * ALBUM_BORDER, 10);
        this.albumContainer.endFill();

        this.arrowLeft = new PIXI.Graphics();

        this.arrowLeft.beginFill(ALBUM_BORDER_COLOR);
        this.arrowLeft.drawPolygon([-27, 0, 24, -29, 24, 29]);
        this.arrowLeft.endFill();

        this.arrowLeft.interactive = true;
        this.arrowLeft.cursor = 'pointer';
        this.arrowLeft.on('pointertap', this.scrollLeft);

        this.arrowLeft.position.set(275, ALBUM_Y + ALBUM_HEIGHT / 2);

        this.arrowRight = new PIXI.Graphics();

        this.arrowRight.beginFill(ALBUM_BORDER_COLOR);
        this.arrowRight.drawPolygon([27, 0, -24, -29, -24, 29]);
        this.arrowRight.endFill();

        this.arrowRight.interactive = true;
        this.arrowRight.cursor = 'pointer';
        this.arrowRight.on('pointertap', this.scrollRight);

        this.arrowRight.position.set(ALBUM_WIDTH + 150, ALBUM_Y + ALBUM_HEIGHT / 2);

        if (this.resources) {

            this.albumList = new PIXI.Container();
            this.albumList.width = listWidth;
            this.albumList.height = ALBUM_HEIGHT;
            this.albumList.x = 0;
            this.albumList.y = 0;

            const listMask = new PIXI.Graphics();

            listMask.x = arrowWidth;
            listMask.y = 0;
            listMask.beginFill(0x000000);
            listMask.drawRect(0, 0, listWidth, ALBUM_HEIGHT)
            listMask.endFill();

            const listWindow = new PIXI.Container();
            listWindow.width = listWidth;
            listWindow.height = ALBUM_HEIGHT;
            listWindow.x = arrowWidth;
            listWindow.y = 0;

            this.albumList.mask = listMask;
            this.albumContainer.addChild(listMask);
            this.albumContainer.addChild(listWindow);
            listWindow.addChild(this.albumList);

            this.config.objects
                .sort((a, b) => {
                    return b.isAvaiable ? 1 : -1;
                })
                .forEach((o, index) => {

                    //@ts-ignore
                    const buttonTexture: Texture = this.resources[`icon_${o.key}`]?.texture

                    //@ts-ignore
                    const stickerTexture: Texture = this.resources[o.key]?.texture

                    if (buttonTexture) {
                        const button = new AlbumButton(
                            buttonTexture,
                            ALBUM_BUTTON_SIZE / 2 + ((ALBUM_BUTTON_SIZE + BUTTON_SPACESING) * index),
                            ALBUM_HEIGHT / 2,
                            o.key,
                            o.isAvaiable,
                            ALBUM_BUTTON_SIZE
                        );

                        if (o.isAvaiable) {
                            button.on('pointerdown', (event: PIXI.InteractionEvent) => {
                                this.addSticker(stickerTexture, button.x, button.y, o.key, event);
                                this.isDragging = true;
                            });
                        } else {
                            button.on('pointermove', (e: PIXI.InteractionEvent) => {
                                if (this.isTooltipShowed) {
                                    this.moveTooltip(e.data.global.x, e.data.global.y);
                                }
                            })
                            button.on('pointerover', (e: PIXI.InteractionEvent) => {
                                this.showTooltip(o.unavaialbeLabel, e.data.global.x, e.data.global.y)
                            })
                            button.on('pointerout', () => {
                                this.hideTooltip();
                            })
                        }

                        if (this.albumList) {
                            this.albumList.addChild(button);
                        }
                    }
                });
        }

        this.stage.addChild(this.albumContainer);

        this.stage.addChild(this.arrowRight);
        this.stage.addChild(this.arrowLeft);
        this.updateArrows();
    }

    private isRightAvailable = () => {
        if (this.albumList) {
            return (this.config.objects.length - BUTTONS_ON_LIST) * ALBUM_BUTTON_SIZE > -this.albumList.x;
        }

        return false;
    }

    private isLeftAvailable = () => {
        if (this.albumList) {
            return (this.albumList.x < 0);
        }

        return false;
    }

    private scrollRight = () => {
        if (this.albumList) {
            this.albumList.x -= ALBUM_BUTTON_SIZE + BUTTON_SPACESING;
            this.updateArrows();
        }
    }

    private scrollLeft = () => {
        if (this.albumList) {
            this.albumList.x += ALBUM_BUTTON_SIZE + BUTTON_SPACESING;
            this.updateArrows();
        }
    }

    private updateArrows() {
        if (this.arrowRight) {
            this.arrowRight.visible = this.isRightAvailable();
        }

        if (this.arrowLeft) {
            this.arrowLeft.visible = this.isLeftAvailable();
        }
    }

    private stickers: Sticker[] = [];

    private addSticker(t: PIXI.Texture, x: number, y: number, key: string, createEvent?: PIXI.InteractionEvent) {
        if (this.board) {
            const sprite = new Sticker(t, x, y, key, createEvent)
            sprite
                .on('pointerdown', this.onDragStart)
                .on('pointerup', this.onDragEnd)
                .on('pointerupoutside', this.onDragEnd)
                .on('pointermove', this.onDrag)

            this.board.addChild(sprite);

            this.stickers.push(sprite);
        }
    }

    private destroySticker(sticker: Sticker) {
        this.stickers = this.stickers.filter(s => s !== sticker);

        sticker.destroy();
    }

    private onDrag = (e: PIXI.InteractionEvent) => {
        if (!this.draggedSticker) return;

        const x = e.data.global.x;
        const y = e.data.global.y;

        if (this.trash?.dropzone.contains(x, y)) {
            this.trash?.drawOver();
        } else {
            this.trash?.drawNormal();
        }
    }

    private draggedSticker?: PIXI.DisplayObject | null;

    private onDragStart = (e: PIXI.InteractionEvent) => {
        this.isDragging = true;
        this.draggedSticker = e.currentTarget
    }

    private onDragEnd = (e: PIXI.InteractionEvent) => {
        this.isDragging = false;
        this.draggedSticker = null;


        if (this.trash?.dropzone.contains(e.data.global.x, e.data.global.y)) {
            this.destroySticker(e.currentTarget as Sticker);
            this.trash.drawNormal();
        }

        this.sendOnChange();
    }

    private sendOnChange = () => {

        const stickers = this.stickers.map(o => {
            return {
                key: o.key,
                position: {
                    y: o.y,
                    x: o.x
                }
            }
        })

        if (this.board) {
            this.config.onChange({
                stickers
            })
        } else {
            this.config.onChange({ stickers })
        }

    }

    private initStickers() {
        //@ts-ignore
        this.config.state.stickers.map(s => this.addSticker(this.resources[s.key]?.texture, s.position.x, s.position.y, s.key))
    }

    public destroy() {
        super.destroy(true);
    }

    public async getSceenBlob(): Promise<string | HTMLCanvasElement> {
        if (this.board) {
            const boardCanvas = this.renderer.plugins.extract.canvas(this.board);

            const canvas = document.createElement('canvas');
            canvas.width = 1778;
            canvas.height = 806;
            const ctx = canvas.getContext('2d')
            ctx?.drawImage(boardCanvas, 0, -this.board.getYCorrection());

            return canvas
        } else {
            return '';
        }
    }

    private tooltip = new PIXI.Text('ssssss', { fontFamily: 'Arial', fontSize: 24, fill: 0xff1010, align: 'center' });
    private isTooltipShowed = false;

    private showTooltip(text: string, x: number, y: number) {
        // this.isTooltipShowed = true;
        // this.tooltip.text = text;

        // this.tooltip.x = x;
        // this.tooltip.y = y;
    }

    private moveTooltip(x: number, y: number) {
        this.tooltip.x = x;
        this.tooltip.y = y;
    }

    private hideTooltip() {
        this.isTooltipShowed = false;
        this.moveTooltip(-9999, -9999);
    }
}

