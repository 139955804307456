import React, { useMemo } from 'react';
import BottomBox from '../../layout/bottom-box';
import './style.scss';


type Props = {
    distance: number,
    approaching: true | false | null
}

const Compass: React.FC<Props> = ({ distance, approaching }) => {

    const hotCold = useMemo(() => {
        if (approaching === null) {
            return '';
        }

        return approaching ? 'hot' : 'cold'
    }, [approaching])

    console.log({approaching})


    return <BottomBox className={`compass ${hotCold}`}>
        {approaching !== null && <div className="approaching">
            <p className={`approaching ${hotCold}`}>{approaching ? 'CIEPŁO!' : 'ZIMNO!'}</p>
        </div>}
        <p><span>Jesteś</span> <span className={`distance ${hotCold}`}>{Math.round(distance * 1000)}<span className="unit">m</span></span> <span>od celu</span></p>
    </BottomBox>

}

export default Compass;
