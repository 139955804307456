
import { useEffect, useRef } from 'react';
import StandardInsert from '../../../../layout/standard-insert';
import createWidget from './create-widget';
import './style.scss';

type Props = {
    onComplete?: () => void
}

const Task305Widget: React.FC<Props> = ({onComplete}) => {

    const widgetContainer = useRef<HTMLDivElement>(null);

    useEffect(() => {

        const gameConfig = {
            container: widgetContainer.current
        }

        const { destroy } = createWidget(gameConfig)

        return () => {
            destroy();
        }

    }, []);

    useEffect(() => {
        if (onComplete) {
            onComplete();
        }
    }, [onComplete])

    return <div className="task-305">
        <StandardInsert>
            <div ref={widgetContainer} className="gameContainer" style={{ width: '100%', margin: '0 auto', userSelect: 'none' }}></div>
        </StandardInsert>
    </div>
}

export default Task305Widget;
