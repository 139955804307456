import * as PIXI from 'pixi.js-legacy';

const grayscale = new PIXI.filters.ColorMatrixFilter();
grayscale.greyscale(0.2, false);



export class AlbumButton extends PIXI.Container {
    constructor(
        texture: PIXI.Texture,
        public x: number,
        public y: number,
        public key: string,
        isAvaiable = true,
        size = 170
    ) {
        super();

        this.interactive = true;
        this.buttonMode = true;

        const background = new PIXI.Graphics();
        background.beginFill(0x979846);
        background.drawRect(-size / 2, -size / 2, size, size);
        background.endFill();

        this.addChild(background);

        const sprite = new PIXI.Sprite(texture);
        sprite.anchor.set(0.5);
        sprite.scale.set(Math.min((size - 10) / texture.height, (size - 10) / texture.width, 1));

        this.addChild(sprite);

        if (!isAvaiable) {
            this.filters = [grayscale];
        }



    }
}
