import { useCallback } from "react";
import { useGeoGameModuleDispatch, GEO_GAME_ACTION } from "..";

export default function useRestartGame() {

    const dispatch = useGeoGameModuleDispatch();

    return useCallback(() => {
        dispatch({
            type: GEO_GAME_ACTION.RESTART,
            payload: {}
        });
    }, [dispatch])

}
