
import React, { useEffect, useReducer, useRef, useState } from 'react';
import StandardInsert from '../../../../layout/standard-insert';
import Popup from '../../../popup';
import createWidget from './create-widget';
import './style.scss';

type Props = {
    onComplete?: () => void
}

const Task401Widget: React.FC<Props> = ({ onComplete: completeCallback }) => {

    const widgetContainer = useRef<HTMLDivElement>(null);
    const [popup, setPopup] = useState<{
        show: boolean,
        text?: string,
        img1?: string,
        img2?: string
    }>({ show: false })

    const [state, addOne] = useReducer((state) => {
        return state + 1;
    }, 0)

    useEffect(() => {

        const gameConfig = {
            container: widgetContainer.current,
            onGroupSelected: (message: string, img1: string, img2: string) => {
                addOne();
                setPopup({
                    show: true,
                    text: message,
                    img1,
                    img2
                })
            }
        }

        const { destroy } = createWidget(gameConfig);

        return () => {
            destroy();
        }
    }, [setPopup, addOne])

    useEffect(() => {
        if (completeCallback && state === 5) {
            completeCallback();
        }
    }, [state, completeCallback])

    return <div className="task-401">
        <StandardInsert>
            <div ref={widgetContainer} className="gameContainer" style={{ width: '100%', margin: '0 auto', userSelect: 'none' }}></div>
        </StandardInsert>

        {
            popup.show && <Popup onClick={() => { setPopup({ show: false }) }}>
                <div className="popup-thumbnails">
                    <div className="img-container">
                        <div className="img" style={{
                            backgroundImage: `url(${popup.img1})`
                        }}></div>
                    </div>
                    <div className="img-container">
                        <div className="img" style={{
                            backgroundImage: `url(${popup.img2})`
                        }}></div>
                    </div>
                </div>
                <p dangerouslySetInnerHTML={{ __html: popup.text || '' }}></p>
            </Popup>
        }
    </div>
}

export default Task401Widget;
