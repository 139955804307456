
import React from 'react';
import { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import StandardInsert from '../../../../layout/standard-insert';
import Popup from '../../../popup';
import './style.scss';

type Props = {
    onComplete?: () => void
}

type Item = {
    id: string,
    src: string,
    groupId: string
    locked: boolean
}

type Group = {
    id: string,
    message: string
}

enum PHASE {
    NO_SELECTED = 'no-selected',
    ONE_SELECTED = 'one-selected'
}

type GameState = {
    items: Item[],
    groups: Group[],
    phase: PHASE,
    selected: string | null,
}

const Task105Widget: React.FC<Props> = ({ onComplete: completeCallback }) => {

    const initState = useMemo<GameState>(() => {
        return {
            items: [
                {
                    id: '1',
                    src: '/assets/tasks/task-105/img-1.png',
                    groupId: 'g2',
                    locked: false
                },
                {
                    id: '2',
                    src: '/assets/tasks/task-105/img-2.png',
                    groupId: 'g1',
                    locked: false
                },
                {
                    id: '3',
                    src: '/assets/tasks/task-105/img-3.png',
                    groupId: 'g3',
                    locked: false
                },
                {
                    id: '4',
                    src: '/assets/tasks/task-105/img-4.png',
                    groupId: 'g4',
                    locked: false
                },
                {
                    id: '5',
                    src: '/assets/tasks/task-105/img-5.png',
                    groupId: 'g2',
                    locked: false
                },
                {
                    id: '6',
                    src: '/assets/tasks/task-105/img-6.png',
                    groupId: 'g1',
                    locked: false
                },
                {
                    id: '7',
                    src: '/assets/tasks/task-105/img-7.png',
                    groupId: 'g3',
                    locked: false
                },
                {
                    id: '8',
                    src: '/assets/tasks/task-105/img-8.png',
                    groupId: 'g4',
                    locked: false
                }
            ].sort(() => (Math.random() - .5)),
            groups: [
                {
                    id: 'g1',
                    message: 'Zgadza się! Baterie oraz akumulatory składają się z metali ciężkich i substancji niebezpiecznych takich jak ołów, rtęć, kadm, nikiel. Pozostawione w lesie, zanieczyszczają glebę i wody powierzchniowe zatruwając rośliny oraz zwierzęta.'
                },
                {
                    id: 'g2',
                    message: 'Butelka szklana może zranić np. sarnę, a wabione zapachem zwierzęta wchodzą do jej środka, po czym nie mogą się z niej wydostać na zewnątrz. To dla nich śmiertelna pułapka!'
                },
                {
                    id: 'g3',
                    message: 'Pod butami słyszałeś trzask gałęzi, szelest suchych liści i ledwie wilgotnego mchu? To coraz częstsze zjawisko z powodu niewystarczającej ilości opadów. Ściółka jest przesuszona, a to sprawia, że bardzo łatwo jest doprowadzić do jej zapłonu. Pamiętaj, że pożary rozprzestrzeniają się w lesie w zawrotnym tempie i są bardzo trudne do ugaszenia. Niosą ze sobą ogromne szkody i cierpienie zwierząt.'
                },
                {
                    id: 'g4',
                    message: 'Zastanawiałeś się kiedyś, dlaczego w lasach nie ma koszy? Nieświadome zagrożenia zwierzęta są wabione do nich zapachem, następnie roznoszą śmieci po lesie, często je zjadając. Zagrożenie stanowią zarówno opakowania, jak i pozostawione w nich resztki jedzenia. Warto wiedzieć, że to co jedzą ludzie może być niebezpieczne dla zwierząt.'
                }
            ],
            selected: null,
            phase: PHASE.NO_SELECTED
        }
    }, []);

    const [state, userSelectItem] = useReducer((state: GameState, action: { type: 'select' | 'reset', id?: string }) => {

        switch (action.type) {
            case 'select':
                if (state.phase === PHASE.NO_SELECTED) {
                    return {
                        ...state,
                        selected: action.id || null,
                        phase: PHASE.ONE_SELECTED
                    }
                }

                if (state.phase === PHASE.ONE_SELECTED) {
                    return {
                        ...state,
                        items: state.items.map(i => {
                            if (i.id === action.id || i.id === state.selected) {
                                return {
                                    ...i,
                                    locked: true
                                }
                            }

                            return i;
                        }),
                        selected: null,
                        phase: PHASE.NO_SELECTED
                    }
                }

                break;

            case 'reset':
                return {
                    ...state,
                    selected: null,
                    phase: PHASE.NO_SELECTED
                }
        }


        return state;
    }, initState);

    const [popup, setPopup] = useState<{
        show: boolean,
        text?: string,
        img1?: string,
        img2?: string
    }>({ show: false });

    const onItemClicked = useCallback((id: string) => {
        if (state.phase === PHASE.NO_SELECTED) {
            userSelectItem({ type: 'select', id });
        } else {
            const item = state.items.find(i => i.id === id);
            const selected = state.items.find(i => i.id === state.selected);

            if (item && selected) {

                if (item.id === selected.id) {
                    return;
                }

                if (item.groupId === selected.groupId) {
                    userSelectItem({ type: 'select', id });

                    const group = state.groups.find(g => g.id === item.groupId);
                    setPopup({
                        show: true,
                        text: group?.message || '',
                        img1: item.src,
                        img2: selected.src
                    })
                } else {
                    userSelectItem({ type: 'reset' });
                }
            }
        }
    }, [state, userSelectItem]);

    useEffect(() => {
        const complete = state.items.reduce((prev, next) => {
            return prev && next.locked;
        }, true);

        if (complete) {
            if (completeCallback) {
                completeCallback();
            }
        }
    }, [state, completeCallback]);


    return <div className="task-105">
        <StandardInsert>


            <div className="items-list">
                {
                    state.items.map(i => {
                        return <div key={i.src} className={`widget-item ${i.locked ? 'locked' : ''} ${i.id === state.selected ? 'selected' : ''}`} onClick={() => onItemClicked(i.id)}>
                            <div className="wrapper">
                                <div className="wrapper-2">
                                    <img src={i.src} alt="" />
                                </div>
                            </div>
                        </div>
                    })
                }
            </div>

            {
                popup.show && <Popup onClick={() => { setPopup({ show: false }) }}>
                    <div className="images">
                        <img src={popup.img1} alt="" />
                        <img src={popup.img2} alt="" />
                    </div>
                    <p dangerouslySetInnerHTML={{ __html: popup.text || '' }}></p>
                </Popup>
            }
        </StandardInsert>

    </div>
}

export default Task105Widget;
