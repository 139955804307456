import React from "react";
import { useParams } from "react-router";
import Logos3 from "../../components/logos/logos-3";
import TaskComponent from "../../components/task";
import Box from "../../layout/box";
import useTask from "../../modules/tasks/hooks/use-task";

export default function TaskPage() {
    const { id } = useParams<{ id: string }>()
    const { task, isLoading } = useTask(id);

    return <div className="page task-page">
        <Box>
            {task && !isLoading && <TaskComponent data={task} />}
        </Box>
        <Box className='bg-white no-padding'>
            <Logos3 />
        </Box>
    </div>
}
