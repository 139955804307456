export function getHomeRoute() {
    return '/'
}

export function getLoginRoute() {
    return '/logowanie';
}

export function getRegisterRoute() {
    return '/rejestracja';
}

export function getRulesRoute() {
    return '/zasady';
}

export function getMapRoute() {
    return '/mapa';
}

export function getTaskRoute(id: string = ':id') {
    return `/zadanie/${id}`
}

export function getGeoGameRoute() {
    return `/gra`;
}

export function getInfoPage1Route() {
    return '/segregacja_odpadow'
}

export function getInfoPage2Route() {
    return '/recycling'
}

export function getInfoPage3Route() {
    return '/smieci_zagrozenie'
}

export function getGeneratorRoute() {
    return '/polana'
}

export function getEndGameRoute() {
    return '/koniec-gry'
}

export function getAwardRoute(taskId: string = ':taskId') {
    return `/nagroda/${taskId}`;
}

export function getAchievementRoute(taskId: string = ':taskId') {
    return `/odznaka/${taskId}`;
}
