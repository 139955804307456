import * as PIXI from 'pixi.js-legacy';

type Params = {
    normalTexture?: PIXI.Texture;
    overTexture?: PIXI.Texture;
}

export class TrashBin extends PIXI.Container {

    private gfx = new PIXI.Graphics();
    private sprite = new PIXI.Sprite();
    private _dropzone = new PIXI.Rectangle();
    public get dropzone() {
        this.getBounds(false, this._dropzone);
        return this._dropzone;
    }
    constructor(private params?: Params) {
        super();
        this.addChild(this.gfx);
        this.drawNormal();
        this.interactive = true;

        this.addChild(this.sprite);
    }
    public drawNormal() {
        this.gfx.clear();
        if (this.params?.normalTexture) {
            this.sprite.texture = this.params.normalTexture
        } else {
            this.gfx.beginFill(0x979846);
            this.gfx.drawRoundedRect(0, 0, 220, 142, 10);
            this.gfx.endFill();
        }
    }
    public drawOver() {
        this.gfx.clear();
        if (this.params?.overTexture) {
            this.sprite.texture = this.params.overTexture;
        } else {
            this.gfx.beginFill(0xbf4c1c);
            this.gfx.drawRoundedRect(0, 0, 220, 142, 10);
            this.gfx.endFill();
        }
    }
}
