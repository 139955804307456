import useGeoTask from "../../../../../../modules/game/hooks/use-geo-task";
import './style.scss';

type Props = {
    id: string,
    onClick: () => void
}

const TrashInForest: React.FC<Props> = ({id, onClick}) => {

    const task = useGeoTask(id)

    return <div className="trash-in-forest">
        <img src={task?.image} onClick={onClick} alt=""/>
    </div>
}

export default TrashInForest;

