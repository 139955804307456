import * as PIXI from 'pixi.js-legacy';
import { WidgetConfig } from '../create-widget';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import GameItem from './item';

export type Item = {
    id: string,
    asset: string,
    group: string,
    x: number,
    y: number,
    scale: number,
}

type Group = {
    id: string,
    message: string,
    img1: string,
    img2: string
}

type GameState = {
    items: Item[],
    groups: Group[],
    selectedItem: string | null,
    locked: string[]
}

export default class TaskWidget extends PIXI.Application {
    public baseWidth: number;
    public baseHeight: number;

    private state: BehaviorSubject<GameState> = new BehaviorSubject({
        items: [
            {
                id: 'folia',
                asset: 'item-1',
                x: 930,
                y: 460,
                scale: .4,
                group: 'g1'
            },
            {
                id: 'puszka',
                asset: 'item-10',
                x: 160,
                y: 180,
                scale: .4,
                group: 'g1'
            },
            {
                id: 'makulatura',
                asset: 'item-5',
                x: 1070,
                y: 220,
                scale: .5,
                group: 'g2'
            },
            {
                id: 'jajka',
                asset: 'item-4',
                x: 420,
                y: 265,
                scale: .4,
                group: 'g2'
            },
            {
                id: 'pet',
                asset: 'item-9',
                x: 820,
                y: 750,
                scale: .4,
                group: 'g3'
            },
            {
                id: 'polar',
                asset: 'item-8',
                x: 320,
                y: 550,
                scale: .5,
                group: 'g3'
            },
            {
                id: 'sloik',
                asset: 'item-3',
                x: 680,
                y: 200,
                scale: .4,
                group: 'g4'
            },
            {
                id: 'butelka',
                asset: 'item-2',
                x: 75,
                y: 750,
                scale: .4,
                group: 'g4'
            },
            {
                id: 'szklo',
                asset: 'item-7',
                x: 560,
                y: 700,
                scale: .5,
                group: 'g5'
            },
            {
                id: 'przejscie',
                asset: 'item-6',
                x: 1140,
                y: 750,
                scale: .6,
                group: 'g5'
            }
        ],
        groups: [
            {
                id: 'g1',
                message: 'Czy wiesz, że aluminium jest świetnym materiałem do recyklingu, gdyż jego odzysk można prowadzić w nieskończoność?',
                img1: 'item-10',
                img2: 'item-1'
            },
            {
                id: 'g2',
                message: 'Zastanawiałeś się jak wygląda proces recyklingu papieru? Po posegregowaniu makulatury do niebieskiego pojemnika lub worka  na papier, trafia ona do sortowni odpadów, gdzie jest doczyszczana i dzielona pod względem jakości.  Następnie trafia do papierni, gdzie odbywa się jej recykling. Najpierw poddawana jest rozwłóknieniu, następnie zostaje oczyszczona z zanieczyszczeń takich jak piasek, zszywki, czy kleje, a w przypadku recyklingu makulatury do produkcji papieru gazetowego poddaje się ją odbarwianiu i bieleniu. W procesie recyklingu wykorzystuję się wodę, bąble powietrza, mydło i różne dodatki chemiczne.',
                img1: 'item-5',
                img2: 'item-4'
            },
            {
                id: 'g3',
                message: 'Z butelek pet mogą powstać też wypełnienia poduszek, namioty, nowe butelki i worki na śmieci.',
                img1: 'item-9',
                img2: 'item-8'
            },
            {
                id: 'g4',
                message: 'Opakowania szklane świetnie nadają się do recyklingu. Powstają z nich nowe butelki i słoiki, ponadto stłuczka szklana używana jest też w budownictwie.',
                img1: 'item-2',
                img2: 'item-3'
            },
            {
                id: 'g5',
                message: 'Stłuczka szkła może być poddana procesowi recyklingu w 100%, a jej przetworzenie jest wyjątkowo wszechstronne. Jako drobny materiał - mączka, może być dodana do farby wykorzystywanej do malowania pasów w celu poprawienia ich widoczności w nocy. Powstaje z niej również cement, który po wymieszaniu z piaskiem może zostać wykorzystany do stabilizowania podłoża pod ścieżkami rowerowymi czy alejkami parkowymi.',
                img1: 'item-7',
                img2: 'item-6'
            }
        ],
        locked: [],
        selectedItem: null
    } as GameState);

    private assets: [string, string][] = [
        ['strings', '/assets/tasks/task-401/strings.svg'],
        ['item-1', '/assets/tasks/task-401/item-1.png'],
        ['item-2', '/assets/tasks/task-401/item-2.png'],
        ['item-3', '/assets/tasks/task-401/item-3.png'],
        ['item-4', '/assets/tasks/task-401/item-4.png'],
        ['item-5', '/assets/tasks/task-401/item-5.png'],
        ['item-6', '/assets/tasks/task-401/item-6.png'],
        ['item-7', '/assets/tasks/task-401/item-7.png'],
        ['item-8', '/assets/tasks/task-401/item-8.png'],
        ['item-9', '/assets/tasks/task-401/item-9.png'],
        ['item-10', '/assets/tasks/task-401/item-10.png'],
    ]

    constructor(width: number, height: number, private config: WidgetConfig) {
        super({
            width,
            height,
            transparent: true,
            antialias: true
        });

        this.baseHeight = height;
        this.baseWidth = width;

        this.loadAssets(this.assets).then((resources) => {
            this.initWidget();
        })
    }


    private initWidget() {
        const strings = PIXI.Sprite.from('strings');
        strings.width = this.baseWidth;
        strings.height = this.baseHeight;

        this.stage.addChild(strings);


        this.state.value.items.forEach((itemData) => {
            const item = new GameItem(
                itemData,
                this.state.pipe(
                    map(state => {
                        return state.selectedItem === itemData.id;
                    })
                ),
                this.state.pipe(
                    map(state => state.locked.includes(itemData.id))
                )
            )

            item.on('pointerdown', () => {
                this.selectItem(itemData.id);
            })

            item.position.set(itemData.x, itemData.y);
            this.stage.addChild(item);
        })
    }

    private selectItem(id: string) {
        const state = this.state.value;

        this.state.next((() => {
            if (state.selectedItem === null) {
                return {
                    ...state,
                    selectedItem: id
                };
            } else {
                if (id === state.selectedItem) {
                    return { ...state }
                }

                const prevItem = state.items.find(i => i.id === state.selectedItem);
                const item = state.items.find(i => i.id === id);

                if (!prevItem || !item) {
                    console.warn('Coś poszło nie tak', { prevItem, item });
                    return { ...state };
                }

                if (prevItem.group === item.group) {

                    const group = state.groups.find(g => g.id === item.group);

                    if (group) {
                        this.config.onGroupSelected(
                            group?.message,
                            this.assets.find(([key,]) => key === group.img1)?.[1] || '',
                            this.assets.find(([key,]) => key === group.img2)?.[1] || ''
                        )
                    }

                    return {
                        ...state,
                        locked: [...state.locked, prevItem.id, item.id],
                        selectedItem: null
                    }
                } else {
                    return {
                        ...state,
                        selectedItem: null
                    }
                }
            }
        })());
    }


    private loadAssets(assets: [string, string][]) {

        return new Promise(resolve => {
            assets.forEach(assetInfo => {
                this.loader.add(...assetInfo);
            })

            this.loader.load((loader, resource) => {
                resolve(null);
            })

            this.loader.onError.add((...args: any[]) => {
                console.error(...args);
            })

        })

    }

    public destroy(removeView: boolean) {
        super.destroy(removeView);
    }

}
