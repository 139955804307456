import CenterBox from "../../../layout/center-box";
import './style.scss';

type Props = {
    onNext: () => void
}

const CameraPermissionDeniedPopup: React.FC<Props> = ({ onNext }) => {
    return <div className="camera-error">
        <CenterBox>
            <p>Nie wyraziłeś zgody na udostępnienie kamery. Aby zmienić ustawienia, wejdź w ustawienia przeglądarki, następnie w ustawienia witryny i wyraź zgodę na udostępnienie aparatu.</p>
            <p> Na koniec odśwież stronę i zobacz grę w rozszerzonej rzeczywistości.</p>

            <button className="button" onClick={onNext}>Dalej</button>
        </CenterBox>
    </div>

}

export default CameraPermissionDeniedPopup;
