import * as PIXI from 'pixi.js-legacy';
import { WidgetConfig } from '../create-widget';

export default class TaskWidget extends PIXI.Application {
    public baseWidth: number;
    public baseHeight: number;

    constructor(width: number, height: number, private config: WidgetConfig) {
        super({
            width,
            height,
            transparent: true,
            antialias: true
        });

        this.baseHeight = height;
        this.baseWidth = width;

        this.loadAssets([
            ['image', '/assets/tasks/task-305/img.png'],
            ['layer', '/assets/tasks/task-305/layer.png'],
        ]).then((resources) => {
            this.initWidget();
        })
    }

    private layer?: PIXI.Sprite;

    private maskCanvas = document.createElement('canvas');

    private initWidget() {
        const image = PIXI.Sprite.from('layer');
        this.layer = PIXI.Sprite.from('image');

        const maskBuffer = new PIXI.Sprite();
        maskBuffer.width = this.baseWidth;
        maskBuffer.height = this.baseHeight;


        this.maskCanvas.width = this.baseWidth;
        this.maskCanvas.height = this.baseHeight;

        // document.body.appendChild(this.maskCanvas);

        const texture = PIXI.Texture.from(this.maskCanvas);
        const ctx = this.maskCanvas.getContext('2d');

        let isDrawing = false;
        const lastPos = new PIXI.Point();

        this.stage.interactive = true;

        this.stage.on('pointerdown', (e: PIXI.InteractionEvent) => {
            lastPos.copyFrom(e.data.getLocalPosition(this.stage));
            isDrawing = true;
        });

        this.stage.on('pointerup', () => {
            isDrawing = false;
        });

        this.stage.on('pointermove', (e: PIXI.InteractionEvent) => {

            if (isDrawing) {
                const pos = e.data.getLocalPosition(this.stage).clone();

                if (ctx) {
                    ctx.beginPath();
                    ctx.moveTo(lastPos.x, lastPos.y);
                    ctx.lineTo(pos.x, pos.y);
                    ctx.strokeStyle = 'white';
                    ctx.lineWidth = 50;
                    ctx.stroke();
                }

                lastPos.copyFrom(pos);
            }

            texture.update();
        });


        const test = PIXI.Sprite.from(texture);

        this.layer.mask = test;
        this.stage.addChild(image);
        this.stage.addChild(this.layer);
        this.stage.addChild(maskBuffer);
        maskBuffer.position.set(0, 300);
        this.stage.addChild(test);
    }


    private loadAssets(assets: any[]) {

        return new Promise(resolve => {
            assets.forEach(assetInfo => {
                this.loader.add(...assetInfo);
            })

            this.loader.load((loader, resource) => {
                resolve(null);
            })

            this.loader.onError.add((...args: any[]) => {
                console.error(...args);
            })

        })

    }

    public destroy(removeView: boolean) {
        super.destroy(removeView);
    }

}
