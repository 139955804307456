type Props = {
    className?: string | undefined
}

const BottomBox: React.FC<Props> = ({children, className}) => {
    return <div className={`bottom-box ${className}`}>
        {children}
    </div>
}

export default BottomBox;
