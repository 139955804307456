import makeStore from "@mborecki/react-create-store";
import React from "react";
import ListenersComponent from "./listeners-componen";
import reducer from "./reducer";
import { GEO_MODULE_ERROR } from "./types";



export type GeoModuleState = {
    position?: {
        lat: number,
        lng: number
    },
    accuracy?: number,
    error: GEO_MODULE_ERROR | null
}

const initGeoModuleState = {
    error: null
}

export enum GEO_MODULE_ACTION {
    SET_DATA,
    SET_ERROR
}

export type GeoModuleAction = {
    type: GEO_MODULE_ACTION,
    payload?: any
}

const [
    GeoModuleProvider,
    useGeoModuleDispatch,
    useGeoModuleState
] = makeStore(reducer, initGeoModuleState)

const Provider: React.FC<{}> = ({ children }) => {

    return <GeoModuleProvider>
        <ListenersComponent>
            {children}
        </ListenersComponent>
    </GeoModuleProvider>
}


export {
    Provider as GeoModuleProvider,
    useGeoModuleDispatch,
    useGeoModuleState
}
