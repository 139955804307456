import React from "react";
import { Task } from "../../modules/tasks/hooks/use-tasks";
import './style.scss';

type Props = {
    task: Task,
    onMapClick: () => void,
    onGeneratorClick: () => void
}

const AwardInfo: React.FC<Props> = ({ task, onMapClick, onGeneratorClick }) => {

    return <div className="award-info">
        <h1>Zadanie wykonane</h1>
        <p>Świetnie Ci poszło! Odblokowałeś nowy element leśnej polany! Przejdź do kolejnego zadania lub trybu projektowania własnej polany!</p>
        <div className="row">
            <div className="awards">
                {task.awards.map(a => {
                    const src = `/assets/generator/awards/award-${a}.png`;

                    return <div className="award" key={a}>
                        <div className="img">
                            <img src={src} alt="" />
                        </div>
                    </div>
                })}
            </div>
            <div className="buttons">
                <button className="button" onClick={onMapClick}>Wróć do mapy</button>
                <button className="button" onClick={onGeneratorClick}>Projektuj polanę</button>
            </div>
        </div>
    </div>
}

export default AwardInfo;
