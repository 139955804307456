import React, { useMemo, useState } from "react";
import { useCallback } from "react";
import { useHistory, useParams } from "react-router";
import AchievementInfo from "../../components/achievements-info";
import AwardInfo from "../../components/award-info";
import Logos3 from "../../components/logos/logos-3";
import Box from "../../layout/box";
import StandardInsert from "../../layout/standard-insert";
import useTask from "../../modules/tasks/hooks/use-task";
import { getGeneratorRoute, getMapRoute } from "../routes";
import './style.scss';

export default function AwardPage() {

    const { taskId } = useParams<{ taskId: string }>();
    const { task } = useTask(taskId);

    const history = useHistory();

    const [showAward, setShowAward] = useState(true);
    const [showAchievement, setShowAchievement] = useState(false);

    const achievement = useMemo(() => {
        switch (task?.achievements[0]) {
            case 'achievement-1':
                return {
                    title: 'Nie porzucam śmieci w lesie',
                    description: '<p>Czapki z głów, mówi Kapitan Ryjówka! Ze zdobytą odznaką umiejętności NIE&nbspPORZUCAM&nbspŚMIECI&nbspW&nbspLESIE możesz ruszać śmiało na spacer! Do zobaczenia! Może spotkamy się na którejś z leśnych ścieżek!</p>',
                    src: `/assets/achievements/achievement-1.png`
                }
            case 'achievement-2':
                return {
                    title: 'Chronię mieszkańców lasu',
                    description: '<p>Leśne zwierzęta odetchnęły z ulgą! Mają swojego nowego bohatera! Idąc do lasu na spacer, pamiętaj czego dowiedziałeś się z mapy i noś dumnie odznakę CHRONIĘ&nbspMIESZKAŃCÓW&nbspLASU!</p>',
                    src: `/assets/achievements/achievement-2.png`
                }
            case 'achievement-3':
                return {
                    title: 'Prawidłowo segreguję odpady',
                    description: '<p>Super! Zdobyłeś umiejętność PRAWIDŁOWO&nbspSEGREGUJĘ&nbspODPADY! Przestrzegaj poznanych zasad oraz ucz innych, jak robić to prawidłowo!</p>',
                    src: `/assets/achievements/achievement-3.png`
                }
            case 'achievement-4':
                return {
                    title: 'Wiem co to recykling',
                    description: '<p>Ekstra, wiesz już naprawdę dużo o przetwarzaniu odpadów! Odznaka umiejętności WIEM&nbspCO&nbspTO&nbspRECYKLING jest Twoja!</p>',
                    src: `/assets/achievements/achievement-4.png`
                }
        }
        return null;
    }, [task])

    const haveAchievement = task?.achievements.length > 0;
    const [nextUrl, setNextUrl] = useState('');

    const onGoToMapOrShowAchievement = useCallback(() => {
        if (haveAchievement) {
            setShowAward(false);
            setShowAchievement(true);
            setNextUrl(getMapRoute());
        } else {
            history.replace(getMapRoute());
        }
    }, [haveAchievement, history, setShowAward, setShowAchievement, setNextUrl]);

    const onGoToGeneratorOrShowAchievement = useCallback(() => {
        if (haveAchievement) {
            setShowAward(false);
            setShowAchievement(true);
            setNextUrl(getGeneratorRoute());
        } else {
            history.replace(getGeneratorRoute());
        }
    }, [haveAchievement, history, setShowAward, setShowAchievement, setNextUrl]);

    const onNext = useCallback(() => {
        if (nextUrl) {
            history.replace(nextUrl);
        }
    }, [nextUrl, history])

    return <div className="page award-page">

        {task && <Box className={(showAward && (task.awards.length === 1)) ? `extra-background` : ''}>
            {task && <StandardInsert>
                {showAward && <AwardInfo
                    onMapClick={onGoToMapOrShowAchievement}
                    onGeneratorClick={onGoToGeneratorOrShowAchievement}
                    task={task}
                />}
                {showAchievement && achievement && <AchievementInfo
                    achievement={achievement}
                    onNext={onNext}
                />}
            </StandardInsert>}
        </Box>}
        <Box className='bg-white no-padding'>
            <Logos3 />
        </Box>
    </div>
}
