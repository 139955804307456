
import { useCallback, useEffect, useRef } from 'react';
import StandardInsert from '../../../../layout/standard-insert';
import createWidget from './create-widget';
import './style.scss';

type Props = {
    onComplete?: () => void
}

const Task102Widget: React.FC<Props> = ({ onComplete: completeCallback }) => {

    const widgetContainer = useRef<HTMLDivElement>(null);

    const onComplete = useCallback(() => {
        if (completeCallback) {
            completeCallback();
        }
    }, [completeCallback]);

    useEffect(() => {
        const gameConfig = {
            container: widgetContainer.current,
            onComplete
        }

        const {destroy} = createWidget(gameConfig)

        return () => {
            destroy();
        }
    }, [onComplete])

    return <div className="task-102">
        <StandardInsert>
            <div ref={widgetContainer} className="widget-container"></div>
        </StandardInsert>
    </div>
}

export default Task102Widget;
