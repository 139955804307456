import { useCallback, useState } from "react";
import Bowser from "bowser";

const browser = Bowser.getParser(window.navigator.userAgent);
const isSafari = browser.satisfies({
    safari: '>=9'
})

// Reference for InputEvent: https://w3c.github.io/input-events/#interface-InputEvent
export const IS_INPUT_SUPPORTED = (function () {
    try {
        // just kill browsers off, that throw an error if they don't know
        // `InputEvent`
        const event = new InputEvent('input', {
            data: 'xyz',
            inputType: 'deleteContentForward'
        });
        let support = false;

        // catch the others
        // https://github.com/chromium/chromium/blob/c029168ba251a240b0ec91fa3b4af4214fbbe9ab/third_party/blink/renderer/core/events/input_event.cc#L78-L82
        const el = document.createElement('input');
        el.addEventListener('input', function (e) {
            //@ts-ignore
            if (e.inputType === 'deleteContentForward') {
                support = true;
            }
        });

        el.dispatchEvent(event);
        return support;
    } catch (error) {
        return false;
    }
})();

/**
* A normalized event from InputEvent and KeyboardEvent that works from ie11
* over modern browsers to android browsers (because that beast is worse than ie6)
*/
export interface CompatibleInputEvent {
    data?: string;
    inputType?: string;
    navigationType?: string;
    originalEvent: KeyboardEvent | Event;
}

export const normalizeInputEvent = function (event: KeyboardEvent | Event): CompatibleInputEvent {
    const e: CompatibleInputEvent = {
        originalEvent: event
    };

    if (event instanceof KeyboardEvent) {
        if (event.key === 'Backspace') {
            e.inputType = 'deleteContentBackward';
            e.navigationType = 'cursorLeft';
        } else if (event.key === 'Delete') {
            e.inputType = 'deleteContentForward';
        } else if (event.key.startsWith('Arrow')) {
            e.navigationType = event.key.replace('Arrow', 'cursor');
        } else {
            e.data = event.key;
            e.inputType = 'insertText';
        }
    } else {
        // @ts-ignore event.inputType is there on android - actually what we need here!
        const { inputType } = event;
        e.inputType = inputType;
        //@ts-ignore
        e.data = event.data;

        if (['insertText', 'insertCompositionText'].includes(inputType)) {
            e.navigationType = 'cursorRight';
        }
    }

    return e;
};

export default function useChainInput() {

    const [container, setContainer] = useState<HTMLElement | null>(null);

    const focusNext = useCallback((node: HTMLInputElement) => {
        if (container) {
            const inputs = container.querySelectorAll('input');

            const index = Array.from(inputs).indexOf(node);

            const nextInput = inputs[index + 1];

            if (nextInput) {
                nextInput.focus();
            }
        }
    }, [container]);

    const focusPrev = useCallback((node: HTMLInputElement) => {
        if (container) {
            const inputs = container.querySelectorAll('input');

            const index = Array.from(inputs).indexOf(node);

            const prevInput = inputs[index - 1];

            if (prevInput) {
                prevInput.focus();
            }
        }
    }, [container]);

    const handleEvent = useCallback((node: HTMLInputElement, e: CompatibleInputEvent) => {

        if (e.navigationType === 'cursorRight') {
            focusNext(node);
        }

        if (e.navigationType === 'cursorLeft') {
            focusPrev(node);
        }

        if (e.inputType === 'deleteContentBackward') {
            node.value = '';
        }

        //@ts-ignore
        if (isSafari && !e.navigationType && e.originalEvent.key.length === 1) {
            setTimeout(() => {
                focusNext(node);
                if (node.value.length) {
                    node.value = node.value[node.value.length - 1];
                }
            }, 100);


        } else {
            if (node.value.length) {
                node.value = node.value[node.value.length - 1];
            }
        }


    }, [focusNext, focusPrev]);

    const registerInput = useCallback((node: HTMLInputElement | null) => {
        if (node !== null) {

            node.addEventListener('keydown', (event: KeyboardEvent) => {
                const e = normalizeInputEvent(event);

                if (isSafari || !IS_INPUT_SUPPORTED || event.key.length > 1) {
                    handleEvent(node, e);
                }
            }, false);

            node.addEventListener('input', (event: Event) => {
                if (IS_INPUT_SUPPORTED) {
                    handleEvent(node, normalizeInputEvent(event));
                }
            }, false);
        }
    }, [handleEvent])

    const registerContainer = useCallback((node: HTMLElement | null) => {
        setContainer(node);
    }, [setContainer])

    return {
        registerInput,
        registerContainer,
        // afterLast,
        // value
    }
}

