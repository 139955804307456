import styled from "styled-components"
import useGame from "../../../../modules/game/hooks/use-game";
// import MapComponent from "../map";

type Props = {}

const Container = styled.div`
    position: absolute;
    top: 10%;
    left: 10%;
    right: 10%;
    bottom: 50%;

    z-index: 1000;

    background-color: pink;
`;

const DebugBox: React.FC<Props> = () => {

    const {distanceToNextPoint, lockingRange, isInRange, debug} = useGame();

    return <Container>
        distanceToNextPoint: {distanceToNextPoint}<br />
        lockingRange: {lockingRange} <br />
        isInRange: {isInRange}<br />
        newValue: {((debug.newValue || 0) * 1000).toFixed(2)}<br />
        oldValue: {((debug.oldValue || 0) * 1000)}<br />
        delta: {(((debug.newValue || 0) * 1000) - ((debug.oldValue || 0) * 1000)).toFixed(2)}<br />
        distanceBuffer: {JSON.stringify(debug.distanceBuffer.map(n => n.toFixed(4)))}

    </Container>
}

export default DebugBox;
