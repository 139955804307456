import { useCallback } from "react";
import { GEO_GAME_CONFIG_ACTION, useGeoGameConfigModuleDispatch } from "..";
import { GeoGameSettings } from "../../game/types";

export default function useSetSettings() {
    const dispatch = useGeoGameConfigModuleDispatch();

    return useCallback((settings: Partial<GeoGameSettings>) => {
        dispatch({
            type: GEO_GAME_CONFIG_ACTION.SET_SETTINGS,
            payload: {
                settings
            }
        })
    }, [dispatch])
}
