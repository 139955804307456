import { useCallback } from 'react';
import './style.scss';

type Props = {
    onClick: () => void
}

const Popup: React.FC<Props> = ({ children, onClick }) => {

    const onPopupClick = useCallback((e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        onClick();
    }, [onClick])

    return <div className="popup-overlay" onClick={onPopupClick}>
        <div className="popup-wrapper">
            {children}
        </div>
    </div>
}

export default Popup;
