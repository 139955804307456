import React from "react";
import GeoTrashTask from "./trash";
import './style.scss';
import useGeoTask from "../../../../../../modules/game/hooks/use-geo-task";
import { GeoTask } from "../../../../../../modules/game/types";
import AnimalWidget from "./animal";

type Props = {
    id: string,
    onComplete: () => void
}

const GeoTaskWidget: React.FC<Props> = ({ onComplete, id }) => {

    const task = useGeoTask(id);

    const showTrashTask = task?.type === 'trash';
    const showAnimalTask = task?.type === 'animal';
    const showErrorTask = !showTrashTask && !showAnimalTask;

    return <div>
        {showTrashTask && <GeoTrashTask onComplete={onComplete} task={task as GeoTask} />}
        {showAnimalTask && <AnimalWidget onComplete={onComplete} task={task as GeoTask} />}
        {showErrorTask && <div>
            <p>Wystapił błąd.</p>
            <p>Możesz iść dalej.</p>
            <button className="button" onClick={onComplete}>Dalej</button>
        </div>}
    </div>
}

export default GeoTaskWidget;
