import { GeoGameConfigAction, GeoGameConfigState, GEO_GAME_CONFIG_ACTION } from ".";

export default function GeoGameReducer(state: GeoGameConfigState, action: GeoGameConfigAction): GeoGameConfigState {

    switch (action.type) {
        case GEO_GAME_CONFIG_ACTION.SET_CONFIG:
            return {
                ...state,
                gameMode: action.payload.gameMode,
                config: {
                    ...state.config,
                    ...(action.payload.config || {})
                }
            }

        case GEO_GAME_CONFIG_ACTION.SET_SETTINGS:
            return {
                ...state,
                settings: {
                    ...state.settings,
                    ...(action.payload.settings || {})
                }
            }
    }

    return state;
}
