export enum VIEW {
    INTRO = 'intro',
    COMPASS = 'compass',
    CAMERA_INFO = 'camera-info',
    GEO_INFO = 'geo_info',
    TAKS = 'task'
}

export type GamePoint = {
    order: number,
    id: string,
    completed: boolean
    lat: number,
    lng: number
}

export type GeoTask = {
    id: string,
    name: string,
    intro: string,
    outro: string,
    type: 'trash' | 'animal',
    image: string,
    trashWidgetImage?: string
    correct?: string
}

export enum GEO_GAME_MODE {
    AROUND = 'around',
    LIPKOW = 'lipkow',

    DEV = 'dev'
}

export type GeoGameConfig = {
    pointsCount: number,
    distance: number,
    maxError: number
}

export type GeoGameSettings = {
    tryCamera: boolean
}
