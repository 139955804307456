import CenterBox from "../../layout/center-box"
import './style.scss';

type Props = {
    accuracy?: number
    limit: number
}

const NoPosition: React.FC<Props> = ({ accuracy, limit }) => {
    return <div className="no-position">
        <CenterBox>
            <h1>Zaczekaj chwilę, trwa kalibracja urządzenia!</h1>
            <p>Aby gra działała poprawnie, Twoje urządzenie musi udostępniać lokalizację z dokładnością przynajmniej <strong>{limit} metrów</strong>!</p>

            {accuracy && <p>Aktualna dokładność pomiaru <strong>{(accuracy || 0) | 0} metrów</strong>.</p>}
        </CenterBox>
    </div>
}

export default NoPosition;
