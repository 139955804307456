import React, { useEffect, useState } from "react";
import { GeoGameModuleProvider } from "./modules/game";
import useSetConfig from "./modules/config/hooks/use-set-config";
import { GeoGameConfig, GEO_GAME_MODE } from "./modules/game/types";
import { GeoModuleProvider } from "./modules/geo";
import GameScreen from "./screens/game";
import WelcomeScreen from "./screens/welcome";
import './style.scss';
import { GeoGameConfigModuleProvider } from "./modules/config";
import GameEnd from "./screens/game-end";

type Props = {
    mode: GEO_GAME_MODE
    config?: Partial<GeoGameConfig>
}

const GeoGraMainInner: React.FC<Props> = ({ mode, config }) => {

    const [scr, setScreen] = useState('welcome')
    const setConfig = useSetConfig();

    useEffect(() => {
        setConfig(mode, config || {})
    }, [setConfig, mode, config])

    const showWelcomeScreen = scr === 'welcome';
    const showGameScreen = scr === 'game';
    const showEndGameScreen = scr === 'end';

    return <main className="geo-game-main">
        {showWelcomeScreen && <WelcomeScreen onNext={() => setScreen('game')} />}
        {showGameScreen && <GeoModuleProvider>
            <GameScreen onGameEnded={() => {setScreen('end')}} />
        </GeoModuleProvider>
        }
        {showEndGameScreen && <GameEnd />}
    </main>
}

const GeoGraMain: React.FC<Props> = ({ mode, config }) => {
    return <GeoGameConfigModuleProvider><GeoGameModuleProvider>
        <GeoGraMainInner mode={mode} config={config} />
    </GeoGameModuleProvider></GeoGameConfigModuleProvider>
}


export default GeoGraMain
