import { useEffect } from "react";
import useGeoTask from "../../../../../../modules/game/hooks/use-geo-task";

type Props = {
    id: string,
    onNext: () => void,
    remainigPointsCount: number
}

const GeoTaskOutro: React.FC<Props> = ({ id, onNext, remainigPointsCount }) => {

    const geoTask = useGeoTask(id);

    useEffect(() => {
        if (remainigPointsCount === 0) {
            onNext();
        }
    }, [onNext, remainigPointsCount]);

    const {n, fst, snd} = getContent(remainigPointsCount);

    return <>
        {geoTask && <div className="geo-task-outro">
            <p>{fst}</p>
            <h1>{n}</h1>
            <p>{snd}</p>
            <div className="buttons">
                <button className='button' onClick={onNext}>Dalej</button>
            </div>
        </div>}
    </>
}

export default GeoTaskOutro;

function getContent(n: number) {
    if (n === 1) {
        return {
            n,
            fst: 'Został Ci jeszcze',
            snd: 'odpad do odpowiedniego posegregowania! Ruszaj dalej!'
        }
    } else if(n <= 4) {
        return {
            n,
            fst: 'Zostały Ci jeszcze',
            snd: 'odpady do odpowiedniego posegregowania! Ruszaj dalej!'
        }
    } else {
        return {
            n,
            fst: 'Zostało Ci jeszcze',
            snd: 'odpadów do odpowiedniego posegregowania! Ruszaj dalej!'
        }
    }
}
