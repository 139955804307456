import * as PIXI from 'pixi.js-legacy';
import { WidgetConfig } from '../create-widget';
import * as p2 from 'p2';

export default class TaskWidget extends PIXI.Application {
    public baseWidth: number;
    public baseHeight: number;

    private showDebug = false;

    private world = new p2.World({
        gravity: [398.1 * Math.cos(Math.PI * 0.2), 398.1 * Math.sin(Math.PI * 0.2)]
        // gravity: [0, 0]
    });

    constructor(width: number, height: number, private config: WidgetConfig) {
        super({
            width: width,
            height: height,
            transparent: true,
            antialias: true
        });

        this.renderer.plugins.interaction.autoPreventDefault = false;
        this.renderer.view.style.touchAction = 'auto';

        this.baseHeight = height;
        this.baseWidth = width;

        this.loadAssets([
            ['animal', '/assets/geo/animal/ryjowa2sama.png'],
            ['butelka', '/assets/geo/animal/butelka-1.png'],
            ['butelka-2', '/assets/geo/animal/butelka-2.png']
        ]).then((resources) => {
            this.initWidget();
        })

        this.animate();
    }

    private animalBody?: p2.Body;




    private animal = new PIXI.Container();

    private initWidget() {
        const butelka = PIXI.Sprite.from('butelka');
        this.stage.addChild(butelka);

        const animalMaterial = new p2.Material();
        const wallMaterial = new p2.Material();

        this.initAnimal(animalMaterial);

        [
            [[803, 399], [889, 305], [889, 399]],
            [[803, 486], [803, 399], [889, 449]],
            [[760, 499], [760, 484], [798, 489]],
            [[717, 484], [717, 449], [760, 484]],
            [[653, 449], [653, 399], [717, 449]],
            [[584, 449], [584, 369], [653, 399]],
            [[462, 399], [462, 364], [584, 369]],
            [[145, 364], [145, 221], [462, 364]],
            [[109, 221], [109, 152], [145, 221]],
            [[158, 33], [158, 7], [222, 7]],
            [[222, 7], [311, 7], [311, 33]],
            [[311, 33], [462, 33], [462, 80]],
            [[462, 80], [584, 80], [623, 152]],
            [[623, 152], [653, 152], [653, 168]],
            [[653, 168], [760, 168], [760, 211]],
            [[760, 211], [844, 211], [844, 256]],
            [[844, 256], [889, 256], [889, 304]],
            [[33, 8], [145, 8], [145, 33]],
            [[109, 152], [5, 152], [5, 80]],
            [[123, 195], [98, 195], [98, 140]],

        ].forEach(w => {
            this.makeWall(wallMaterial, [w[0], w[1], w[2]]);
        })



        const contact = new p2.ContactMaterial(animalMaterial, wallMaterial, {
            friction: 0,
            restitution: .1,

        })

        this.world.addContactMaterial(contact);

        const triggerShape = new p2.Circle({
            radius: 40
        })
        triggerShape.sensor = true;
        const triggerBody = new p2.Body();
        triggerBody.damping = 0;
        triggerBody.addShape(triggerShape);
        triggerBody.position = [21, 44];

        this.world.addBody(triggerBody);

        this.world.on('beginContact', (e: any) => {
            if (e.bodyA === triggerBody || e.bodyB === triggerBody) {
                this.config.onComplete();
            }
        })


        const butelka2 = PIXI.Sprite.from('butelka-2');
        this.stage.addChild(butelka2);
    }

    private initAnimal(animalMaterial: p2.Material) {

        const shapes = [
            {
                radius: 10,
                offset: [0, 0]
            },
            {
                radius: 95,
                offset: [105, 53]
            },
            {
                radius: 38,
                offset: [-45, -18]
            },
            {
                radius: 35,
                offset: [0, -15]
            },
            {
                radius: 35,
                offset: [0, 50]
            },
            {
                radius: 24,
                offset: [-90, -28]
            },
            {
                radius: 10,
                offset: [-125, -38]
            },
            {
                radius: 10,
                offset: [-145, -40]
            },
            {
                radius: 7,
                offset: [-164, -43]
            },
            {
                radius: 24,
                offset: [-104, -105]
            },
            {
                radius: 10,
                offset: [-160, -125]
            },
            {
                radius: 10,
                offset: [-133, -120]
            },
            {
                radius: 10,
                offset: [-73, -79]
            },
            {
                radius: 7,
                offset: [-29, 96]
            },
            {
                radius: 7,
                offset: [6, 111]
            },
        ]

        this.animalBody = new p2.Body({
            mass: 1,
            position: [560, 240]
        });

        const animalGfx = new PIXI.Graphics();
        animalGfx.clear();

        const animalSprite = PIXI.Sprite.from('animal');
        animalSprite.anchor.set(.5);


        this.animal.addChild(animalSprite);

        shapes.forEach(({ radius, offset }) => {
            const animalShape = new p2.Circle({
                radius
            });

            animalShape.material = animalMaterial;
            this.animalBody?.addShape(animalShape, offset as [number, number]);

            animalGfx.beginFill(0x00ff00 * Math.random(), this.showDebug ? .5 : .01);
            animalGfx.drawCircle(offset[0], offset[1], radius);
            animalGfx.endFill();

            animalGfx.position.set(0, 0);

            this.animal.addChild(animalGfx);
        });

        animalGfx.interactive = true;
        animalGfx.on('pointerdown', (e: PIXI.InteractionEvent) => {

            const here = e.data.getLocalPosition(this.stage);

            const point = {
                x: here.x - this.animal.position.x,
                y: here.y - this.animal.position.y
            }


            if (this.animalBody) {
                this.animalBody.angularForce = 3000 * Math.sqrt(point.x * point.x + point.y * point.y) * (Math.random() - .5);
                const force = [
                    -10000 * point.x / Math.abs(point.x),
                    -10000 * point.y / Math.abs(point.y)
                ];
                this.animalBody.force = force as [number, number];
            }
        });

        this.stage.addChild(this.animal);

        this.world.addBody(this.animalBody);

    }

    private _render() {

        if (this.animalBody) {
            this.animal.position.set(this.animalBody.position[0], this.animalBody.position[1])
            this.animal.rotation = this.animalBody.angle;
        }
    }

    private isLive = true;

    private animate() {
        requestAnimationFrame(() => {
            if (this.isLive) {
                this.animate();
            }
        });

        this.world.step(1 / 60);
        this._render();
    }

    private loadAssets(assets: any[]) {

        return new Promise(resolve => {
            assets.forEach(assetInfo => {
                this.loader.add(...assetInfo);
            })

            this.loader.load((loader, resource) => {
                resolve(null);
            })

            this.loader.onError.add((...args: any[]) => {
                console.error(...args);
            })

        })

    }

    private makeWall(material: p2.Material, v: number[][]) {
        const shape = new p2.Convex({
            vertices: v,
        });

        const body = new p2.Body({
            mass: 0
        });

        if (this.showDebug) {
            const gfx = new PIXI.Graphics();
            gfx.clear();
            gfx.beginFill(0x0000ff, .5);
            gfx.drawPolygon(v.reduce((prev, next) => {
                return [
                    ...prev,
                    ...next,
                ]
            }, []));
            gfx.endFill();

            this.stage.addChild(gfx);
        }


        shape.material = material;
        body.addShape(shape);

        this.world.addBody(body);
    }

    public destroy() {
        super.destroy(true);
        this.isLive = false;
    }
}
