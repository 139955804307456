import React, { useCallback, useState } from "react";
import useCompletePoint from "../../../../modules/game/hooks/use-complete-task";
import { GamePoint } from "../../../../modules/game/types";
import CenterBox from "../../layout/center-box";
import './style.scss';
import GeoTaskIntroduction from "./views/introduction";
import GeoTaskOutro from "./views/outro";
import TrashInForest from "./views/trash";
import GeoTaskWidget from "./views/widget";

type Props = {
    point: GamePoint,
    pointCount: number,
    remainigPointsCount: number
}

const GeoTask: React.FC<Props> = ({ point, pointCount, remainigPointsCount }) => {

    const completeTask = useCompletePoint(point.id);

    const [step, setStep] = useState('trash');

    const goToWidet = useCallback(() => {
        setStep('widget');
    }, [setStep])

    const onComplete = useCallback(() => {
        setStep('outro');
    }, [setStep]);

    const onExit = useCallback(() => {
        completeTask();
    }, [completeTask])

    return <>
        {(step === 'trash') && <TrashInForest id={point.id} onClick={() => {setStep('intro')}} />}
        {(step !== 'trash') && <CenterBox className="geo-task">
            {(step === 'intro') && <GeoTaskIntroduction id={point.id} index={point.order + 1} count={pointCount} onNext={goToWidet} />}
            {(step === 'widget') && <GeoTaskWidget id={point.id} onComplete={onComplete} />}
            {(step === 'outro') && <GeoTaskOutro id={point.id} onNext={onExit} remainigPointsCount={remainigPointsCount - 1} />}
        </CenterBox>}
    </>
}

export default GeoTask;
