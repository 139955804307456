
import { useEffect, useMemo, useReducer } from 'react';
import StandardInsert from '../../../../layout/standard-insert';
import './style.scss';

type Props = {
    onComplete?: () => void
}

type Item = {
    id: string,
    src: string,
    correct?: boolean
}

type Group = {
    id: string,
    items: Item[],
}

const Task101Widget: React.FC<Props> = ({ onComplete: completeCallback }) => {

    const data: Group[] = useMemo(() => {
        return [
            {
                id: '1',
                items: [
                    {
                        id: '0',
                        src: '/assets/tasks/task-1/item1.svg'
                    },
                    {
                        id: '1',
                        src: '/assets/tasks/task-1/item2.svg',
                        correct: true
                    }
                ]
            },
            {
                id: '2',
                items: [
                    {
                        id: '0',
                        src: '/assets/tasks/task-1/item3.svg',
                        correct: true
                    },
                    {
                        id: '1',
                        src: '/assets/tasks/task-1/item4.svg'
                    }
                ]
            },
            {
                id: '3',
                items: [
                    {
                        id: '0',
                        src: '/assets/tasks/task-1/item5.svg'
                    },
                    {
                        id: '1',
                        src: '/assets/tasks/task-1/item6.svg',
                        correct: true
                    }
                ]
            },
            {
                id: '4',
                items: [
                    {
                        id: '0',
                        src: '/assets/tasks/task-1/item7.svg',
                        correct: true
                    },
                    {
                        id: '1',
                        src: '/assets/tasks/task-1/item8.svg'
                    }
                ]
            },
            {
                id: '5',
                items: [
                    {
                        id: '0',
                        src: '/assets/tasks/task-1/item9.svg',
                        correct: true
                    },
                    {
                        id: '1',
                        src: '/assets/tasks/task-1/item10.svg'
                    }
                ]
            }
        ]
    }, []);

    const [selected, setSelected] = useReducer((state: { groupId: string, itemId: string }[], action: { groupId: string, itemId: string }) => {
        return [
            ...state
                .filter(s => s.groupId !== action.groupId),
            action
        ];
    }, [])

    const isCompleted = useMemo(() => {
        if (selected.length !== data.length) {
            return false
        }

        return selected.reduce((last, next) => {
            if (!last) return false;

            const group = data.find(g => g.id === next.groupId);

            if (group) {
                const item = group.items.find(i => i.id === next.itemId);

                return item?.correct || false;
            }

            return false;
        }, true);
    }, [selected, data]);

    useEffect(() => {
        if (isCompleted) {
            if (completeCallback) {
                completeCallback();
            }
        }
    }, [isCompleted, completeCallback]);

    return <div className="task-101">
        <StandardInsert className="groups">
            {data.map(group => {
                return <div className="group" key={group.id}>
                    {
                        group.items.map((item) => {
                            const selectedRecord = selected.find(s => s.groupId === group.id)

                            const isSelected = selectedRecord ? selectedRecord.itemId === item.id : false;

                            return <div className={`group-item ${item.correct ? 'correct' : ''} ${isSelected ? 'selected' : ''}`} key={group.id + item.id} onClick={() => {
                                setSelected({ groupId: group.id, itemId: item.id });
                            }}>
                                <div className="wrapper">
                                    <img src={item.src} alt="" />
                                </div>
                            </div>
                        })
                    }
                </div>
            })}
        </StandardInsert>
    </div>
}

export default Task101Widget;
