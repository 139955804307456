import React from "react";
import './style.scss';

type Props = {
    achievement: {
        title: string,
        description: string,
        src: string
    },
    onNext: () => void
}

const AchievementInfo: React.FC<Props> = ({ achievement, onNext }) => {



    return <div className="achievement-info">
        <h1>Gratulacje!</h1>
        <h2>Ścieżka <strong>{achievement.title}</strong> została zakończona!</h2>
        <p dangerouslySetInnerHTML={{ __html: achievement.description }}></p>

        <div className="row">
            <div className="achievements">
                <div className="achievement">
                    <div className="img">
                        <img src={achievement.src} alt="" />
                    </div>
                </div>
            </div>

            <div className="buttons">
                <button className="button" onClick={onNext}>Przejdź dalej</button>
            </div>
        </div>

    </div>
}

export default AchievementInfo;
