import { useCallback } from "react";
import { GEO_GAME_CONFIG_ACTION, useGeoGameConfigModuleDispatch } from "..";
import { GeoGameConfig, GEO_GAME_MODE } from "../../game/types";

export default function useSetConfig() {
    const dispatch = useGeoGameConfigModuleDispatch();

    return useCallback((gameMode: GEO_GAME_MODE, config: Partial<GeoGameConfig>) => {
        dispatch({
            type: GEO_GAME_CONFIG_ACTION.SET_CONFIG,
            payload: {
                gameMode,
                config
            }
        })
    }, [dispatch])
}
