import { Letter } from "."

const DICTIONARY: Letter[] = [
    {
        "letter": "a",
        "src": "/assets/tasks/task-204/a.svg"
    },
    {
        letter: 'ą',
        src: `/assets/tasks/task-204/a2.svg`
    },
    {
        "letter": "b",
        "src": "/assets/tasks/task-204/b.svg"
    },
    {
        "letter": "c",
        "src": "/assets/tasks/task-204/c.svg"
    },
    {
        letter: 'ć',
        src: `/assets/tasks/task-204/c2.svg`
    },
    {
        "letter": "d",
        "src": "/assets/tasks/task-204/d.svg"
    },
    {
        "letter": "e",
        "src": "/assets/tasks/task-204/e.svg"
    },
    {
        letter: 'ę',
        src: `/assets/tasks/task-204/e2.svg`
    },
    {
        "letter": "f",
        "src": "/assets/tasks/task-204/f.svg"
    },
    {
        "letter": "g",
        "src": "/assets/tasks/task-204/g.svg"
    },
    {
        "letter": "h",
        "src": "/assets/tasks/task-204/h.svg"
    },
    {
        "letter": "i",
        "src": "/assets/tasks/task-204/i.svg"
    },
    {
        "letter": "j",
        "src": "/assets/tasks/task-204/j.svg"
    },
    {
        "letter": "k",
        "src": "/assets/tasks/task-204/k.svg"
    },
    {
        "letter": "l",
        "src": "/assets/tasks/task-204/l.svg"
    },
    {
        letter: 'ł',
        src: `/assets/tasks/task-204/l2.svg`
    },
    {
        "letter": "m",
        "src": "/assets/tasks/task-204/m.svg"
    },
    {
        "letter": "n",
        "src": "/assets/tasks/task-204/n.svg"
    },
    {
        letter: 'ń',
        src: `/assets/tasks/task-204/n2.svg`
    },
    {
        "letter": "o",
        "src": "/assets/tasks/task-204/o.svg"
    },
    {
        letter: 'ó',
        src: `/assets/tasks/task-204/o2.svg`
    },
    {
        "letter": "p",
        "src": "/assets/tasks/task-204/p.svg"
    },
    {
        "letter": "r",
        "src": "/assets/tasks/task-204/r.svg"
    },
    {
        "letter": "s",
        "src": "/assets/tasks/task-204/s.svg"
    },
    {
        letter: 'ś',
        src: `/assets/tasks/task-204/s2.svg`
    },
    {
        "letter": "t",
        "src": "/assets/tasks/task-204/t.svg"
    },
    {
        "letter": "u",
        "src": "/assets/tasks/task-204/u.svg"
    },
    {
        "letter": "w",
        "src": "/assets/tasks/task-204/w.svg"
    },
    {
        "letter": "y",
        "src": "/assets/tasks/task-204/y.svg"
    },
    {
        "letter": "z",
        "src": "/assets/tasks/task-204/z.svg"
    },
    {
        letter: 'ż',
        src: `/assets/tasks/task-204/z2.svg`
    },
    {
        letter: 'ź',
        src: `/assets/tasks/task-204/z3.svg`
    }
]

export default DICTIONARY;
