import CenterBox from "../../../layout/center-box";
import './style.scss';

type Props = {}

const PermissionDeniedPopup: React.FC<Props> = () => {
    return <CenterBox className="geo-error">
        <p>Dostęp do geolokalizacji jest niezbędny do działania gry! Wyraź zgodę i spróbuj ponownie!</p>

        <button className="button" onClick={() => {
            document.location.reload();
        }}>Od początku</button>
    </CenterBox>
}

export default PermissionDeniedPopup;
