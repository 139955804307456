import * as PIXI from 'pixi.js-legacy';
import { WidgetConfig } from '../create-widget';

type Spot = {
    id: string,
    x: number,
    y: number,
    width: number,
    height: number,
    finded: boolean,
    opacity: number,
}

export default class TaskWidget extends PIXI.Application {
    public baseWidth: number;
    public baseHeight: number;
    private baseRatio: number;
    private scaleRatio = 1;

    private state: Spot[] = [
        {
            id: '1',
            x: 133,
            y: 67,
            width: 255,
            height: 269,
            opacity: 1,
            finded: false
        },

        {
            id: '2',
            x: 127,
            y: 358,
            width: 94,
            height: 200,
            opacity: 1,
            finded: false
        },

        {
            id: '3',
            x: 70,
            y: 655,
            width: 120,
            height: 192,
            opacity: 1,
            finded: false
        },

        {
            id: '4',
            x: 548,
            y: 614,
            width: 196,
            height: 90,
            opacity: 1,
            finded: false
        },

        {
            id: '5',
            x: 752,
            y: 395,
            width: 85,
            height: 123,
            opacity: 1,
            finded: false
        },

        {
            id: '6',
            x: 740,
            y: 22,
            width: 180,
            height: 198,
            opacity: 1,
            finded: false
        },

        {
            id: '7',
            x: 1002,
            y: 58,
            width: 162,
            height: 135,
            opacity: 1,
            finded: false
        },

        {
            id: '8',
            x: 1113,
            y: 544,
            width: 200,
            height: 172,
            opacity: 1,
            finded: false
        },

        {
            id: '9',
            x: 1200,
            y: 228,
            width: 122,
            height: 184,
            opacity: 1,
            finded: false
        },

        {
            id: '10',
            x: 740,
            y: 820,
            width: 105,
            height: 90,
            opacity: 1,
            finded: false
        }
    ]

    constructor(width: number, height: number, private config: WidgetConfig) {
        super({
            width,
            height,
            transparent: true,
            antialias: true
        });

        this.renderer.plugins.interaction.autoPreventDefault = false;
        this.renderer.view.style.touchAction = 'auto';

        this.baseHeight = height;
        this.baseWidth = width;
        this.baseRatio = height / width;

        this.loadAssets([
            ['full', '/assets/tasks/task-102/image-full.png'],
            ['empty', '/assets/tasks/task-102/image-empty.png']
        ]).then((resources) => {
            this.initWidget();
        })

        setInterval(() => {
            this.updateMask();
        }, 300)
    }


    private initWidget() {
        const fullImage = PIXI.Sprite.from('full');
        const emptyImage = PIXI.Sprite.from('empty');

        this.stage.addChild(fullImage);
        this.stage.addChild(emptyImage);

        this.updateMask();


        emptyImage.mask = this.mask;

        this.stage.addChild(this.mask);

        this.state.forEach(s => {
            const container = new PIXI.Container();
            container.interactive = true;

            const rect = new PIXI.Rectangle(s.x, s.y, s.width, s.height);
            container.hitArea = rect;

            container.on('pointerdown', () => {
                this.markAsFinded(s.id);
            })

            this.stage.addChild(container);
        })
    }

    private markAsFinded(id: string) {
        this.state = this.state.map(slot => {
            if (slot.id === id) {
                return {
                    ...slot,
                    finded: true
                }
            }

            return slot;
        });

        const complete = this.state.reduce((prev, next) => {
            return prev && next.finded;
        }, true)

        if (complete) {
            this.config.onComplete();
        }
    }


    private mask = new PIXI.Graphics();

    private updateMask() {
        this.mask.clear();

        this.mask.beginFill(0xffffff);
        this.state.forEach((s) => {
            if (s.finded) {
                this.mask.drawRect(s.x, s.y, s.width, s.height);
            }
        })

        this.mask.endFill();
    }


    private loadAssets(assets: any[]) {

        return new Promise(resolve => {
            assets.forEach(assetInfo => {
                this.loader.add(...assetInfo);
            })

            this.loader.load((loader, resource) => {
                resolve(null);
            })

            this.loader.onError.add((...args: any[]) => {
                console.error(...args);
            })

        })

    }

    public destroy() {
        super.destroy(true);
    }

}
