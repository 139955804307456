import { useRef, useEffect, useCallback } from "react";

type Props = {
    srcObject: MediaStream
}

const VideoComponent: React.FC<Props> = ({srcObject}) => {

    const videoRef = useRef<HTMLVideoElement>(null);

    const formatVideoTag = useCallback(() => {
        if (videoRef.current) {
            const width = videoRef.current.videoWidth;
            const height = videoRef.current.videoHeight;

            videoRef.current.style.height = '100%';
            videoRef.current.style.width = `${100 * width / height}vh`;
            videoRef.current.style.minWidth = '100%';
            videoRef.current.style.minHeight = `${100 * height / width}vw`;
        }
    }, [videoRef]);

    useEffect(() => {

        const video = videoRef.current;

        if (video && srcObject) {
            video.srcObject = srcObject

            video.addEventListener('loadedmetadata', formatVideoTag);
        }

        return () => {
            if (srcObject && video) {
                video.removeEventListener('loadedmetadata', formatVideoTag);
                video.srcObject = null;

                srcObject.getTracks()[0].stop()
            }

        }
    }, [videoRef, srcObject, formatVideoTag])

    return <video ref={videoRef} autoPlay={true} playsInline></video>
}


export default VideoComponent;
