import { useGeoGameConfigModuleState } from "..";

export default function useConfig() {

    const state = useGeoGameConfigModuleState();

    return {
        ...state
    }
}
