import makeStore from "@mborecki/react-create-store";
import reducer from './reducer';
import { GamePoint, VIEW } from "./types";

export type GeoGameState = {
    view: VIEW,
    started: boolean,
    gamePoints: GamePoint[] | null
}

export enum GEO_GAME_ACTION {
    START_GAME = 'start',
    SET_GAME_POINTS = 'load-game-points',
    SHOW_VIEW = 'show_view',
    RESTART = 'reset',
    SHOW_TASK = 'show-task',
    COMPLETE_POINT = 'complete-point'
}

export type GeoGameAction = {
    type: GEO_GAME_ACTION,
    payload: any,
}

export const initGeoGameModuleState: GeoGameState = {
    view: VIEW.INTRO,
    started: false,
    gamePoints: null
}

const [
    GeoGameModuleProvider,
    useGeoGameModuleDispatch,
    useGeoGameModuleState
] = makeStore(reducer, initGeoGameModuleState, 'kampinos-geo-game')

export {
    GeoGameModuleProvider,
    useGeoGameModuleDispatch,
    useGeoGameModuleState
}
