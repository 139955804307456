//@ts-ignore
import { Preview } from 'react-dnd-preview'
import { useCallback, useMemo } from 'react';
import React from 'react';
import DropZone from '../../../../../../../components/drag-drop-template/drop-zone';
import PickUp from '../../../../../../../components/drag-drop-template/pick-up';
import { GeoTask } from '../../../../../../modules/game/types';

type Props = {
    onComplete?: () => void,
    task: GeoTask
}

type Item = {
    id: string,
    src: string,
    correct: string
}

type Bin = {
    id: string,
    src: string
    type: 'normal' | 'wide'
}

type GameState = {
    items: Item[],
    bins: Bin[],
}

const GeoTrashTask: React.FC<Props> = ({ onComplete, task }) => {
    const state: GameState = useMemo(() => {
        return {
            items: [
                {
                    id: 'item',
                    src: task.trashWidgetImage || task.image,
                    correct: task.correct || '',
                }
            ],
            bins: [
                {
                    id: 'tworzywa',
                    src: '/assets/tasks/task-303/bin1.png',
                    type: 'normal'
                },
                {
                    id: 'szklo',
                    src: '/assets/tasks/task-303/bin2.png',
                    type: 'normal'
                },
                {
                    id: 'bio',
                    src: '/assets/tasks/task-303/bin3.png',
                    type: 'normal'
                },
                {
                    id: 'zmieszane',
                    src: '/assets/tasks/task-303/bin4.png',
                    type: 'normal'
                },
                {
                    id: 'papier',
                    src: '/assets/tasks/task-303/bin5.png',
                    type: 'normal'
                },
                {
                    id: 'pszok',
                    src: '/assets/tasks/task-303/bin6.png',
                    type: 'wide'
                },
                {
                    id: 'baterie',
                    src: '/assets/tasks/task-303/bin7.png',
                    type: 'normal'
                }
            ]
        }
    }, [task]);

    const onDrop = useCallback((pickUp: string, dropId: string) => {
        const item = state.items.find(i => i.id === pickUp);

        if (onComplete && item?.correct === dropId) {
            onComplete();
        }
    }, [state, onComplete]);

    return <>

        <div className="geo-trash-task">
            <div className="pickup-zone group">
                {
                    state.items
                        .slice(0, 5)
                        .map(item => {
                            return <div className="item-container" key={item.id}>
                                <div className="item-container-inner">
                                    <PickUp data={{ type: 'item', id: item.id, data: item }}>
                                        <GameItem item={item} />
                                    </PickUp>
                                </div>

                            </div>
                        })
                }
            </div>
            <div className="slots group">
                {
                    state.bins.map(bin => {
                        return <div className={`bin ${bin.type}`} key={bin.id}>
                            <DropZone targetType="item" id={bin.id} onDrop={onDrop}>
                                <div className="bin-container">
                                    <img src={bin.src} alt="" />
                                </div>
                            </DropZone>
                        </div>
                    })
                }
            </div>
        </div>
        <ItemPreview />
    </>
}

const GameItem: React.FC<{ item: Item }> = ({ item }) => {
    return <img className="task-103-pick-up" src={item.src} alt="" />
}

const ItemPreview: React.FC<{}> = () => {
    return <Preview>{({ item, style }: any) => {
        return <div style={{ ...style, width: item.size.width }}>
            <GameItem item={item.data} />
        </div>
    }}</Preview>
}

export default GeoTrashTask;
