import { useCallback, useEffect } from "react";
import { useGeoModuleDispatch, GEO_MODULE_ACTION } from ".";
import { GEO_MODULE_ERROR } from "./types";

type Props = {}

const ListenersComponent: React.FC<Props> = ({ children }) => {
    const dispatch = useGeoModuleDispatch();

    const positionHandler = useCallback((position) => {
        dispatch({
            type: GEO_MODULE_ACTION.SET_DATA,
            payload: {
                position: {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                },
                accuracy: position.coords.accuracy
            }
        })
    }, [dispatch])

    const errorHandler = useCallback((error: GeolocationPositionError) => {

        if (typeof GeolocationPositionError === 'undefined') {
            //@ts-ignore
            GeolocationPositionError = PositionError;
        }

        switch (error?.code) {
            case GeolocationPositionError.PERMISSION_DENIED:
                dispatch({
                    type: GEO_MODULE_ACTION.SET_ERROR,
                    payload: {
                        error: GEO_MODULE_ERROR.PERMISSION_DENIED
                    }
                });
                break;

            case GeolocationPositionError.POSITION_UNAVAILABLE:
                dispatch({
                    type: GEO_MODULE_ACTION.SET_ERROR,
                    payload: {
                        error: GEO_MODULE_ERROR.POSITION_UNAVAILABLE
                    }
                });
                break;

            case GeolocationPositionError.TIMEOUT:
                dispatch({
                    type: GEO_MODULE_ACTION.SET_ERROR,
                    payload: {
                        error: GEO_MODULE_ERROR.TIMEOUT
                    }
                });
                break;

            default:
                dispatch({
                    type: GEO_MODULE_ACTION.SET_ERROR,
                    payload: {
                        error: GEO_MODULE_ERROR.UNKNOWN
                    }
                });

        }
    }, [dispatch])

    useEffect(() => {
        if ('geolocation' in navigator) {
            const id = navigator.geolocation.watchPosition(positionHandler, errorHandler, {
                maximumAge: 500,
                enableHighAccuracy: true
            });

            navigator.geolocation.getCurrentPosition(positionHandler, errorHandler, {
                maximumAge: 500,
                enableHighAccuracy: true
            });

            return () => {
                navigator.geolocation.clearWatch(id);
            }

        } else {
            dispatch({
                type: GEO_MODULE_ACTION.SET_ERROR,
                payload: {
                    error: GEO_MODULE_ERROR.NO_GEOLOCATION_IN_DEVICE
                }
            })
        }
    }, [dispatch, errorHandler, positionHandler])

    return <>{children}</>;
}

export default ListenersComponent;
