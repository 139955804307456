import TaskWidget from "./widget";

export type WidgetConfig = {
    container: any,
    onGroupSelected: (message: string, img1: string, img2: string) => void
}

const BASE_WIDTH = 1248;
const BASE_HEIGHT = 934;

export default function createWidget(config: WidgetConfig) {

    const app = new TaskWidget(BASE_WIDTH, BASE_HEIGHT, config);

    config.container.appendChild(app.view);

    return {
        destroy: () => app.destroy(true)
    }
}
