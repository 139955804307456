import { useMemo, useState } from "react";
import { useRef, useCallback, useEffect } from "react";
import './style.scss';

type Props = {
    src: string
}

const AudioPlayer: React.FC<Props> = ({ src }) => {


    const player = useRef<HTMLAudioElement>(null)
    const markerRef = useRef<HTMLDivElement>(null);
    const timelineRef = useRef<HTMLInputElement>(null);

    const onPlayClicked = useCallback(() => {
        player.current?.play();
    }, [player])


    const onPauseClicked = useCallback(() => {
        player.current?.pause();
    }, [player])


    const onStopClicked = useCallback(() => {
        if (player.current) {
            player.current.pause();
            player.current.currentTime = 0;
        }
    }, [player])

    const [current, setCurrent] = useState(0);
    const [duration, setDuration] = useState(0);

    const onPlay = useCallback(() => { }, []);

    const onPause = useCallback(() => { }, []);

    const onEnded = useCallback(() => { }, []);

    const onTimeUpdate = useCallback(() => {
        if (player.current) {
            setCurrent(player.current.currentTime);
            setDuration(player.current.duration)
        } else {
            setCurrent(0);
            setDuration(0)
        }
    }, [setCurrent, player]);

    const onTimeChange = useCallback(() => {
        if (timelineRef.current && player.current) {
            const value = parseInt(timelineRef.current.value);
            player.current.currentTime = (value / 100) * player.current.duration;
            setCurrent(player.current.currentTime);
        }
    }, [timelineRef, player])

    useEffect(() => {
        if (player.current) {
            const playerEl = player.current;
            playerEl.addEventListener('play', onPlay)
            playerEl.addEventListener('pause', onPause)
            playerEl.addEventListener('ended', onEnded)
            playerEl.addEventListener('timeupdate', onTimeUpdate)

            return () => {
                if (playerEl) {
                    playerEl.removeEventListener('play', onPlay)
                    playerEl.removeEventListener('pause', onPause)
                    playerEl.removeEventListener('ended', onEnded)
                    playerEl.removeEventListener('timeupdate', onTimeUpdate)
                }
            }
        }
    }, [onEnded, onPause, onPlay, onTimeUpdate, player])

    useEffect(() => {

        if (timelineRef.current) {
            const timeline = timelineRef.current
            timeline.addEventListener('change', onTimeChange);

            return () => {
                if (timeline) {
                    timeline.removeEventListener('change', onTimeChange);
                }
            }
        }


    }, [timelineRef, player, onTimeChange])

    const currentText = useMemo(() => {
        const min = (current / 60) | 0;
        const sek = current % 60 | 0

        return `${min}:${sek < 10 ? ('0' + sek) : sek}`
    }, [current]);

    return (
        <div className="audio-player">
            <div className="buttons">

                <span className="player-button play" role="button" tabIndex={0} onClick={onPlayClicked}></span>
                <span className="player-button pause" role="button" tabIndex={0} onClick={onPauseClicked}></span>
                <span className="player-button stop" role="button" tabIndex={0} onClick={onStopClicked}></span>
            </div>
            <div className="timeinfo">
                <div className="time-controlls">
                    <input type="range" ref={timelineRef} max={100} min={0} step={1} />
                    <div className="axis">
                    </div>
                    <div className="marker" ref={markerRef} style={{ left: `${duration ? (current / duration) * 100 : 0}%` }}>
                    </div>
                </div>
                <div className="label">
                    <span>{currentText}</span>
                </div>
            </div>
            <audio src={src} ref={player} />
        </div>
    )
}

export default AudioPlayer;
