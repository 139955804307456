
import React, { useCallback, useEffect, useMemo } from 'react';
import './style.scss';

//@ts-ignore
import { Preview } from 'react-dnd-preview'
import StandardInsert from '../../../../layout/standard-insert';
import { useReducer } from 'react';
import { Card } from './card';

type Props = {
    onComplete?: () => void
}

type Item = {
    id: string,
    src: string,
    description: string,
    correct: number
}

type GameState = {
    items: Item[]
}

const Task403Widget: React.FC<Props> = ({ onComplete }) => {

    const initData: GameState = useMemo(() => {
        const items = [
            {
                id: 'i1',
                src: '/assets/tasks/task-403/item-1.svg',
                description: 'Przerabianie płatków na włókna',
                correct: 7
            },
            {
                id: 'i2',
                src: '/assets/tasks/task-403/item-2.svg',
                description: 'Czyszczenie',
                correct: 6
            },
            {
                id: 'i3',
                src: '/assets/tasks/task-403/item-3.svg',
                description: 'Sortowanie na kolory (butelek pet)',
                correct: 4
            },
            {
                id: 'i4',
                src: '/assets/tasks/task-403/item-4.svg',
                description: 'Tworzenie produktów z materiałów wtórnych: polarów, wypełnień poduszek, maskotek',
                correct: 8
            },
            {
                id: 'i5',
                src: '/assets/tasks/task-403/item-5.svg',
                description: 'Zbiórka z pojemników',
                correct: 2
            },
            {
                id: 'i6',
                src: '/assets/tasks/task-403/item-6.svg',
                description: 'Rozdrabnianie na płatki PET',
                correct: 5
            },
            {
                id: 'i7',
                src: '/assets/tasks/task-403/item-7.svg',
                description: 'Butelka trafia do żółtego pojemnika',
                correct: 1
            },
            {
                id: 'i8',
                src: '/assets/tasks/task-403/item-8.svg',
                description: 'Odkręcenie, opróżnienie i zgniecenie butelki',
                correct: 0
            },
            {
                id: 'i9',
                src: '/assets/tasks/task-403/item-9.svg',
                description: 'Sortowanie (oddzielenie pet od innych odpadów z żółtego pojemnika np. puszek, kartonów po napojach)',
                correct: 3
            }
        ];

        return {
            items
        }

    }, [])



    const [state, itemMove] = useReducer((state: GameState, { dragIndex, hoverIndex }: { dragIndex: number, hoverIndex: number }) => {
        const copyArr = [...state.items];
        copyArr.splice(hoverIndex, 0, copyArr.splice(dragIndex, 1)[0]);

        return {
            ...state,
            items: [...copyArr]
        }
    }, initData)

    const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
        itemMove({ dragIndex, hoverIndex })
    }, [])

    useEffect(() => {
        const complete = state.items.reduce((prev, next, index) => {
            return prev && next.correct === index;
        }, true)

        if (complete) {
            if (onComplete) {
                onComplete();
            }
        }
    }, [state, onComplete])

    return <div className="task-403">
        <>
            <div className="box-insert">
            </div>
            <StandardInsert>
                <div className={`items`}>
                    {
                        state.items.map((item, index) => {

                            // const item = state.items.find(i => i.id === s.itemId);

                            if (item) {
                                return <div className={`item`} key={index}>
                                    <div className="index">{index + 1}</div>
                                    <Card id={item.id} index={index} moveCard={moveCard} data={item} >
                                        <GameItem item={item} correct={index === item.correct} />
                                    </Card>
                                </div>
                            }

                            return <></>

                        })
                    }
                </div>
            </StandardInsert>
            <ItemPreview />
        </>
    </div>
}

const GameItem: React.FC<{
    item: Item,
    correct?: true | false | null
}> = ({ item, correct }) => {
    return <div className={`task-403-pick-up ${correct === true ? 'correct' : (correct === false ? 'wrong' : '')}`}>
        <img src={item.src} alt="" />
        <div className="description">
            {item.description}
        </div>
    </div>
}

const ItemPreview: React.FC<{}> = () => {
    return <Preview>{({ item, style }: any) => {
        return <div style={{ ...style, width: item.size.width }}>
            <GameItem item={item.data} />
        </div>
    }}</Preview>
}

export default Task403Widget;

