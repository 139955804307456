import { useMemo } from "react";
import useTasks from "../../tasks/hooks/use-tasks";
import useUser from "./use-user";

export default function useAwards() {
    const {user} = useUser();
    const {isLoading, tasks} = useTasks();

    const awards = useMemo(() => {
        if (user && tasks) {
            return user.completedTasks.reduce((prev: string[], next: string) => {

                const task = tasks.find(t => t.id === next);

                if (task) {
                    return [...prev, ...task.awards]
                }

                return prev;
            }, [])
        }

        return [];
    }, [user, tasks]);

    return {
        awards,
        isLoading
    }

}
