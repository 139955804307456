import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import './style.scss';

type Props = {
    type: 'web' | 'geo'
}

const Cookies: React.FC<Props> = ({ type }) => {
    const { show, accept } = useCookiesInfo(type);

    useEffect(() => {
        window.document.body.classList.add('with-cookies-info');

        return () => { window.document.body.classList.remove('with-cookies-info'); }
    })

    return (<>
        {show && <aside className="cookies-overlay">
            <div className="container">
                <div className="inner">
                    {type === 'web' && <>
                        <p>
                            Nasz serwis wykorzystuje pliki cookies. Przechodząc do serwisu klikając odnośnik „Zgadzam się” wyrażasz zgodę na przetwarzanie przez nas Twoich danych osobowych oraz wyrażasz zgodę na zapis i wykorzystywanie następujących plików cookies:
                        </p>
                        <ul>
                            <li>pliki typu nessesary - niezbędne do prawidłowego funkcjonowania strony</li>
                            <li>pliki typu statistics - służące do zbierania statystyk odwiedzin i aktywności za pomocą narzędzia Google Analytics</li>
                        </ul>
                        <p>
                            Czytaj więcej: <Link to={'polityka-prywatności'}>Polityka prywatności i cookies</Link>
                        </p>
                    </>}
                    {type === 'geo' && <>
                        <p>
                            Nasz serwis wykorzystuje pliki cookies. Przechodząc do serwisu klikając odnośnik „Zgadzam się” wyrażasz zgodę na przetwarzanie przez nas Twoich danych osobowych oraz wyrażasz zgodę na zapis i wykorzystywanie następujących plików cookies:
                        </p>
                        <ul>
                            <li>pliki typu nessesary - niezbędne do prawidłowego funkcjonowania strony</li>
                            <li>pliki typu statistics - służące do zbierania statystyk odwiedzin i aktywności za pomocą narzędzia Google Analytics</li>
                            <li>pliki zbierające dane o lokalizacji urządzenia - konieczne do prawidłowego działania gry w przestrzeni</li>
                        </ul>
                        <p>
                            Czytaj więcej: <Link to={'/polityka-prywatności'}>Polityka prywatności i cookies</Link>
                        </p>
                    </>}
                    <div className='buttons'>
                        <button className="button" onClick={accept}>Dalej</button>
                    </div>
                </div>


            </div>
        </aside>}
    </>
    )

}

function useCookiesInfo(postfix: string = '') {

    const [show, setShow] = useState<string | null>(window.localStorage.getItem('accept-cookies-' + postfix));

    const accept = useCallback(() => {
        window.localStorage.setItem('accept-cookies-' + postfix, '1')
        setShow('1');
    }, [postfix])

    return {
        show: show !== '1',
        accept
    }
}

export default Cookies;
