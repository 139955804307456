import { useCallback } from "react";
import { GEO_GAME_ACTION, useGeoGameModuleDispatch } from "..";

export default function useCompletePoint(id: string) {

    const dispatch = useGeoGameModuleDispatch();

    return useCallback(() => {
        dispatch({
            type: GEO_GAME_ACTION.COMPLETE_POINT,
            payload: {
                id
            }
        })
    }, [id, dispatch])
}
