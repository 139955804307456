import { useCallback } from "react";
import { GEO_GAME_ACTION, useGeoGameModuleDispatch } from "../../../../modules/game";
import { VIEW } from "../../../../modules/game/types";
import CenterBox from "../../layout/center-box";
import './style.scss';

type Props = {}

const Intro: React.FC<Props> = () => {

    const dispatch = useGeoGameModuleDispatch();

    const onClick = useCallback(() => {
        dispatch({
            type: GEO_GAME_ACTION.SHOW_VIEW,
            payload: {
                view: VIEW.COMPASS
            }
        })
    }, [dispatch])

    return <CenterBox className="geo-game-intro">
        <p>
            Czas rozpocząć prawdziwą zabawę!
            </p>
        <p> Twoim zadaniem będzie zebranie wszystkich wirtualnych śmieci rozrzuconych wokół Ciebie.

        </p>
        <p> Podpowiadamy jest ich 10! Aby je znaleźć, będziesz musiał poruszać się na przestrzeni polany wg zasady ciepło – zimno. Gdy znajdziesz się bardzo blisko celu, zobaczysz w przestrzeni odpad, który musisz złapać a następnie, posegregować do odpowiedniego wirtualnego kosza. Podczas zabawy, czeka na Ciebie zadanie specjalne.
            </p>
        <p>
            Powodzenia!
        </p>

        <div className="buttons">
            <button className="button" onClick={onClick}>OK</button>
        </div>
    </CenterBox>
}

export default Intro;
