import * as PIXI from 'pixi.js-legacy';
import { Item } from '.';
import { Observable, BehaviorSubject, combineLatest } from 'rxjs';

const SIZE = 150;

export default class GameItem extends PIXI.Container {

    gfx = new PIXI.Graphics();

    private hover = new BehaviorSubject(false);

    constructor(
        item: Item,
        private selected: Observable<boolean>,
        private locked: Observable<boolean>,
    ) {
        super();

        // this.gfx.position.set(-SIZE / 2, -SIZE / 2);
        this.addChild(this.gfx);

        this.interactive = true;
        this.cursor = 'pointer';

        this.on('pointerover', () => {
            this.hover.next(true);
        })

        this.on('pointerout', () => {
            this.hover.next(false);
        })



        combineLatest([
            this.hover,
            this.selected,
            this.locked
        ]).subscribe(([hover, selected, locked]) => {
            this.gfx.clear();

            if (locked) {
                this.gfx.beginFill(0xA59C38);
                this.gfx.drawCircle(0, 0, SIZE / 2);
                this.gfx.beginFill(0xEDE8DA);
                this.gfx.drawCircle(0, 0, (SIZE * .9) / 2);
                this.gfx.endFill();
                return;
            }

            if (selected) {
                this.gfx.beginFill(0xD14D3F);
                this.gfx.drawCircle(0, 0, SIZE / 2);
                this.gfx.beginFill(0xEDE8DA);
                this.gfx.drawCircle(0, 0, (SIZE * .9) / 2);
                this.gfx.endFill();
                return;
            }

            if (hover) {
                this.gfx.beginFill(0xEDE8DA);
                this.gfx.drawCircle(0, 0, SIZE / 2);
                this.gfx.endFill();
                return;
            }


            this.gfx.beginFill(0xDAD7A9);
            this.gfx.drawCircle(0, 0, SIZE / 2);
            this.gfx.endFill();
        })

        const sprite = PIXI.Sprite.from(item.asset);

        sprite.anchor.set(.5);
        sprite.scale.set(item.scale);
        sprite.position.set(0,0);

        this.addChild(sprite);
    }
}
