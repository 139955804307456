type Props = {
    className?: string | undefined
}

const CenterBox: React.FC<Props> = ({children, className}) => {
    return <div className={`center-box ${className}`}>
        {children}
    </div>
}

export default CenterBox;
