import React from 'react';
import { BrowserRouter, Redirect, Route, Switch, useLocation } from 'react-router-dom';
import Cookies from './components/cookies';
import useIsLogged from './modules/user/hooks/use-is-logged';
import AudioPage from './routes/audio-page';
import AwardPage from './routes/award';
// import DebugPage from './routes/debug';
import EndGamePage from './routes/end-game';
import GeneratorPage from './routes/genarator';
import HomePage from './routes/home';
import LoginPage from './routes/login';
import MapPage from './routes/map';
import { getAwardRoute, getEndGameRoute, getGeneratorRoute, getGeoGameRoute, getHomeRoute, getInfoPage1Route, getInfoPage2Route, getInfoPage3Route, getLoginRoute, getMapRoute, getRulesRoute, getTaskRoute } from './routes/routes';
import RulesPage from './routes/rules';
import TaskPage from './routes/task';
import ScrollToTop from '@mborecki/react-scroll-to-top';
import GeoGraMain from './geo-gra';
import { GEO_GAME_MODE } from './geo-gra/modules/game/types';
import PrivacePage from './routes/privace';

export default function App() {
    return (
        <>
            <BrowserRouter>
                <Routes />
            </BrowserRouter >
        </>
    )
}

const OnlyForLoggedRoute: React.FC<any> = ({ children, ...rest }: any) => {

    const { isLogged } = useIsLogged();

    return <Route {...rest}>{
        isLogged
            ? children
            : <Redirect to={getLoginRoute()} />
    } </Route>
}

const OnlyForUnloggedRoute = ({ children, ...rest }: any) => {

    const { isLogged } = useIsLogged();

    return <Route {...rest}>{
        isLogged
            ? <Redirect to={getMapRoute()} />
            : children
    } </Route>
}


const Routes: React.FC<{}> = () => {

    const { pathname } = useLocation()

    return <>
        <ScrollToTop trigger={pathname} />
        <Switch>
            <Route path={getHomeRoute()} exact>
                <HomePage />
                <Cookies type="web" />
            </Route>

            <Route path={getRulesRoute()}>
                <RulesPage />
                <Cookies type="web" />
            </Route>
            <OnlyForLoggedRoute path={getMapRoute()}>
                <MapPage />
                <Cookies type="web" />
            </OnlyForLoggedRoute>
            <OnlyForLoggedRoute path={getTaskRoute()}>
                <TaskPage />
                <Cookies type="web" />
            </OnlyForLoggedRoute>

            <OnlyForUnloggedRoute path={getLoginRoute()} exact >
                <LoginPage />
                <Cookies type="web" />
            </OnlyForUnloggedRoute>

            <OnlyForLoggedRoute path={getEndGameRoute()} exact>
                <EndGamePage />
                <Cookies type="web" />
            </OnlyForLoggedRoute>
            <OnlyForLoggedRoute path={getGeneratorRoute()} exact>
                <GeneratorPage />
                <Cookies type="web" />
            </OnlyForLoggedRoute>

            <OnlyForLoggedRoute path={getAwardRoute()} exact>
                <AwardPage />
                <Cookies type="web" />
            </OnlyForLoggedRoute>

            <Route path={getGeoGameRoute()}>
                <GeoGraMain mode={GEO_GAME_MODE.LIPKOW} />
                <Cookies type="geo" />
            </Route>

            <Route path={'/gra-2'}>
                <GeoGraMain mode={GEO_GAME_MODE.AROUND} />
                <Cookies type="geo" />
            </Route>

            <Route path={'/gra-3'}>
                <GeoGraMain mode={GEO_GAME_MODE.DEV} />
                <Cookies type="geo" />
            </Route>

            <Route path={getInfoPage1Route()}>
                <AudioPage id={'1'} />
                <Cookies type="web" />
            </Route>

            <Route path={getInfoPage2Route()}>
                <AudioPage id={'2'} />
                <Cookies type="web" />
            </Route>

            <Route path={getInfoPage3Route()}>
                <AudioPage id={'3'} />
                <Cookies type="web" />
            </Route>

            <Route path={'/polityka-prywatności'}>
                <PrivacePage />
            </Route>

            {/* <Route path={'/debug'}><DebugPage /></Route> */}

            <Route component={HomePage} />
        </Switch>
    </>
}
