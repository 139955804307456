import { useQuery } from "react-query";
import Network from "../../network";


export type TaskWidget = {
    type: 'widget',
    params: {
        id: string
    }
}

export type TaskText = {
    type: 'html',
    params: {
        html: string
    }
}

export type TaskContent = TaskText | TaskWidget;

export type Task = {
    id: string,
    title: string,
    subtitle: string,
    awards: string[],
    achievements: string[],
    requires: string[],
    postTaskInfoUrl: string,
    content: TaskContent[]
}

export default function useTasks() {
    const {data, isLoading} = useQuery('get-tasks', Network.getTasks, {
        staleTime: Infinity
    });

    return {
        isLoading,
        tasks: data as Task[]
    }
}
