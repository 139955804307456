
import { useEffect } from 'react';
import StandardInsert from '../../../../layout/standard-insert';
import './style.scss';

type Props = {
    onComplete?: () => void
}

const Task404Widget: React.FC<Props> = ({ onComplete: completeCallback }) => {

    const img = {
        step1: '/assets/tasks/task-404/step1.svg',
        step2: '/assets/tasks/task-404/step2.svg',
        step3: '/assets/tasks/task-404/step3.svg',
        step3a: '/assets/tasks/task-404/step3a.svg',
        step3b: '/assets/tasks/task-404/step3b.svg',
        step4: '/assets/tasks/task-404/step4.svg',
        step5: '/assets/tasks/task-404/step5.svg',
        step6: '/assets/tasks/task-404/step6.svg',
        step7: '/assets/tasks/task-404/step7.svg'
    }

    useEffect(() => {
        if(completeCallback) {
            completeCallback();
        }
    }, [completeCallback])

    return <div className="task-404">
        <StandardInsert>
            <div className="manual">
                <div className="step step-with-img">
                    <div className="left-wrapper wrapper">
                        <p>1. Będziemy potrzebowali: pustego litrowego kartonu po soku (najlepiej o kwadratowej podstawie), nożyczek lub nożyka, zszywacza</p>
                        <img src={img.step1} alt="" />
                    </div>
                </div>
                <div className="step step-with-img">
                    <div className="right-wrapper wrapper">
                        <p>2. Pusty karton po napoju odkręcamy (uwaga: nie wolno zgubić nakrętki!)</p>
                        <img src={img.step2} alt="" />
                    </div>
                </div>
                <div className="step step-with-img">
                    <div className="right-wrapper wrapper">
                        <p>3. Odginamy dolne i górne “uszka” kartonu i rozpłaszczamy go.</p>
                        <img src={img.step3a} alt="" />
                    </div>
                </div>
                <div className="step step-with-img">
                    <div className="right-wrapper wrapper">
                        <p>4. Rozcinamy górną i dolną krawędź kartonu. Dokładnie myjemy go w środku</p>
                        <img src={img.step3b} alt="" />
                    </div>
                </div>
                <div className="step step-with-img">
                    <div className="left-wrapper wrapper">
                        <p>5. Boki kartonu zaginamy do środka</p>
                        <img src={img.step4} alt="" />
                    </div>
                </div>
                <div className="step step-with-img">
                    <div className="right-wrapper wrapper">
                        <p>6. Zaginamy karton w ⅓ wysokości (licząc od góry) tak, aby strona z gwintem znalazła się na zewnątrz</p>
                        <img src={img.step5} alt="" />
                    </div>
                </div>
                <div className="step step-with-img">
                    <div className="left-wrapper wrapper">
                        <p>7. Niezagiętą część kartonu (dolna ⅓) przycinamy w taki sposób, aby została tylko jej tylna ścianka. Wycinamy w niej dziurę w miejscu, w którym, po zagięciu do środka, znajdzie się gwint od nakrętki. Średnica otworu powinna dokładnie odpowiadać średnicy gwintu. W ten sposób powstanie zamknięcie do naszego portfela.</p>
                        <img src={img.step6} alt="" />
                    </div>
                </div>
                <div className="step step-with-img">
                    <div className="right-wrapper wrapper">
                        <p>8. Tak przygotowany portfel musimy zszyć zszywaczem w środku, aby nie stracił formy. </p>
                        <img src={img.step7} alt="" />
                    </div>
                </div>
            </div>
        </StandardInsert>
    </div >
}

export default Task404Widget;
