import { useMemo } from "react";
import useConfig from "../../config/hooks/use-config";
import useGeo from "../../geo/hooks/use-geo"

export default function useGeoWithLimit() {

    const { position, accuracy, error, hasPosition } = useGeo();
    const { config } = useConfig();

    const givePosition: boolean = useMemo(() => {
        return (accuracy && accuracy > config.maxError) ? false : hasPosition;
    }, [accuracy, hasPosition, config.maxError])

    return {
        hasPosition: givePosition,
        position,
        accuracy,
        error
    }
}
