import TaskWidget from "./widget";

export type WidgetConfig = {
    container: any,
    onComplete: () => void
}

const BASE_WIDTH = 900;
const BASE_HEIGHT = 499;

export default function createWidget(config: WidgetConfig) {

    const app = new TaskWidget(BASE_WIDTH, BASE_HEIGHT, config);

    config.container.appendChild(app.view);

    return {
        destroy: () => {
            app.destroy();
        }
    }
}
