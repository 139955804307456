
import { useEffect, useMemo, useRef, useState } from 'react';
import { useCallback } from 'react';
import StandardInsert from '../../../../layout/standard-insert';
import DICTIONARY from './dictionary';
import './style.scss';
import useChainInput from './use-chain-input';

type Props = {
    onComplete?: () => void
}

export type Letter = {
    letter: string,
    src: string
}

const Task204Widget: React.FC<Props> = ({ onComplete: completeCallback }) => {

    const code: Letter[] = DICTIONARY;

    const passwords = useMemo(() => ['nie śmieć!', 'chroń zwierzęta w lesie!'], []);

    const { registerInput, registerContainer } = useChainInput();

    const [state, setState] = useState<'unchecked' | 'correct' | 'incorrect'>('unchecked');

    const passContainer = useRef<HTMLDivElement>(null);
    const validate = useCallback(() => {
        if (passContainer.current) {
            const inputs = passContainer.current.querySelectorAll('input');

            let value = '';
            inputs.forEach((i) => {
                value = value + i.value;
            })

            if (normalizePassword(value) === normalizePassword(passwords.join(''))) {
                setState('correct');
            } else {
                setState('incorrect');
                inputs.forEach((i) => {
                    i.value = '';
                })
            }
        }
    }, [passContainer, passwords])

    useEffect(() => {
        if (state === 'correct' && completeCallback) {
            completeCallback();
        }
    }, [state, completeCallback]);

    return <div className="task-204">
        <StandardInsert>

            <div className="code-key">
                <div className="sentence">
                    <div className="word">
                        {
                            code.map(c => {
                                return <div className="piece" key={c.letter}>
                                    <div className="cell">
                                        <span className="letter">{c.letter}</span>
                                    </div>
                                    <div className="cell">
                                        <img className="symbol" src={c.src} alt="" />
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </div>
            </div>

            <div className={`password ${state}`} ref={registerContainer}>
                <div ref={passContainer}>
                    {
                        passwords.map((sentence, index) => {
                            return <div className="sentence" key={index}>
                                {sentence.split(' ').map((word, index) => {
                                    return <div className="word" key={index}>
                                        {
                                            word.split('').map((char, index) => {
                                                const codeData = code.find(c => c.letter === char);

                                                if (codeData) {
                                                    return <label className="piece" key={index}>
                                                        <div className="cell">
                                                            <img className="symbol" src={codeData.src} alt="" />
                                                        </div>
                                                        <div className="cell">
                                                            <input ref={registerInput} className="input" type="text" />
                                                        </div>
                                                    </label>
                                                }

                                                if (char === '!') {
                                                    return <div className="piece" key={index}>
                                                        <div className="cell empty">

                                                        </div>
                                                        <div className="cell">
                                                            <span className="letter">!</span>
                                                        </div>
                                                    </div>
                                                }

                                                return <></>
                                            })
                                        }
                                    </div>
                                })}
                            </div>
                        })
                    }
                </div>
            </div>

            <button className="button" onClick={validate}>Sprawdź</button>
        </StandardInsert>

    </div>
}


export default Task204Widget;


function normalizePassword(s: string): string {
    return s
        .toLowerCase()
        .replace(/ /g, '')
        .replace(/!/g, '');
}
