import useGeoTask from "../../../../../../modules/game/hooks/use-geo-task";
import './style.scss';

type Props = {
    id: string,
    index: number,
    count: number,
    onNext: () => void
}

const GeoTaskIntroduction: React.FC<Props> = ({ id, index, count, onNext }) => {

    const geoTask = useGeoTask(id)

    return <>
        {geoTask && <div className='geo-task-introduction'>
            <div className="group">
                <h1>{index}/{count} {geoTask.name}</h1>
                <div className="img">
                    <img src={geoTask.image} alt="" />
                </div>
            </div>
            <div className="group">
                <div className="description" dangerouslySetInnerHTML={{ __html: geoTask.intro }}></div>
                <div className="buttons">
                    <button className='button' onClick={onNext}>Dalej</button>
                </div>
            </div>
        </div>}
    </>
}

export default GeoTaskIntroduction;
