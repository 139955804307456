import React from "react";
import LoginForm from "../../components/login-form";
import Logos3 from "../../components/logos/logos-3";
import Box from "../../layout/box";
import StandardInsert from "../../layout/standard-insert";
import './style.scss'

export default function LoginPage() {
    return <div className="page login-page">
        <Box className="login-box">
            <StandardInsert>
                <h1>Witaj, wpisz swój nick</h1>
                <LoginForm />
            </StandardInsert>
        </Box>
        <Box className='bg-white no-padding'>
            <Logos3 />
        </Box>
    </div>
}
