import TaskWidget from "./widget";

export type WidgetConfig = {
    container: any,
    image: string,
    width: number,
    height: number
}

export default function createDrawingWidget(config: WidgetConfig) {

    const app = new TaskWidget(config.width, config.height, config);

    config.container.appendChild(app.view);

    return {
        destroy: () => app.destroy(),
        reset: () => app.reset()
    }
}
