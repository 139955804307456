
import React, { useEffect } from 'react';
import StandardInsert from '../../../../layout/standard-insert';
import './style.scss';

type Props = {
    onComplete?: () => void
}

const Task201Widget: React.FC<Props> = ({ onComplete: completeCallback }) => {

    const img = {
        step1: '/assets/tasks/task-201/step1.svg',
        step2: '/assets/tasks/task-201/step2.svg',
        step3: '/assets/tasks/task-201/step3.svg',
        step4: '/assets/tasks/task-201/step4.svg',
        step5: '/assets/tasks/task-201/step5.svg',
        step6: '/assets/tasks/task-201/step6.svg',
        step7: '/assets/tasks/task-201/step7.svg'
    }

    useEffect(() => {
        if (completeCallback) {
            completeCallback();
        }
    }, [completeCallback])

    return <div className="task-201">
        <StandardInsert>
            <div className="manual">
                <div className="step step-with-img">
                    <div className="left-wrapper wrapper">
                        <p>1. PRZYGOTUJ: PUSTĄ, PLASTIKOWĄ BUTELKĘ, DWA ARKUSZE PAPIERU (RÓŻOWY I CZARNY), KLEJ I NOŻYCZKI</p>
                        <img src={img.step1} alt="" />
                    </div>
                </div>
                <div className="step step-with-img">
                    <div className="right-wrapper wrapper">
                        <p>2. WYTNIJ POSZCZEGÓLNE ELEMENTY</p>
                        <img src={img.step2} alt="" />
                    </div>
                </div>
                <div className="step step-with-img">
                    <div className="left-wrapper wrapper">
                        <p>3. SKLEJ PASKI I ODEGNIJ SKRZYDEŁKA NA BOKI</p>
                        <img src={img.step3} alt="" />
                    </div>
                </div>
                <div className="step step-with-img">
                    <div className="left-wrapper wrapper">
                        <p>4. WYTNIJ W BUTELCE OTWÓR NA PIENIĄDZE, OKLEJ BUTELKĘ PASKIEM PAPIERU I SKLEJ</p>
                        <img src={img.step4} alt="" />
                    </div>
                </div>
                <div className="step step-with-img">
                    <div className="right-wrapper wrapper">
                        <p>5. WYTNIJ OTWÓR NA PIENIĄDZE W PAPIERZE, A OD DOŁU PRZYKLEJ CZTERY NOGI</p>
                        <img src={img.step5} alt="" />
                    </div>
                </div>
                <div className="step step-with-img">
                    <div className="left-wrapper wrapper">
                        <p>6. ZAGNIJ SKRZYDEŁKA I DOKLEJ USZY PO BOKACH, A OD PRZODU RYJEK I OCZY</p>
                        <img src={img.step6} alt="" />
                    </div>
                </div>
                <div className="step step-with-img">
                    <div className="right-wrapper wrapper">
                        <p>7. NA KONIEC PODKRĘĆ NOŻYCZKAMI OGONEK, DOKLEJ Z TYŁU BUTELKI I ŚWINKA-SKARBONKA GOTOWA!</p>
                        <img src={img.step7} alt="" />
                    </div>
                </div>
            </div>
            <div className="buttons">
                <a className="button" download="swinka.pdf" href="/assets/tasks/task-201/swinka.pdf">Szablon do wydruku</a>
            </div>
        </StandardInsert>
    </div >
}

export default Task201Widget;
