import { ErrorMessage } from '@hookform/error-message';
import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import useCreateUser from '../../modules/user/hooks/use-create-user';
import './style.scss'

type Props = {}

const LoginForm: React.FC<Props> = () => {

    const { register, handleSubmit, errors } = useForm();
    const createUser = useCreateUser();

    const onSubmit = useCallback((data) => {
        createUser(data.login);
    }, [createUser]);

    return <div className="login-form">
        <form onSubmit={handleSubmit(onSubmit)}>

            <label>
                <input type="text" name="login" id="" ref={register({ required: true })} /><br />
                <p>Twój nick / ksywka</p>
                <ErrorMessage errors={errors} name="login" />
            </label>

            <div className="buttons">
                <button className="button" type="submit">Rozpocznij grę</button>

            </div>

        </form>
    </div>
}

export default LoginForm;
