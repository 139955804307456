import { forwardRef, useRef, useState, useCallback, useEffect, useImperativeHandle } from "react";
import React from "react";
import createGame, { GameConfig } from "./game";


const GENERATOR_KEY = 'dd2020-gen'

type Props = {
    stickers: {
        src: string;
        iconSrc: string;
        key: string;
        isAvaiable: boolean;
        unavaialbeLabel: any;
    }[],
    name?: string
}

type API = {
    getImage: () => Promise<string | HTMLCanvasElement>,
    reset: () => void
}

const GameComponent = forwardRef<API, Props>(
    ({ stickers }, ref) => {
        const generatorContainer = useRef<HTMLDivElement>(null);

        const saveState = useCallback((state: GameConfig['state']) => {
            window.localStorage.setItem(GENERATOR_KEY, JSON.stringify(state));
        }, [])

        const getSavedState = useCallback(() => {
            try {
                return JSON.parse(window.localStorage.getItem(GENERATOR_KEY) || '{stickers: []}');
            } catch (e) {
                return {
                    stickers: []
                }
            }
        }, []);

        const [gameAPI, setGameAPI] = useState<API>()

        useImperativeHandle(ref, () => ({
            getImage() {
                if (!gameAPI) return Promise.resolve('');
                return gameAPI.getImage();
            },
            reset() {
                if (gameAPI) {
                    gameAPI.reset();
                }
            }
          }));

        // Inicjalizacja gry;
        useEffect(() => {

            const gameConfig = {
                container: generatorContainer.current,

                objects: stickers,
                state: getSavedState() || [],

                onChange: saveState,
                userName: '',
            }

            const {
                getSceenBlob,
                uninstall,
                reset
            } = createGame(gameConfig)

            setGameAPI({
                getImage: getSceenBlob,
                reset
            })

            return () => {
                uninstall();
            }

        }, [stickers, getSavedState, saveState])

        return (
            <div ref={generatorContainer} className="gameContainer" style={{maxWidth: '1778px', width: '90%', margin: '0 auto', userSelect: 'none'}}></div>
        )
    }
);

export default GameComponent;
