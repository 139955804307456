import { MainModuleState, MainModuleAction, MAIN_MODULE_ACTION } from ".";

export default function MainReducer(state: MainModuleState, action: MainModuleAction): MainModuleState {
    const {type, payload} = action;
    switch (type) {
        case MAIN_MODULE_ACTION.FETCH_CONFIG:
            return {
                ...state,
                isLoading: true
            }
        case MAIN_MODULE_ACTION.FETCH_CONFIG_SUCCESS:
            return {
                ...state,
                user: payload.user || null,
                isLoading: false,
            }
    }

    return state;
}
