
import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { useMemo } from 'react';
import StandardInsert from '../../../../layout/standard-insert';
import Popup from '../../../popup';
import './style.scss';

type Props = {
    onComplete?: () => void
}

type Item = {
    src: string,
    question: string,
    correct: string,
    postfix?: string,
    message: string
}

const Task202Widget: React.FC<Props> = ({ onComplete: completeCallback }) => {

    const data: Item[] = useMemo(() => {
        return [{
            src: '/assets/tasks/task-202/jez.png',
            question: 'JŻE',
            correct: 'JEŻ',
            postfix: 'ZACHODNI',
            message: 'Atrybutem tego zwierzęcia są jego wyjątkowe, przekształcone włosy. Ich ilość zależy od wieku i wielkości zwierzęcia – u dorosłego osobnika jest ich około 5 tysięcy, ale u największych doliczono się nawet 7 tysięcy kolców. Dzięki silnemu mięśniowi płaszczowemu zwierzę to w chwili zagrożenia zwija się w najeżoną kolcami kulkę.'
        }, {
            src: '/assets/tasks/task-202/zuk.png',
            question: 'ŻKU',
            correct: 'ŻUK',
            postfix: 'LEŚNY',
            message: 'Mówi się o nim leśny czyściciel, ponieważ upycha martwą materię organiczną w drążone przez siebie podziemne korytarze oraz zawleka szczątki roślin i odchody zwierząt roślinożernych pod ziemię.'
        }, {
            src: '/assets/tasks/task-202/jaszczurka.png',
            question: 'JSZRUCZAAK',
            correct: 'JASZCZURKA',
            postfix: 'ŻYWORODNA',
            message: 'Zwierzę to ma niezwykłą zdolność odrzucenia części swojego ciała! W sytuacji zagrożenia jego ogon „odcina się” od reszty ciała. Po co? Po to, by drapieżnik skupił się na odrzuconym ogonie, a napadnięte zwierzę miało czas na ucieczkę.'
        }, {
            src: '/assets/tasks/task-202/ryjowka.png',
            question: 'RAKÓWJY',
            correct: 'RYJÓWKA',
            postfix: 'AKSAMITNA',
            message: 'Ciekawym zjawiskiem zaobserwowanym u tych zwierząt jest przechodzenie na „zimowy tryb oszczędny”, który włącza się w okresie utrudnionej dostępności pokarmu. Polega ono na zmniejszeniu narządów wewnętrznych - ciało staje się krótsze, zmniejsza się ogólna jego masa, a nawet mózg i pojemność puszki mózgowej. Mniejsze zwierzę to mniejsze zapotrzebowanie na pokarm.'
        }]
    }, [])

    const [popup, setPopup] = useState<{
        show: boolean,
        text?: string,
        img?: string
    }>({ show: false })

    const [state, setCompleted] = useReducer((state: string[], id: string) => {
        return Array.from(new Set([...state, id]));
    }, [])

    const checkAnswer = useCallback((answer: string, correct: string, message: string, img: string, target: HTMLInputElement) => {
        if (answer.trim().toLowerCase() === correct.trim().toLowerCase()) {
            setCompleted(correct);
            setPopup({
                show: true,
                text: message,
                img
            });
            target.blur();
        }
    }, [setPopup])

    useEffect(() => {
        if (completeCallback && state.length === data.length) {
            completeCallback();
        }
    }, [state, data, completeCallback])

    return <div className="task-202">
        <StandardInsert>
            <div className="list">
                {
                    data.map((item, index) => {
                        return <div className="item" key={index}>
                            <div className="wrapper">
                                <div className="img">
                                    <img src={item.src} alt="" />
                                </div>
                                <div className="question">
                                    {item.question.split(' ').map(word => {
                                        return <div className="word" key={word}>
                                            {
                                                word.split('').map((letter, index) => {
                                                    return <span className="letter" key={index}><span>{letter}</span></span>
                                                })
                                            }
                                        </div>
                                    })}
                                </div>
                                <label className="answer">
                                    <div className="row">
                                        <input
                                            type="text"
                                            onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                                checkAnswer(
                                                    e.currentTarget.value,
                                                    item.correct,
                                                    item.message,
                                                    item.src,
                                                    e.currentTarget
                                                )
                                            }}
                                            onKeyDown={popup.show ? () => { setPopup({ show: false }) } : () => { }}
                                        /><span>{item.postfix}</span>
                                    </div>
                                    <div className="tip">
                                        tu wpisz ułożoną nazwę zwierzęcia
                                    </div>
                                </label>
                            </div>
                        </div>
                    })
                }
            </div>
        </StandardInsert>
        {
            popup.show && <Popup onClick={() => { setPopup({ show: false }) }}>
                <p dangerouslySetInnerHTML={{ __html: popup.text || '' }}></p>
                <img src={popup.img} alt="" />
            </Popup>
        }
    </div>
}

export default Task202Widget;
