import React, { useCallback, useState } from "react";
import { useHistory } from "react-router";
import useCompleteTask from "../../modules/tasks/hooks/use-complete-task";
import { Task } from "../../modules/tasks/hooks/use-tasks";
import { getAwardRoute, getMapRoute } from "../../routes/routes";
import TaskWidget from "./widget";
import './style.scss';
import { Link } from "react-router-dom";
import StandardInsert from "../../layout/standard-insert";

type Props = {
    data: Task
}

const TaskComponent: React.FC<Props> = ({ data }) => {

    const completeTask = useCompleteTask();
    const [showCompleteButtomn, setShowCompleteButton] = useState(false);
    const history = useHistory();

    const onClickComplete = useCallback(() => {
        completeTask(data.id);
        history.push(getAwardRoute(data.id))
    }, [completeTask, data, history]);

    const onTaskComplete = useCallback(() => {
        setShowCompleteButton(true);
    }, [setShowCompleteButton])


    return <div className="task">

        <StandardInsert>
            <div className="task-header">
                <div className="row-1">
                    <div className="back">
                        <Link className='button' to={getMapRoute()}>Wróć{'\u00A0'}do{'\u00A0'}mapy</Link>
                    </div>
                    <h1>{data.title}</h1>
                </div>
                <h2>{data.subtitle}</h2>
            </div>

        </StandardInsert>

        {
            data.content.map((c, index) => {
                return <div className="task-content-block" key={data.id + index}>
                    {
                        (c.type === 'widget') && <TaskWidget id={c.params.id} onComplete={onTaskComplete} />
                    }
                    {
                        (c.type === 'html') && <StandardInsert><div className="html-block" dangerouslySetInnerHTML={{ __html: c.params.html }} ></div></StandardInsert>
                    }
                </div>
            })
        }

        <StandardInsert><div className="complete-button">
            {showCompleteButtomn && <button className="button" onClick={onClickComplete}>Zadanie wykonane</button>}
        </div></StandardInsert>

    </div>
}

export default TaskComponent;
