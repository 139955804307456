import React, { useMemo } from "react";
import AudioPlayer from "../../components/audio-player";
import Logos3 from "../../components/logos/logos-3";
import Box from "../../layout/box";
import StandardInsert from "../../layout/standard-insert";
import './style.scss';

type Props = {
    id: string
}

type PageInfo = {
    id: string,
    title: string,
    subtitle: string,
    description: string,
    audio: string
}

const AudioPage: React.FC<Props> = ({ id }) => {

    const page = useMemo(() => {
        const pages: PageInfo[] = [{
            id: "1",
            title: "Nagranie 1.",
            subtitle: "Czyta Krystyna Czubówna",
            description: 'Posłuchaj jakich błędów unikać podczas segregacji odpadów.',
            audio: '/assets/audio/bioodpady-1.wav'
        }, {
            id: "2",
            title: "Nagranie 2.",
            subtitle: "Czyta Krystyna Czubówna",
            description: 'Posłuchaj, co dzieje się z plastikową butelką z żółtego pojemnika.',
            audio: '/assets/audio/bioodpady-2.wav'
        }, {
            id: "3",
            title: "Nagranie 3.",
            subtitle: "Czyta Krystyna Czubówna",
            description: 'Posłuchaj, jakie zagrożenia niosą ze sobą śmieci porzucone w naturze.',
            audio: '/assets/audio/bioodpady-3.wav'
        }]
        return pages.find(p => p.id === id);
    }, [id]);

    return <div className="page audio-page">
        {page &&
            <Box>
                <StandardInsert>
                    <h1>{page.title}</h1>
                    <h2>{page.subtitle}</h2>
                    <p>{page.description}</p>
                    <div className="player-container">
                        <AudioPlayer src={page.audio} />
                    </div>
                </StandardInsert>
            </Box>
        }

        <Box className='bg-white no-padding'>
            <Logos3 />
        </Box>
    </div>
}

export default AudioPage;
