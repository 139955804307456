import React, { useCallback, useState } from "react";
import useGame from "../../modules/game/hooks/use-game";
import useRestartGame from "../../modules/game/hooks/use-restart-game";
import CenterBox from "../game/layout/center-box";
import './style.scss';
import CameraOn from '../../../assets/geo/camera-on.svg';
import Poi from '../../../assets/geo/poi.svg';
import useSetSettings from "../../modules/config/hooks/use-set-settings";

type Props = {
    onNext: () => void
}

const WelcomeScreen: React.FC<Props> = ({ onNext }) => {

    const { started } = useGame();
    const restart = useRestartGame();
    const setSettings = useSetSettings();

    const [step, setStep] = useState(0);

    const onStart = useCallback(() => {
        restart();
        setStep(1);
    }, [restart, setStep]);

    const onContinue = useCallback(() => {
        setStep(1);
    }, [setStep]);

    const onNewGame = useCallback(() => {
        restart();
        setStep(1);
    }, [setStep, restart]);

    const onCameraYes = useCallback(() => {
        setSettings({
            tryCamera: true
        })
        onNext();
    }, [onNext, setSettings])

    const onCameraNo = useCallback(() => {
        setSettings({
            tryCamera: false
        })
        onNext();
    }, [onNext, setSettings])

    return <div className="geo-game-screen welcome-screen">
        {(step === 0) && <CenterBox className="screen-container">
            <h1>Witaj Zuchu!</h1>
            <p>Dzięki, że zeskanowałeś QR kod! Przed Tobą niezwykła gra zlokalizowana w przestrzeni wokół niego! Jednak by mogła działać, koniecznie udostępnij swoją <strong>lokalizację</strong>.</p>
            <img className="img-poi" src={Poi} alt=""/>
            <div className="buttons">
                {!started && <button className='button' onClick={onStart}>DALEJ</button>}
                {started && <>
                    <button className='button' onClick={onContinue}>Kontynuuj grę</button>
                    <button className='button' onClick={onNewGame}>Graj od nowa</button>
                </>}
            </div>
        </CenterBox>}
        {(step === 1) && <CenterBox className="screen-container">
            <p>Gra wykorzystuje technologię rozszerzonej rzeczywistości. Jeżeli chcesz przejść rundę w jej pełnym wymiarze, czy zgadzasz się na udostępnienie <strong>kamery</strong>?</p>
            <img className="img-camera" src={CameraOn} alt=""/>
            <div className="buttons">
                <button className='button' onClick={onCameraYes}>TAK</button>
                <button className='button' onClick={onCameraNo}>NIE</button>
            </div>
        </CenterBox>}

    </div>
}

export default WelcomeScreen;
