
import React, { useCallback, useMemo, useState } from 'react';
import StandardInsert from '../../../../layout/standard-insert';
import MemoryGame from '../../../memo';
import './style.scss';

type Props = {
    onComplete?: () => void
}

const Task402Widget: React.FC<Props> = ({ onComplete: completeCallback }) => {

    const [showJupi, setShowJupi] = useState(false);
    const onComplete = useCallback(() => {
        if (completeCallback) {
            completeCallback();
        }

        setShowJupi(true);
    }, [completeCallback, setShowJupi]);


    const data = useMemo<[string, string][]>(() => {
        return [
            [
                '/assets/tasks/task-402/2.png',
                '/assets/tasks/task-402/3.png'
            ],
            [
                '/assets/tasks/task-402/4.png',
                '/assets/tasks/task-402/5.png'
            ],
            [
                '/assets/tasks/task-402/6.png',
                '/assets/tasks/task-402/7.png'
            ],
            [
                '/assets/tasks/task-402/8.png',
                '/assets/tasks/task-402/9.png'
            ],
            [
                '/assets/tasks/task-402/10.png',
                '/assets/tasks/task-402/11.png'
            ],
            [
                '/assets/tasks/task-402/12.png',
                '/assets/tasks/task-402/1.png'
            ]
        ]
    }, [])

    return <div className="task-402">
        <StandardInsert>
            <MemoryGame items={data} backImage='/assets/tasks/task-402/back.png' onComplete={onComplete} />
            {showJupi && <div className="jupi-container">
                <h3 className="jupi">GRATULACJE!<br />Teraz możesz ruszyć dalej, klikając poniżej "ZADANIE{`\u00a0`}WYKONANE"</h3>
            </div>}
        </StandardInsert>
    </div>
}

export default Task402Widget;
