
import React, { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import StandardInsert from '../../../../layout/standard-insert';
import './style.scss';

//@ts-ignore
import { Preview } from 'react-dnd-preview'
import PickUp from '../../../drag-drop-template/pick-up';
import DropZone from '../../../drag-drop-template/drop-zone';
import { FormEvent } from 'react';
import Popup from '../../../popup';

type Props = {
    onComplete?: () => void
}

type Item = {
    id: string,
    src: string,
    message: string
}

type Spot = {
    id: string,
    time: 'week' | 'year' | 'age',
    value: number,
    correct: string,
    placed: string | null
}

type GameState = {
    items: Item[];
    unplaced: string[];
    spots: Spot[]
}

const Task103Widget: React.FC<Props> = ({ onComplete: completeCallback }) => {

    const initState: GameState = useMemo(() => {

        const items: Item[] = [
            {
                id: 'gazeta',
                src: '/assets/tasks/task-103/gazeta.png',
                message: 'Gazeta rozkłada się około 6 tygodni'
            },
            {
                id: 'butelka',
                src: '/assets/tasks/task-103/butelka.png',
                message: 'Szkło, na przykład szklana butelka, rozkłada się w ciągu około 4 tysięcy lat - to ponad 200 tysięcy tygodni!'
            },
            {
                id: 'guma',
                src: '/assets/tasks/task-103/guma.png',
                message: 'Guma do żucia rozkłada się około 5 lat, czyli aż około 260 tygodni'
            },
            {
                id: 'jablko',
                src: '/assets/tasks/task-103/jablko.png',
                message: 'Ogryzek jabłka rozkłada się około 18 tygodni'
            },
            {
                id: 'torba',
                src: '/assets/tasks/task-103/torba.png',
                message: 'Foliowa reklamówka rozkłada się około 300 lat - ponad 15 tysięcy tygodni'
            }
        ];

        const spots: Spot[] = [
            {
                id: 'jablko',
                correct: 'jablko',
                time: 'week',
                value: 18,
                placed: null
            },
            {
                id: 'guma',
                correct: 'guma',
                time: 'year',
                value: 5,
                placed: null
            },
            {
                id: 'gazeta',
                correct: 'gazeta',
                time: 'week',
                value: 6,
                placed: null
            },
            {
                id: 'torba',
                correct: 'torba',
                time: 'age',
                value: 3,
                placed: null
            },
            {
                id: 'butelka',
                correct: 'butelka',
                time: 'age',
                value: 40,
                placed: null
            }
        ]

        return {
            items: items,
            unplaced: items.map(i => i.id).sort(() => Math.random() - .5),
            spots
        }
    }, []);

    const [state, userAction] = useReducer((state: GameState, action: { itemId: string, spotId: string }) => {
        return {
            ...state,
            unplaced: state.unplaced.filter(i => i !== action.spotId),
            spots: state.spots.map(s => {
                if (s.id === action.spotId) {
                    return {
                        ...s,
                        placed: action.itemId
                    }
                }

                return { ...s }
            })
        }
    }, initState)

    const [timelineHeader, setTimelineHeader] = useState('weeks');

    const timeWindowRef = useRef<HTMLDivElement>(null);
    const weeksRef = useRef<HTMLDivElement>(null);
    const yearsRef = useRef<HTMLDivElement>(null);
    const agesRef = useRef<HTMLDivElement>(null);
    const positionInputRef = useRef<HTMLInputElement>(null);

    const markerRef = useRef<HTMLDivElement>(null);

    const [popup, setPopup] = useState<{
        show: boolean,
        text?: string,
        img?: string
    }>({ show: false })

    const weeks = useMemo(() => {
        return Array(104).fill('')
            .map((_, index) => {
                return index + 1;
            })
    }, [])

    const years = useMemo(() => {
        return Array(99 - 2).fill('')
            .map((_, index) => {
                return index + 3;
            })
    }, []);

    const ages = useMemo(() => {
        return Array(42 - 1).fill('')
            .map((_, index) => {
                return index + 2;
            })
    }, []);

    const onDrop = useCallback((pickupData: string, dropId: string) => {

        const spot = state.spots.find(s => s.id === dropId);

        if (spot) {
            if (spot.correct === pickupData) {

                const item = state.items.find(i => i.id === pickupData);

                userAction({ itemId: pickupData, spotId: dropId })
                setPopup({
                    show: true,
                    text: item?.message,
                    img: item?.src
                })
            }
        }
    }, [state]);

    const weeksElements = useMemo(() => {
        return weeks.map((week) => {
            const spot = state.spots.find(s => (s.time === 'week' && s.value === week));
            const item = spot?.placed && state.items.find(i => i.id === spot.placed);
            return <div className={`time-zone ${spot ? 'with-spot' : ''}`} key={week}>
                <div className="top"></div>
                <div className="cell">
                    {spot && <div className={`spot ${item ? 'with-item' : 'empty'}`}>
                        <DropZone targetType='item' id={spot.id} onDrop={onDrop}>
                            {item && <GameItem item={item} />}
                        </DropZone>
                    </div>}
                </div>
                <div className="label"><span>{week}</span></div>

            </div>
        })
    }, [weeks, state, onDrop]);

    const yearsElements = useMemo(() => {
        return years.map((year) => {
            const spot = state.spots.find(s => (s.time === 'year' && s.value === year));
            const item = spot?.placed && state.items.find(i => i.id === spot.placed);
            return <div className={`time-zone ${spot ? 'with-spot' : ''}`} key={year}>
                <div className="top"></div>
                <div className="cell">
                    {spot && <div className={`spot ${item ? 'with-item' : 'empty'}`}>
                        <DropZone targetType='item' id={spot.id} onDrop={onDrop}>
                            {item && <GameItem item={item} />}
                        </DropZone>
                    </div>}
                </div>
                <div className="label"><span>{year}</span><span className="small">{year * 52} tygodni</span></div>

            </div>
        })
    }, [years, state, onDrop])

    const agesElements = useMemo(() => {
        return ages.map((age) => {
            const spot = state.spots.find(s => (s.time === 'age' && s.value === age));
            const item = spot?.placed && state.items.find(i => i.id === spot.placed);
            return <div className={`time-zone ${spot ? 'with-spot' : ''}`} key={age}>
                <div className="top"></div>
                <div className="cell">
                    {spot && <div className={`spot ${item ? 'with-item' : 'empty'}`}>
                        <DropZone targetType='item' id={spot.id} onDrop={onDrop}>
                            {item && <GameItem item={item} />}
                        </DropZone>
                    </div>}
                </div>
                <div className="label"><span>{age}</span><span className="small">{age * 52 * 100} tygodni</span></div>

            </div>
        })
    }, [ages, state, onDrop])

    const onInputValueSet = useCallback((value: number) => {
        if (timeWindowRef.current) {
            if (timeWindowRef.current.scrollTo) {
                timeWindowRef.current.scrollTo({
                    left: value
                })
            } else {
                timeWindowRef.current.scrollLeft = value;
            }
        }

        if (markerRef.current && positionInputRef.current) {

            try {
                const markerPosition = value / parseInt(positionInputRef.current.getAttribute('max') || '');
                markerRef.current.style.left = `${100 * markerPosition}%`;
                markerRef.current.style.marginLeft = `${-markerPosition * markerRef.current.offsetWidth}px`;

            } catch (e) { }

        }
    }, [timeWindowRef, positionInputRef])

    const onInput = useCallback((e: FormEvent<HTMLInputElement>) => {
        onInputValueSet(parseInt(e.currentTarget.value));
    }, [onInputValueSet]);

    const onScroll = useCallback((e: React.UIEvent) => {

        if (timeWindowRef.current && weeksRef.current && yearsRef.current) {
            const left = timeWindowRef.current.scrollLeft

            if (left > yearsRef.current.offsetWidth + weeksRef.current.offsetWidth) {
                setTimelineHeader('ages')
            } else if (left > weeksRef.current.offsetWidth) {
                setTimelineHeader('years')
            } else {
                setTimelineHeader('weeks')
            }

            if (positionInputRef.current) {
                if (parseInt(positionInputRef.current.value) !== left) {
                    //@ts-ignore
                    onInputValueSet(left);
                }
            }
        }


    }, [timeWindowRef, setTimelineHeader, weeksRef, positionInputRef, onInputValueSet])

    const [timelineWidth, setTimelineWidht] = useState(100);

    const updateTimelineWidht = useCallback(() => {
        if (weeksRef.current && yearsRef.current && agesRef.current) {
            setTimelineWidht(weeksRef.current.offsetWidth + yearsRef.current.offsetWidth + agesRef.current.offsetWidth)
        }
    }, [setTimelineWidht, weeksRef, yearsRef, agesRef]);

    useEffect(() => {
        updateTimelineWidht();
        window.addEventListener('resize', updateTimelineWidht);

        return () => {
            window.removeEventListener('resize', updateTimelineWidht);
        }
    }, [updateTimelineWidht]);

    useEffect(() => {
        if (timeWindowRef.current) {
            const w = timeWindowRef.current;

            w.style.bottom = w.clientHeight - w.offsetHeight + "px";
        }
    }, [timeWindowRef])


    useEffect(() => {

        if (timeWindowRef.current) {

            const container = timeWindowRef.current;
            let isDragging = false;

            const onDragStart = () => {
                isDragging = true;
            }

            const onDrag = (e: MouseEvent) => {
                if (isDragging) {
                    if (container.scrollBy) {
                        container.scrollBy({
                            left: -e.movementX
                        })
                    } else {
                        container.scrollLeft = container.scrollLeft - e.movementX
                    }
                }
            }

            const onDragStop = () => {
                isDragging = false;
            }

            container.addEventListener('mousedown', onDragStart);
            container.addEventListener('mousemove', onDrag)
            container.addEventListener('mouseup', onDragStop);
            container.addEventListener('mouseleave', onDragStop);

            return () => {
                container.removeEventListener('mousedown', onDragStart);
                container.removeEventListener('mousemove', onDrag)
                container.removeEventListener('mouseup', onDragStop);
                container.removeEventListener('mouseleave', onDragStop);
            }
        }

    }, [timeWindowRef]);

    useEffect(() => {
        const complete = state.spots.reduce((prev: boolean, next: Spot) => {
            return prev && next.placed !== null;
        }, true)

        if (complete && completeCallback) {
            completeCallback();
        }
    }, [state, completeCallback])

    return <>
        <div className="task-103">
            <StandardInsert>
                {(state.unplaced.length > 0) && <div className="menu">
                    {state.unplaced.map(id => {
                        const item = state.items.find(i => i.id === id);

                        if (item) {
                            return <div className='menu-item' key={id}>
                                <PickUp data={{ type: 'item', id, data: item }}>
                                    <GameItem item={item} />
                                </PickUp>
                            </div>
                        }

                        return <></>
                    })}
                </div>}
            </StandardInsert>
            <div className="timeline">
                <div className="timeline-inner">
                    <div className="row-1">
                        <div className={`timeline-header ${timelineHeader}`}>

                        </div>
                        <div className="timeline-window-2" >
                            <div className="timeline-window" onScroll={onScroll} ref={timeWindowRef}>
                                <div className="real-timeline">
                                    <div className="weeks" ref={weeksRef}>
                                        {weeksElements}
                                    </div>
                                    <div className="years" ref={yearsRef}>
                                        {yearsElements}
                                    </div>
                                    <div className="ages" ref={agesRef}>
                                        {agesElements}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row-2">
                        <div className="spacer">

                        </div>
                        <div className="timeline-controlls">
                            <input type="range" name="" id="" defaultValue={0} min={0} max={timelineWidth} step={1} onInput={onInput} onChange={onInput} ref={positionInputRef} />
                            <div className="marker" ref={markerRef}>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        {
            popup.show && <Popup onClick={() => { setPopup({ show: false }) }}>
                <img src={popup.img} alt="" />
                <p dangerouslySetInnerHTML={{ __html: popup.text || '' }}></p>
            </Popup>
        }
        </div>
        <ItemPreview />
    </>
}

const GameItem: React.FC<{ item: Item }> = ({ item }) => {
    return <img className="task-103-pick-up" src={item.src} alt="" />
}

const ItemPreview: React.FC<{}> = () => {
    return <Preview>{({ item, style }: any) => {
        return <div style={{ ...style, width: item.size.width }}>
            <GameItem item={item.data} />
        </div>
    }}</Preview>
}

export default Task103Widget;
