import React from "react";
import { Link } from "react-router-dom";
import Box from "../../layout/box";
import StandardInsert from "../../layout/standard-insert";
import { getMapRoute } from "../routes";
import './style.scss';

import avaiableSrc from '../../assets/images/map/avaiable.png';
import completedSrc from '../../assets/images/map/completed.png';
import hoverSrc from '../../assets/images/map/hover.png';
import taskSrc from '../../assets/images/map/task.png';
import Logos3 from "../../components/logos/logos-3";

export default function RulesPage() {
    return <div className="page rules-page">
        <Box className="rules-box">
            <StandardInsert>
                <h1>Zasady misji</h1>
                <ul>
                    <li>Na kolejnej stronie wpisz imię lub ksywkę/kryptonim na czas wykonywania misji.</li>
                    <li>Podczas misji możesz podążyć czterema ścieżkami, które są pokazane na mapie.</li>
                    <li>
                        Zadania do wykonania są oznaczone kolorowymi kropkami wg poniższego schematu:
                        <div className="legend">
                            <div className="column">
                                <img src={taskSrc} alt=""/>
                                <label htmlFor="">nieaktywne</label>
                            </div>
                            <div className="column">
                                <img src={hoverSrc} alt=""/>
                                <label htmlFor="">po najechaniu</label>
                            </div>
                            <div className="column">
                                <img src={avaiableSrc} alt=""/>
                                <label htmlFor="">Aktywne</label>
                            </div>
                            <div className="column">
                                <img src={completedSrc} alt=""/>
                                <label htmlFor="">Zakończone</label>
                            </div>
                        </div>

                    </li>
                    <li>Część zadań wymaga od Ciebie pracy manualnej i przetwarzania znalezionych odpadów.</li>
                    <li>Ukończenie zadania musisz potwierdzić klikając w przycisk "Zadanie wykonane".</li>
                    <li>Aby wykonać następne zadanie, najpierw musisz ukończyć to, które jest dla Ciebie dostępne.</li>
                    <li>Za ukończenie zadania otrzymasz nagrody.</li>
                    <li>Nagrody możesz wykorzystać do stworzenia własnej leśnej polany. By do niej przejść, kliknij w tabliczkę z napisem: „Projektuj Polanę”.</li>
                    <li>Za ukończenie każdej ścieżki w całości otrzymasz odznakę umiejętności.</li>
                    <li>Zdobyte odznaki będą widoczne w panelu „Projektuj Polanę”.</li>
                    <li>Zdobyte odznaki możesz wydrukować lub odrysować, wyciąć i dumnie nosić.</li>
                    <li>Aby wrócić później do opisu zasad, kliknij na mapie guzik z napisem: „Wróć do opisu zasad”.</li>
                </ul>
            </StandardInsert>
            <StandardInsert>
                <div className="buttons">

                    <Link className="button" to={getMapRoute}>Przejdź dalej</Link>
                </div>
            </StandardInsert>
        </Box>
        <Box className='bg-white no-padding'>
            <Logos3 />
        </Box>
    </div>
}
