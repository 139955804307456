import './style.scss';

const Box: React.FC<{} & React.HTMLAttributes<HTMLDivElement>> = ({children, ...args}) => {
    return <div {...args} className={`layout-box ${args.className}`} >
        <div className="inner">
            {children}
        </div>
    </div>
}

export default Box;
