import { useQuery } from "react-query";
import Network from "../../network";
import { Task } from "./use-tasks";

export default function useTask(id: string) {
    const {data, isLoading} = useQuery(['task', id], () => {
        return Network.getTask(id);
    })

    return {
        task: data as Task,
        isLoading
    }
}
