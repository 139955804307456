import './style.scss';
import ccrLogo from '../../assets/images/logos/ccr.jpg';
import e3000Logo from '../../assets/images/logos/electro-3000.svg';
import pknLogo from '../../assets/images/logos/kpn.png';

export default function Logos3() {
    return <footer className='logos3'>
        <div className="label">
            <h3>do gry zapraszają:</h3>
        </div>
        <div className="logo">
            <a href="https://www.kampinoski-pn.gov.pl/"><img src={pknLogo} alt="Kampinowski Park Narodowy" /></a>
        </div>
        <div className="logo ccr">
            <a href="https://www.ccr-revlog.pl"><img src={ccrLogo} alt="CCR Reverse Logistic Group" /></a>
        </div>
        <div className="text-info">
            <p>Zajrzyj też na <a href="https://elektro3000.pl/">www.elektro3000.pl</a> aby dowiedzieć się więcej o segregacji i recyklingu odpadów.</p>
            <a className="fb" href="//facebook.com/elektro3000">facebook.com/elektro3000</a>
        </div>
        <div className="logo electro">
            <a href="https://elektro3000.pl/"><img src={e3000Logo} alt="Elektro 3000" /></a>
        </div>
    </footer>
}
