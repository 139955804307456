export function getPoint(brng: number, dist: number, {lat, lng}: {lat: number, lng: number}) {
    dist = dist / 6371;
    brng = toRad(brng);

    var lat1 = toRad(lat), lon1 = toRad(lng);

    var lat2 = Math.asin(Math.sin(lat1) * Math.cos(dist) +
                         Math.cos(lat1) * Math.sin(dist) * Math.cos(brng));

    var lon2 = lon1 + Math.atan2(Math.sin(brng) * Math.sin(dist) *
                                 Math.cos(lat1),
                                 Math.cos(dist) - Math.sin(lat1) *
                                 Math.sin(lat2));

    if (isNaN(lat2) || isNaN(lon2)) return null;

    return {
        lat:toDeg(lat2),
        lng:toDeg(lon2)
    };
 }

function toRad(value: number) {
    return value * Math.PI / 180;
 }

function toDeg(value: number) {
    return value * 180 / Math.PI;
 }
