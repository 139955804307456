import { useCallback } from 'react';
import './style.scss';

type Props = {
    onComplete?: () => void
}

const PlaceholderWidget: React.FC<Props> = ({ onComplete: completeCallback }) => {

    const onComplete = useCallback(() => {
        if (completeCallback) {
            completeCallback();
        }
    }, [completeCallback]);

    return <div className="task-204">
        <button className="button" onClick={onComplete}>Zrób zadanie</button>
    </div>
}

export default PlaceholderWidget;
