
import StandardInsert from '../../../../layout/standard-insert';
import './style.scss';

//@ts-ignore
import { Preview } from 'react-dnd-preview'
import PickUp from '../../../drag-drop-template/pick-up';
import DropZone from '../../../drag-drop-template/drop-zone';
import { useCallback, useEffect, useReducer, useState } from 'react';
import Sticky from 'react-sticky-el';
import React from 'react';
import Popup from '../../../popup';

type Props = {
    onComplete?: () => void
}

type Item = {
    id: string,
    src: string,
    correct: string,
    message: string
}

type Bin = {
    id: string,
    src: string
    type: 'normal' | 'wide'
}

type GameState = {
    items: Item[],
    bins: Bin[],
}

const Task303Widget: React.FC<Props> = ({ onComplete }) => {
    const [popup, setPopup] = useState<{
        show: boolean,
        text?: string,
        img?: string
    }>({ show: false })

    const [state, moveItem] = useReducer((state: GameState, action: { itemId: string }) => {
        return {
            ...state,
            items: state.items.filter(i => i.id !== action.itemId)
        }
    }, {
        items: [
            {
                id: 'i1',
                src: '/assets/tasks/task-303/item-5.png',
                correct: 'zmieszane',
                message: 'Kości oraz inne odpady pochodzenia zwierzęcego powinny być wyrzucone do pojemnika na zmieszane.'
            },
            {
                id: 'i2',
                src: '/assets/tasks/task-303/item-6.png',
                correct: 'zmieszane',
                message: 'Papier termiczny, z którego jest wykonany paragon nie nadaje się do recyklingu. Wyrzuć go do pojemnika na odpady zmieszane.'
            },
            {
                id: 'i3',
                src: '/assets/tasks/task-303/item-3.png',
                correct: 'pszok',
                message: 'Gdy na plastikowej zabawce widnieje symbol przekreślonego kontenera, trzeba oddać ją do punktu zbiórki elektrośmieci, np. do PSZOKu (Punkt Selektywnej Zbiórki Odpadów Komunalnych).'
            },
            {
                id: 'i4',
                src: '/assets/tasks/task-303/item-1.png',
                correct: 'baterie',
                message: 'Baterie są niebezpieczne dla środowiska i naszego zdrowia, ze względu na to, że zawierają w sobie metale ciężkie i substancje niebezpieczne, takie jak ołów, kadm, rtęć czy nikiel. Właśnie dlatego musimy wyrzucać je do specjalnych pojemników lub punktów zbiórki baterii.'
            },
            {
                id: 'i5',
                src: '/assets/tasks/task-303/item-7.png',
                correct: 'zmieszane',
                message: 'Szklanka, lustro, szyba wykonane są ze szkła o innym składzie chemicznym i nie powinny zostać wyrzucone do zielonego pojemnika. Wrzuć je do pojemnika na zmieszane. Do pojemnika na szkło wrzucamy tylko opakowania, czyli szklane butelki i słoiki – bez nakrętek.'
            },
            {
                id: 'i6',
                src: '/assets/tasks/task-303/item-2.png',
                correct: 'pszok',
                message: 'Świetlówki zawierają w sobie między innymi rtęć, dlatego starą świetlówkę należy oddać do punktu zbiórki tego rodzaju odpadów np. do PSZOKu. Można ją również oddać w sklepie, przy zakupie nowej.'
            },
            {
                id: 'i7',
                src: '/assets/tasks/task-303/item-4.png',
                correct: 'tworzywa',
                message: 'Kartony po soku, tak jak i po mleku, należą do odpadów wielomateriałowych. W ich wypadku papier jest powleczony woskiem lub plastikiem oraz aluminium. Opakowania te nie mogą zostać poddane recyklingowi razem z makulaturą, a pozostałe w nich resztki napojów stanowią zagrożenie zabrudzenia papieru, dlatego wyrzucamy je do żółtego pojemnika na metale i tworzywa sztuczne.'
            }
        ],
        bins: [
            {
                id: 'tworzywa',
                src: '/assets/tasks/task-303/bin1.png',
                type: 'normal'
            },
            {
                id: 'szklo',
                src: '/assets/tasks/task-303/bin2.png',
                type: 'normal'
            },
            {
                id: 'bio',
                src: '/assets/tasks/task-303/bin3.png',
                type: 'normal'
            },
            {
                id: 'zmieszane',
                src: '/assets/tasks/task-303/bin4.png',
                type: 'normal'
            },
            {
                id: 'papier',
                src: '/assets/tasks/task-303/bin5.png',
                type: 'normal'
            },
            {
                id: 'pszok',
                src: '/assets/tasks/task-303/bin6.png',
                type: 'wide'
            },
            {
                id: 'baterie',
                src: '/assets/tasks/task-303/bin7.png',
                type: 'normal'
            }
        ]
    });

    const onDrop = useCallback((pickUp: string, dropId: string) => {
        const item = state.items.find(i => i.id === pickUp);

        if (item) {
            if (item.correct === dropId) {
                moveItem({ itemId: pickUp });
            } else {
                setPopup({
                    show: true,
                    text: item.message,
                    img: item.src
                })
            }
        }
    }, [state, moveItem, setPopup]);

    useEffect(() => {
        if (state.items.length === 0 && onComplete) {
            onComplete();
        }
    }, [state, onComplete])

    return <>

        <div className="task-303">
            <StandardInsert>
                {(state.items.length > 0) && <Sticky>
                    <div className="pickup-zone">
                        {
                            state.items
                                .slice(0, 5)
                                .map(item => {
                                    return <div className="item-container" key={item.id}>
                                        <div className="item-container-inner">
                                            <PickUp data={{ type: 'item', id: item.id, data: item }}>
                                                <GameItem item={item} />
                                            </PickUp>
                                        </div>

                                    </div>
                                })
                        }
                    </div>
                </Sticky>
                }
                <div className="slots">
                    {
                        state.bins.map(bin => {
                            return <div className={`bin ${bin.type}`} key={bin.id}>
                                <DropZone targetType="item" id={bin.id} onDrop={onDrop}>
                                    <div className="bin-container">
                                        <img src={bin.src} alt="" />
                                    </div>
                                </DropZone>
                            </div>
                        })
                    }
                </div>
            </StandardInsert>
            {
                popup.show && <Popup onClick={() => { setPopup({ show: false }) }}>
                    <img src={popup.img} alt="" />
                    <p dangerouslySetInnerHTML={{ __html: popup.text || '' }}></p>
                </Popup>
            }
        </div>
        <ItemPreview />
    </>
}

const GameItem: React.FC<{ item: Item }> = ({ item }) => {
    return <img className="task-103-pick-up" src={item.src} alt="" />
}

const ItemPreview: React.FC<{}> = () => {
    return <Preview>{({ item, style }: any) => {
        return <div style={{ ...style, width: item.size.width }}>
            <GameItem item={item.data} />
        </div>
    }}</Preview>
}

export default Task303Widget;
