import Axios from 'axios';
import { User } from '../user/types';

/**
 * Axios instance to comunicate with local PHP backend
 */
const APIClient = Axios.create({
    baseURL: ['localhost', '192.168.1.145'].includes(window.location.hostname) ? 'https://kampinos.borek224.usermd.net/api/' : '/api/',
    withCredentials: true
})

const URLS = {
    GET_CONFIG: '/config',
    REGISTER_USER: '/register',
    LOGIN: '/login',
    TASKS: '/tasks',
    TASK: '/task',
    COMPLETE_TASK: '/complete-task'
}

type GetConfigResponse = {
    serverTime: number,
    user: User
}

function getConfig(): Promise<GetConfigResponse> {
    return APIClient.get(URLS.GET_CONFIG)
        .then((response) => {
            return {
                serverTime: response.data.time,
                user: response.data.user
            }
        });
}

function register(login: string, password: string, password2: string): Promise<any> {
    return APIClient.post(URLS.REGISTER_USER, {
        login, password, password2
    });
}

function login(login: string, password: string): Promise<any> {
    return APIClient.post(URLS.LOGIN, {
        login, password
    });
}

function getTasks() {
    return APIClient.get(URLS.TASKS)
        .then(response => response.data);
}

function getTask(id: string) {
    return APIClient.get(URLS.TASK, {
        params: {
            id
        }
    })
        .then(response => response.data);
}

function completeTask(id: string) {
    return APIClient.post(URLS.COMPLETE_TASK, {
        id
    });
}


const Network = {
    getConfig,
    register,
    login,
    getTasks,
    getTask,
    completeTask
}

export default Network;
