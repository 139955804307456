import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import useTasks from "../../modules/tasks/hooks/use-tasks";
import useUser from "../../modules/user/hooks/use-user";
import { getGeneratorRoute, getRulesRoute, getTaskRoute } from "../../routes/routes";
import './style.scss';

type Props = {}

const GameMap: React.FC<Props> = () => {

    const { tasks } = useTasks();
    const { user } = useUser();

    const data = useMemo(() => {
        if (tasks && user) {
            return tasks.map(task => {
                return {
                    task,
                    completed: user?.completedTasks.includes(task.id),
                    avaiable: task.requires.reduce((prev: boolean, next: string) => {

                        return prev && user.completedTasks.includes(next);

                    }, true)
                }
            })
        }
        return [];
    }, [user, tasks])


    return <>
        {
            data.map(d => {
                if (d.avaiable) {
                    return <Link key={d.task.id} className={`task-link task-link-${d.task.id} ${d.completed ? 'completed' : 'avaiable'}`} style={{ fontWeight: 'bold' }} to={getTaskRoute(d.task.id)}></Link>
                } else {
                    return <span key={d.task.id} className={`task-link task-link-${d.task.id} ${d.completed ? 'completed' : ''}`} ></span>
                }
            })
        }
        <div className="generator-link">
            <Link className='button' to={getGeneratorRoute()}>Projektuj polanę</Link>
        </div>
        <div className="rules-link">
            <Link className='button' to={getRulesRoute()}>Wróć do opisu zasad</Link>
        </div>
    </>
}

export default GameMap;
