import React from "react";
import { Link } from "react-router-dom";
import Logos3 from "../../components/logos/logos-3";
import Box from "../../layout/box";
import StandardInsert from "../../layout/standard-insert";
import { getGeneratorRoute, getMapRoute } from "../routes";
import './style.scss';

export default function EndGamePage() {
    return <div className='page end-game-page'>
        <Box className="end-game-box">
            <StandardInsert>
                <div className="content">
                    <h1>Gratulacje, gra ukończona!</h1>
                    <p>
                        Brawo! Udało Ci się ukończyć grę - to nie lada wyczyn! Teraz wiesz już jakich zasad należy przestrzegać, aby chronić przyrodę. Pamiętaj, by stosować się do nich na co dzień!
                </p>
                    <p>
                        Jeśli chcesz, możesz powrócić do projektowania polany lub do mapy z zadaniami.
                </p>

                </div>
                <div className="buttons">
                    <Link to={getGeneratorRoute()} className="button">Projektuj polanę</Link>
                    <Link to={getMapRoute()} className="button">Wróć do mapy</Link>
                </div>
            </StandardInsert>
        </Box>

        <Box className='bg-white no-padding end-game-footer'>
            <Logos3 />
        </Box>
    </div>
}
