import React from "react";
import { useHistory } from "react-router";
import Box from "../../layout/box";
import StandardInsert from "../../layout/standard-insert";
import './style.scss';

export default function PrivacePage() {

    const history = useHistory();

    return <div className='page privace'>
        <Box>
            <StandardInsert>


                <p>CCR Polska Sp. z o.o. z siedzibą w Warszawie przy ulicy Jana Pawła II 27 , jako właściciel i Administrator serwisu: http://www.niezasmiecamprzyrody.pl/ (dalej: Serwis) przedstawia poniżej informacje dotyczące zastosowania i wykorzystania w Serwisie plików cookie.</p>
                <p>W Serwisie administrowanym przez CCR Polska Sp. z o.o. wykorzystywane są następujące rodzaje plików cookies:</p>
                <ul>
                    <li>pliki typu nessesary - niezbędne do prawidłowego funkcjonowania strony</li>
                    <li>pliki typu statistics - służące do zbierania statystyk odwiedzin i aktywności za pomocą narzędzia Google Analytics, dane te wykorzystujemy również do optymalizacji i rozwoju Serwisów.</li>
                    <li>pliki zbierające dane o lokalizacji urządzenia - wykorzystywane w grze mobilnej</li>

                </ul>
                    <p>Nasze pliki cookies to:</p>
                <ul>

                    <li>pliki sesyjne – wygasają po zakończeniu sesji np. po wylogowaniu się ze strony internetowej, wg. parametrów określonych w Twojej przeglądarce internetowej oraz</li>
                    <li>pliki trwałe - nie są kasowane w momencie zamknięcia okna przeglądarki;, wykorzystywane są, aby pomóc nam wspierać komfort korzystania z naszych Serwisów (np. zmiana wyglądu lub wersji stron).</li>
                </ul>

                <p>Wszystkie serwisy zewnętrzne połączone z serwisami Administratora  takie jak  np. Facebook (https://pl-pl.facebook.com/policies/cookies/ lub Google Inc. (https://policies.google.com/technologies/cookies)  mają własną politykę cookies. Serwisy zewnętrzne zapewniające usługi analityczne (np. Google Inc.) mogą dodać dane zebrane podczas Twojej wizyty do zbioru danych pozyskanych na Twój temat z innych źródeł.</p>
                <p>W każdej chwili możesz edytować ustawienia prywatności na Twoim urządzeniu w ramach ustawień swojej przeglądarki m.in. w zakresie instalowania plików cookies. W każdej chwili możesz też usunąć z pamięci swojego urządzenia pliki cookies zapisane w trakcie przeglądania naszych Serwisów. Ograniczenia w stosowaniu plików cookies mogą utrudnić lub uniemożliwić komfort korzystania z naszych Serwisów.</p>
                <p>
                    Instalowanie plików cookies lub uzyskiwanie do nich dostępu nie powoduje zmian w Twoim urządzeniu ani w oprogramowaniu zainstalowanym na tym urządzeniu.
                </p>

                <div className="buttons">
                    <button className="button" onClick={() => {
                        history.goBack();
                    }}>Dalej</button>
                </div>

            </StandardInsert>
        </Box>
    </div>
}
