import TaskWidget from "./widget";

export type WidgetConfig = {
    container: any,
    onComplete: () => void
}

const BASE_WIDTH = 1315;
const BASE_HEIGHT = 986;

export default function createWidget(config: WidgetConfig) {

    const app = new TaskWidget(BASE_WIDTH, BASE_HEIGHT, config);

    config.container.appendChild(app.view);

    return {
        destroy: () => app.destroy()
    }
}
