
import React, { FormEvent, useCallback, useEffect, useReducer, useRef, useState } from 'react';
import StandardInsert from '../../../../layout/standard-insert';
import Popup from '../../../popup';
import './style.scss';


//@ts-ignore
import { Preview } from 'react-dnd-preview'
import PickUp from '../../../drag-drop-template/pick-up';
import DropZone from '../../../drag-drop-template/drop-zone';

type Props = {
    onComplete?: () => void
}

type Item = {
    id: string,
    src: string,
    correct?: boolean,
    message: string
}

type Slot = {
    id: string,
    correct: string,
    itemId: string | null
}

type GameState = {
    items: Item[],
    unselected: string[],
    slots: Slot[]
}

const EMPTY = '/assets/tasks/task-302/empty.svg'

const Task302Widget: React.FC<Props> = ({onComplete}) => {
    const [popup, setPopup] = useState<{ show: boolean, text?: string }>({ show: false })

    const [state, moveItem] = useReducer((state: GameState, action: { itemId: string, slotId: string }) => {
        return {
            ...state,
            unselected: state.unselected.filter(i => i !== action.itemId),
            slots: state.slots.map((s) => {
                if (s.id === action.slotId) {
                    return {
                        ...s,
                        itemId: action.itemId
                    }
                }

                return { ...s }
            })
        }
    }, {
        items: [
            {
                id: 'i1',
                src: '/assets/tasks/task-302/tile-5.png',
                message: 'Super! Butelka plastikowa rozkłada się od 100 do 1000 lat, dlatego nie wolno porzucać jej na łonie natury, a należy wrzucić do żółtego pojemnika, aby trafiła do recyklingu.',
                correct: true
            },
            {
                id: 'i2',
                src: '/assets/tasks/task-302/tile-4.png',
                message: 'Dobra robota! Teraz będziesz mógł przejść do kolejnego etapu.',
                correct: true
            },
            {
                id: 'i3',
                src: '/assets/tasks/task-302/tile-3.png',
                message: 'Masz rację, należy dobrze opróżnić opakowanie. Pamiętaj, aby nie marnować produktów i wykorzystywać je do końca.',
                correct: true
            },
            {
                id: 'i4',
                src: '/assets/tasks/task-302/tile-2.png',
                message: 'Tak! Jeżeli  zgnieciesz butelki w koszu zmieści się ich więcej. Dzięki temu śmieciarki będą transportować plastik, a nie zamknięte w butelkach powietrze!',
                correct: true
            },
            {
                id: 'i5',
                src: '/assets/tasks/task-302/tile-8.png',
                message: 'Doskonale, to ostatni bardzo ważny krok! Butelki z żółtego pojemnika i worka trafiają do recyklingu, w ramach którego są  przetwarzane na nowe produkty. Czy wiesz, że butelki po napojach są wykorzystywane np. do produkcji bluz polarowych?',
                correct: true
            },
            {
                id: 'i6',
                src: '/assets/tasks/task-302/tile-7.png',
                message: 'UPS. Wkradł się błąd. Przed wyrzuceniem nie należy ciąć czy rozdrabniać butelek.',
            },
            {
                id: 'i7',
                src: '/assets/tasks/task-302/tile-1.png',
                message: 'To może być dla Ciebie zaskoczenie, ale opakowań po produktach nie trzeba myć przed wyrzuceniem. Wystarczy je dokładnie opróżnić. Szkoda marnować wody, gdyż proces recyklingu uwzględnia również czyszczenie surowców wtórnych.',
            },
            {
                id: 'i8',
                src: '/assets/tasks/task-302/tile-6.png',
                message: 'Zdecydowanie NIE! Podgrzewanie czy palenie plastiku, a więc jego topienie w warunkach domowych, jest szkodliwe i niebezpieczne!',
            }
        ],
        slots: [
            {
                id: '1',
                correct: 'i1',
                itemId: null
            },
            {
                id: '2',
                correct: 'i2',
                itemId: null
            },
            {
                id: '3',
                correct: 'i3',
                itemId: null
            },
            {
                id: '4',
                correct: 'i4',
                itemId: null
            },
            {
                id: '5',
                correct: 'i5',
                itemId: null
            }
        ],
        unselected: ['i1', 'i2', 'i3', 'i4', 'i5', 'i6', 'i7', 'i8'].sort(() => Math.random() - .5)
    })

    const markerRef = useRef<HTMLDivElement>(null);
    const [timelineWidth, setTimelineWidht] = useState(100);
    const positionInputRef = useRef<HTMLInputElement>(null);
    const timeWindowRef = useRef<HTMLDivElement>(null);
    const pickupListRef = useRef<HTMLDivElement>(null);

    const onInputValueSet = useCallback((value: number) => {
        if (timeWindowRef.current) {
            if (timeWindowRef.current.scrollTo) {
                timeWindowRef.current.scrollTo({
                    left: value
                })
            } else {
                timeWindowRef.current.scrollLeft = value;
            }
        }

        if (markerRef.current && positionInputRef.current) {

            try {
                const markerPosition = value / parseInt(positionInputRef.current.getAttribute('max') || '');
                markerRef.current.style.left = `${100 * markerPosition}%`;
                markerRef.current.style.marginLeft = `${-markerPosition * markerRef.current.offsetWidth}px`;

            } catch (e) { }

        }
    }, [timeWindowRef, positionInputRef])

    const onInput = useCallback((e: FormEvent<HTMLInputElement>) => {
        onInputValueSet(parseInt(e.currentTarget.value));
    }, [onInputValueSet]);

    const onScroll = useCallback((e: React.UIEvent) => {

        if (timeWindowRef.current) {
            const left = timeWindowRef.current.scrollLeft

            if (positionInputRef.current) {
                if (parseInt(positionInputRef.current.value) !== left) {
                    //@ts-ignore
                    onInputValueSet(left);
                }
            }
        }


    }, [timeWindowRef, positionInputRef, onInputValueSet])

    const onDrop = useCallback((pickupId: string, dropId: string) => {
        const item = state.items.find(i => i.id === pickupId);
        const slot = state.slots.find(s => s.id === dropId);

        if (item && slot) {

            if (!item.correct) {
                setPopup({
                    show: true,
                    text: item.message
                });
                return;
            }

            if (item.id === slot.correct) {
                moveItem({
                    itemId: item.id,
                    slotId: slot.id
                });
                setPopup({
                    show: true,
                    text: item.message
                });
            } else {
                setPopup({
                    show: true,
                    text: 'Umieść czynności robota we właściwej kolejności!'
                })
            }

        }

    }, [setPopup, moveItem, state]);

    useEffect(() => {
        const complete = state.slots.reduce((prev: boolean, next: Slot) => {
            return prev && next.itemId !== null
        }, true)

        if (complete && onComplete) {
            onComplete();
        }
    }, [state, onComplete])

    const updateTimelineWidht = useCallback(() => {
        if (pickupListRef.current && timeWindowRef.current) {
            setTimelineWidht((pickupListRef.current.offsetWidth / 5) * (state.unselected.length - 5))
        }
    }, [setTimelineWidht, pickupListRef, state]);

    useEffect(() => {
        setTimeout(() => {
            updateTimelineWidht();
        }, 100);
        window.addEventListener('resize', updateTimelineWidht);

        return () => {
            window.removeEventListener('resize', updateTimelineWidht);
        }
    }, [updateTimelineWidht]);

    useEffect(() => {
        if (timeWindowRef.current) {
            const w = timeWindowRef.current;
            w.style.bottom = w.clientHeight - w.offsetHeight + "px";
        }
    }, [timeWindowRef])

    return <div className="task-302">
        <StandardInsert>
            <>

                <div className="items-menu">
                    <div className="pickup-window-2" >
                        <div className="pickup-window" onScroll={onScroll} ref={timeWindowRef}>
                            <div className="pickup-list" ref={pickupListRef}>
                                {
                                    state.unselected.map((id) => {
                                        const item = state.items.find(i => i.id === id);

                                        if (item) {
                                            return <div className="pickup-container" key={id}>
                                                <div className="pickup-container-inner">
                                                    <PickUp data={{ type: 'item', id: item.id, data: item }}>
                                                        <GameItem item={item} />
                                                    </PickUp>
                                                </div>
                                            </div>
                                        }

                                        return <></>
                                    })
                                }
                                {
                                    (state.unselected.length < 5) && Array(5 - state.unselected.length)
                                        .fill(null)
                                        .map((x, index) => {
                                            return <div className="pickup-container" key={index}>
                                                <div className="pickup-container-inner">
                                                    <img className="task-302-pick-up" src={EMPTY} alt="" />
                                                </div>
                                            </div>
                                        })
                                }
                            </div>
                        </div>
                    </div>
                    <div className="controlls">
                        {(state.unselected.length > 5) && <>
                            <input type="range" name="" id="" defaultValue={0} min={0} max={timelineWidth} step={1} onInput={onInput} onChange={onInput} ref={positionInputRef} />
                            <div className="marker" ref={markerRef}>
                            </div>
                        </>}
                    </div>
                </div>

                <div className="slots">
                    <div className="slot-list">
                        {
                            state.slots.map((s) => {

                                const item = state.items.find(i => i.id === s.itemId);

                                return <div className="drop-container" key={s.id}>
                                    <DropZone id={s.id} targetType="item" onDrop={onDrop}>
                                        <div className="drop-container-inner">
                                            {item && <GameItem item={item} />}
                                            {!item && <img className="task-302-pick-up" src={EMPTY} alt="" />}
                                        </div>
                                    </DropZone>
                                </div>
                            })
                        }
                    </div>
                </div>

                <ItemPreview />
            </>
        </StandardInsert>
        {
            popup.show && <Popup onClick={() => { setPopup({ show: false }) }}>
                <p dangerouslySetInnerHTML={{ __html: popup.text || '' }}></p>
            </Popup>
        }
    </div>
}

const GameItem: React.FC<{ item: Item }> = ({ item }) => {
    return <img className="task-302-pick-up" src={item.src} alt="" />
}

const ItemPreview: React.FC<{}> = () => {
    return <Preview>{({ item, style }: any) => {
        return <div style={{ ...style, width: item.size.width }}>
            <GameItem item={item.data} />
        </div>
    }}</Preview>
}

export default Task302Widget;
