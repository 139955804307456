
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import './style.scss';
import crossword from './data';
import StandardInsert from '../../../../layout/standard-insert';
import useChainInput, { ChainInfo } from './use-chain-input';

type Props = {
    onComplete?: () => void
}

export type CrosswordField = {
    type: 'label' | 'input',
    position: {
        x: number,
        y: number
    },
    chain?: ChainInfo[],
    extraClass?: string,
    text?: string,
    correct?: string
}

const Task405Widget: React.FC<Props> = ({ onComplete: completeCallback }) => {

    const containerRef = useRef<HTMLDivElement>(null)
    const [containerWidth, setContainerWidth] = useState(0);
    const crosswordColumnCount = 19;
    const crosswordRowCount = 13;
    const cellSize = useMemo(() => {
        return containerWidth / crosswordColumnCount;
    }, [containerWidth])

    const { registerContainer, registerInput } = useChainInput();

    const onResize = useCallback(() => {
        if (containerRef.current) {
            setContainerWidth(containerRef.current.getBoundingClientRect().width);
        }
    }, [setContainerWidth, containerRef]);

    const validate = useCallback(() => {
        if (containerRef.current) {
            const inputs = containerRef.current.querySelectorAll('input');

            const correct = Array.from(inputs)
                .reduce((prev, next) => {
                    return prev && next.dataset['correct'] === next.value.toLowerCase();
                }, true)

            if (correct && completeCallback) {
                completeCallback()
            }

        }
    }, [containerRef, completeCallback])


    useEffect(() => {
        onResize();
        window.addEventListener('resize', onResize);

        return () => {
            window.removeEventListener('resize', onResize);
        }
    }, [onResize]);

    const [showCorrect, setShowCorrect] = useState(false);

    return <div className="task-405">
        <StandardInsert>
            <div ref={registerContainer}>
                <div className="cw-container" ref={containerRef} style={{ height: `${cellSize * crosswordRowCount}px` }}>
                    {
                        crossword.map((field, index) => {

                            return <Cell
                                cellSize={cellSize}
                                field={field}
                                inputRef={field.chain ? registerInput(field?.chain) : null}
                                onChange={validate}
                                showCorrect={showCorrect}
                            />
                        })
                    }
                </div>

                <div className="info">
                    <div className="questions">
                        <p>1. Zarówno szklana, jak i  plastikowa nadaje się do recyklingu</p>
                        <p>2. Materiał, z którego wykonane są puszki na napoje. Z odzyskanego , w nieskończoność można produkować nowe pełnowartosciowe produkty</p>
                        <p>3. Samochód transportujący odpady z pojemników do sortoni odpadów</p>
                        <p>4. Np. szkła lub stali. W niej odbywa się recykling</p>
                        <p>5. Miejsce, gdzie następuje bardziej szczegółowa segregacja odpadów,  aby możliwe było lepsze wykorzystanie surowcow wtórnych w procesie recyklingu</p>
                        <p>6. To tworzenie nowych przedmiotów, o nowych funkcjach ze starych, zużytych materiałów/rzeczy np. szycie toreb ze starych materiałów czy tworzenie mebli z drewnianych palet</p>
                        <p>7. Papier przeznaczony do recyklingu, który wrzucamy do niebieskiego pojemnika</p>
                        <p>8. Z około 35 plastikowych butelek po napojach, poddanych recyklingowi może powstać bluza …</p>
                    </div>
                    <div className="buttons">
                        <button className="button" onClick={() => setShowCorrect(true)}>Sprawdź</button>
                        <a className="button" href="/assets/tasks/task-405/krzyzowka.pdf" download="krzyzowka.pdf">Pobierz PDF</a>
                    </div>
                </div>

            </div>

        </StandardInsert>
    </div>
}

type CellProps = {
    cellSize: number,
    field: CrosswordField,
    inputRef?: any
    onChange: () => void,
    showCorrect: boolean
}

const Cell: React.FC<CellProps> = ({ cellSize, field, inputRef, onChange, showCorrect }) => {
    const borderWidth = 3;

    const [correct, setCorrect] = useState(false);
    const onValueChange = useCallback((e: React.FormEvent<HTMLInputElement>) => {
        setCorrect((e.currentTarget.value[e.currentTarget.value.length-1] || '').toLowerCase() === field.correct);

        if (onChange) {
            onChange();
        }
    }, [setCorrect, field, onChange]);


    return <div className={`cw-cell cell-${field.type} ${field.extraClass || ''} ${showCorrect ? (correct ? 'correct' : 'wrong') : ''}`} style={{
        transform: `translate(${(cellSize) * field.position.x}px, ${(cellSize) * field.position.y}px)`,
        width: `${cellSize - borderWidth}px`,
        height: `${cellSize - borderWidth}px`,
        borderWidth: `${borderWidth}px`
    }}>
        {(field.type === 'label') && <span style={{ fontSize: `${cellSize * .75}px` }}>{field.text}</span>}
        {(field.type === 'input') && field.chain && <input
            type="text"
            data-correct={field.correct}
            ref={inputRef}
            style={{ fontSize: `${cellSize * .75}px` }}
            onFocus={(e: React.FormEvent<HTMLInputElement>) => {
                const target = e.currentTarget;
                setTimeout(() => {
                    target.setSelectionRange(1, 1);
                }, 50)
            }}
            onInput={onValueChange}
        />}
    </div>
}

export default Task405Widget;
