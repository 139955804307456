
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import StandardInsert from '../../../../layout/standard-insert';
import './style.scss';

type Props = {
    onComplete?: () => void
}

type Item = {
    text: string,
    correct?: boolean
}

const Task205Widget: React.FC<Props> = ({ onComplete: completeCallback }) => {

    const { register, handleSubmit, formState } = useForm()

    const data: Item[] = useMemo(() => {
        return [
            {
                text: 'Wędrując po lesie chodzę tylko po wyznaczonych ścieżkach',
                correct: true
            },
            {
                text: 'Podczas leśnych spacerów głośno śpiewam, krzyczę i gwiżdżę - w końcu tu nie przeszkadzam sąsiadom. '
            },
            {
                text: 'Resztki prowiantu zostawiam w lesie - na pewno jakieś zwierzę z przyjemnością je doje'
            },
            {
                text: 'Zabieram ze sobą wszystkie śmieci, które wytworzyłem podczas spaceru, a nawet cudze, które znalazłem po drodze',
                correct: true
            },
            {
                text: 'Nie zwracam uwagi na tabliczki i znaki umieszczone w lesie - w końcu nie obowiązują tu przepisy ruchu drogowego'
            },
            {
                text: 'Zauważając “mieszkanie” jakiegoś zwierzęcia np.gniazdo ptaka, norę lub mrowisko, nie podchodzę blisko i nie ruszam go, zapewniając zwierzętom spokój.',
                correct: true
            },
            {
                text: 'Spacerując z psem nie trzymam go na smyczy - wreszcie może mieć trochę swobody!'
            }
        ]
    }, []);


    const [watch, setWatch] = useState(false);

    const onSubmit = useCallback(() => {
        setWatch(true);
    }, [setWatch]);

    useEffect(() => {
        if (watch && formState.isValid) {
            if (completeCallback) {
                completeCallback();
            }
        }
    }, [watch, formState, completeCallback])

    return <div className="task-205">
        <StandardInsert>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="item-list">
                    {
                        data.map((item, index) => {
                            const name = `index-${index}`;


                            return <label className={`item ${watch ? (formState.errors[name] ? 'error' : 'correct') : ''}`} key={index}>
                                <input type="checkbox" name={name} ref={register({ validate: value => value === Boolean(item.correct) })} />
                                <span></span>
                                <p>
                                    {item.text}
                                </p>
                            </label>
                        })
                    }
                </div>

                <button className="button" type="submit" onClick={() => setWatch(true)}>Sprawdź</button>
            </form>
        </StandardInsert>

    </div>
}

export default Task205Widget;
