
import React, { useEffect, useReducer } from 'react';
import { useCallback } from 'react';
import './style.scss';
import Sticky from 'react-sticky-el';
import PickUp from '../../../drag-drop-template/pick-up';
import DropZone from '../../../drag-drop-template/drop-zone';

//@ts-ignore
import { Preview } from 'react-dnd-preview'
import StandardInsert from '../../../../layout/standard-insert';

type Props = {
    onComplete?: () => void
}

type TrashBin = {
    id: string,
    src: string
}

type Spot = {
    id: string,
    correct: string,
    title: string,
    description: string,
    trashBin?: string
}

type GameState = {
    emptySrc: string,
    bins: TrashBin[],
    unselected: string[],
    spots: Spot[]
}

const Task301Widget: React.FC<Props> = ({ onComplete: completeCallback }) => {

    const [state, moveBin] = useReducer((state: GameState, action: { binId: string, spotId: string }) => {
        const newState = {
            ...state,
            unselected: state.unselected.filter(b => b !== action.binId),
            spots: state.spots.map(s => {
                if (s.id !== action.spotId) return s;

                return {
                    ...s,
                    trashBin: action.binId
                }
            })
        }

        return newState;

    }, {
        emptySrc: '/assets/tasks/task-301/empty.svg',
        bins: [
            {
                id: 'bin1',
                src: '/assets/tasks/task-301/bin1.png'
            },
            {
                id: 'bin2',
                src: '/assets/tasks/task-301/bin2.png'
            },

            {
                id: 'bin3',
                src: '/assets/tasks/task-301/bin3.png'
            },

            {
                id: 'bin4',
                src: '/assets/tasks/task-301/bin4.png'
            },

            {
                id: 'bin5',
                src: '/assets/tasks/task-301/bin5.png'
            }
        ],
        unselected: ['bin1', 'bin2', 'bin3', 'bin4', 'bin5'].sort(() => (Math.random() - .5)),
        spots: [
            {
                id: 's1',
                correct: 'bin1',
                title: 'METALE I TWORZYWA SZTUCZNE',
                description: 'Do tego pojemnika wyrzucaj zgniecione plastikowe butelki, kubeczki po jogurtach, plastikowe opakowania po kosmetykach, foliówki, kartony po mleku i sokach, puszki po konserwach i napojach, nakrętki i kapsle',
            },
            {
                id: 's2',
                correct: 'bin5',
                title: 'PAPIER',
                description: 'Do tego pojemnika wyrzucaj opakowania z papieru, karton, tekturę, gazety i czasopisma, zeszyty lub książki, torby papierowe',
            },
            {
                id: 's3',
                correct: 'bin2',
                title: 'SZKŁO',
                description: 'Do tego pojemnika wyrzucaj butelki i słoiki po napojach i żywności, szklane opakowania po kosmetykach',
            },
            {
                id: 's4',
                correct: 'bin3',
                title: '“BIO”',
                description: 'Do tego pojemnika wyrzucaj odpady zielone oraz kuchenne pochodzenia roślinnego, nadające się do kompostowania: obierki warzyw i owoców, gałęzie drzew i krzewów, skoszoną trawę oraz skorupki jajek.',
            },
            {
                id: 's5',
                correct: 'bin4',
                title: 'ODPADY ZMIESZANE',
                description: 'Do tego pojemnika wyrzucaj wszystko to, czego nie można odzyskać w procesie recyklingu. Pamiętaj, nie wrzucaj tu odpadów niebezpiecznych np. baterii i elektrośmieci, które możesz oddać do specjalnych pojemników lub do Punktu Selektywnej Zbiórki Odpadów Komunalnych (PSZOK). Jego adres znajdziesz na stronie gminy.',
            }
        ]
    })

    const onDrop = useCallback((pickupData: string, dropId: string) => {
        const spot = state.spots.find(s => s.id === dropId);

        if (spot && spot.correct === pickupData) {
            moveBin({ binId: pickupData, spotId: dropId });
        }
    }, [state]);

    useEffect(() => {
        const complete = state.spots.reduce((prev, next) => {
            return prev && next.correct === next.trashBin;
        }, true)

        if (complete) {
            if (completeCallback) {
                completeCallback();
            }
        }
    }, [state, completeCallback]);


    return <div className="task-301">
        <>
            {(state.unselected.length > 0) &&
                <Sticky>
                    <StandardInsert>
                        <div className="pickup-zone">
                            {
                                state.unselected.map((tId) => {
                                    const bin = state.bins.find(b => b.id === tId);

                                    if (!bin) return <></>;

                                    return <div className="pick-up-container" style={{ flexBasis: `${100 / state.bins.length}%` }} key={bin.id}>
                                        <div className="pick-up-container-inner">
                                            <PickUp data={{ type: 'bin', id: tId, data: bin }} key={tId}>
                                                <GameItem bin={bin} />
                                            </PickUp>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </StandardInsert>
                </Sticky>
            }
            <StandardInsert>

                {
                    state.spots.map((s) => {

                        const bin = state.bins.find(b => b.id === s.trashBin);

                        return <div className="game-row" key={s.id}>
                            <DropZone targetType='bin' onDrop={onDrop} id={s.id}>
                                <img className="task-301-pick-up" src={bin ? bin.src : state.emptySrc} alt="" />
                            </DropZone>
                            <div className="game-row-text">
                                <h3>{s.title}</h3>
                                <p>{s.description}</p>
                            </div>
                        </div>
                    })
                }
            </StandardInsert>
            <ItemPreview />
        </>
    </div>
}

const GameItem: React.FC<{ bin: TrashBin }> = ({ bin }) => {
    return <img className="task-301-pick-up" src={bin.src} alt="" />
}

const ItemPreview: React.FC<{}> = () => {
    return <Preview>{({ item, style }: any) => {
        return <div style={{ ...style, width: item.size.width }}>
            <GameItem bin={item.data} />
        </div>
    }}</Preview>
}

export default Task301Widget;
