import React, { useMemo } from "react";
import Generator from "../../components/generator";
import Logos3 from "../../components/logos/logos-3";
import Box from "../../layout/box";
import useBodyClass from "../../layout/hooks/use-body-class";
import useAwards from "../../modules/user/hooks/use-awards";
import './style.scss';

export default function GeneratorPage() {

    useBodyClass('no-image');

    const { awards, isLoading } = useAwards();


    const stickers = useMemo(() => {
        const free = [
            '0004',
            '0005',
            '0006',
            '0010',
            '0024'
        ];

        return [
            'a101',
            'a102',
            'a103',
            'a104',
            'a105',
            'a201',
            'a202',
            'a203a',
            'a203b',
            'a204',
            'a205',
            'a301',
            'a302',
            'a303',
            'a304',
            'a305',
            'a401',
            'a402',
            'a403',
            'a404',
            'a405',
            '0004',
            '0005',
            '0006',
            '0010',
            '0024',
        ].map(x => {
            return {
                src: `/assets/generator/awards/award-${x}.png`,
                iconSrc: `/assets/generator/awards/award-${x}.png`,
                key: x,
                isAvaiable: awards.includes(x) || free.includes(x),
                unavaialbeLabel: ''
            }
        })
    }, [awards])

    return <div className="page generator-page">
        <div className="container">
            <h1>Twoja leśna polana</h1>
            <p>
                Oto polana na której możesz budować leśne miasteczka, czy organizować spotkania dla mieszkańców lasu. Wypełnij ją elementami zdobytymi podczas misji, by stworzyć swoją wyjątkową krainę. Powodzenia!
            </p>
        </div>
        {!isLoading && <Generator stickers={stickers} showEndButton={true} />}

        <Box className='bg-white no-padding'>
            <Logos3 />
        </Box>
    </div>
}
