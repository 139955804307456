
import { useEffect, useRef, useState } from 'react';
import StandardInsert from '../../../../layout/standard-insert';
import createDrawingWidget from '../../../../widgets/drawing/create-widget';
import './style.scss';

type Props = {
    onComplete?: () => void
}

const img = {
    src: "/assets/tasks/task-104/labirynt.png",
    download: '/assets/tasks/task-104/labirynt.pdf'
}

const Task104Widget: React.FC<Props> = ({ onComplete: completeCallback }) => {

    const widgetContainer = useRef<HTMLDivElement>(null);


    const [api, setAPI] = useState<{ reset: () => void }>({ reset: () => { } })

    useEffect(() => {

        const gameConfig = {
            container: widgetContainer.current,
            image: img.src,
            width: 1300,
            height: 1119
        }

        const { destroy, reset } = createDrawingWidget(gameConfig)

        setAPI({
            reset
        })

        return () => {
            destroy();
        }

    }, []);

    useEffect(() => {
        if (completeCallback) {
            completeCallback();
        }
    }, [completeCallback])

    return <div className="task-104">
        <StandardInsert>
            <div ref={widgetContainer} className="gameContainer" style={{ width: '100%', margin: '0 auto', userSelect: 'none' }}></div>
            <div className="buttons">
                <a href={img.download} download="labirynt.pdf" className="button">Pobierz pdf</a>
                <button className="button" onClick={api.reset}>Resetuj</button>
            </div>
        </StandardInsert>
    </div>
}

export default Task104Widget;
