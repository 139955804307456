import { useRef, useCallback, useMemo } from "react";
import React from "react";
import GameComponent from "./game-component";
import { getEndGameRoute, getMapRoute } from "../../routes/routes";
import { Link } from "react-router-dom";
import './style.scss'
import useAchievements from "../../modules/user/hooks/use-achievements";
import jsPDF from "jspdf";

type Props = {
    stickers: {
        src: string;
        iconSrc: string;
        key: string;
        isAvaiable: boolean;
        unavaialbeLabel: any;
    }[],
    showEndButton: boolean
}

const Generator: React.FunctionComponent<Props> = ({ stickers, showEndButton }) => {
    const gameRef = useRef<any>();

    const { achievements } = useAchievements();

    const acvList = useMemo(() => {
        return [
            'achievement-3',
            'achievement-2',
            'achievement-4',
            'achievement-1',
        ];
    }, [])

    const onDownloadClick = useCallback((e: React.MouseEvent) => {
        e.preventDefault();
        gameRef.current.getImage().then((image: string | HTMLCanvasElement) => {

            var pdf = new jsPDF({
                orientation: 'landscape',
                format: 'a4'
            });

            const pageMargin = 15;
            const imageWidth = 267
            const imageHeight = 121;

            pdf.addImage({
                imageData: image,
                x: pageMargin,
                y: pageMargin,
                width: imageWidth,
                height: imageHeight
            });

            pdf.addImage({
                imageData: '/assets/generator/header.png',
                x: pageMargin + imageWidth * 573 / 1589,
                y: pageMargin + 2,
                width: imageWidth * 441 / 1589,
                height: imageHeight * 58 / 721
            });

            const acvPadding = imageWidth / 17;
            const acvWidth = acvPadding * 3;
            const smallAcvHeight = acvWidth * 0.9228;
            const bigAcvHeight = acvWidth * 1.1835;

            achievements.forEach((a, index) => {

                const acvHeight = (() => {
                    switch (a) {

                        case 'achievement-1':
                        case 'achievement-4':
                            return smallAcvHeight;

                        case 'achievement-2':
                        case 'achievement-3':
                        default:
                            return bigAcvHeight;
                    }
                })();

                const x = pageMargin + acvPadding + ((acvWidth + acvPadding) * index);
                const y = pageMargin + imageHeight + pageMargin / 2 + Math.max(bigAcvHeight, smallAcvHeight) / 2 - acvHeight / 2;

                pdf.addImage({
                    imageData: `/assets/achievements/${a}.png`,
                    x,
                    y,
                    width: acvWidth,
                    height: acvHeight
                })
            })

            pdf.setFontSize(10);
            pdf.text('Zapraszamy do gry www.niezasmiecamprzyrody.pl', 5, 205)


            pdf.save("polana.pdf");
        })
    }, [gameRef, achievements])

    const onDownloadAvcClick = useCallback(() => {
        var pdf = new jsPDF({
            orientation: 'landscape',
            format: 'a4'
        });

        pdf.addImage({
            imageData: '/assets/generator/header2.png',
            x: 120,
            y: 15,
            width: 55,
            height: 8.2
        });

        pdf.setFontSize(10);
        pdf.text('Zapraszamy do gry www.niezasmiecamprzyrody.pl', 5, 205)

        if (achievements.includes('achievement-1')) {
            pdf.addImage({
                imageData: `/assets/achievements/achievement-1.png`,
                x: 158,
                y: 124,
                width: 79.5,
                height: 73.5
            })
        }

        if (achievements.includes('achievement-2')) {
            pdf.addImage({
                imageData: `/assets/achievements/achievement-2.png`,
                x: 158,
                y: 25.5,
                width: 79.5,
                height: 92.7
            })
        }

        if (achievements.includes('achievement-3')) {
            pdf.addImage({
                imageData: `/assets/achievements/achievement-3.png`,
                x: 60.5,
                y: 25.5,
                width: 79.5,
                height: 92.7
            })
        }

        if (achievements.includes('achievement-4')) {
            pdf.addImage({
                imageData: `/assets/achievements/achievement-4.png`,
                x: 60.5,
                y: 124,
                width: 79.5,
                height: 73.5
            })
        }

        pdf.save("oznaki.pdf");

    }, [achievements])

    const onResetClick = useCallback((e: React.MouseEvent) => {
        e.preventDefault();
        gameRef.current.reset();
    }, [])

    return (
        <div className="generator-widget">
            <GameComponent stickers={stickers} ref={gameRef} />
            <div className="container">
                <div className="controls">
                    <div className="controls-group">
                        <button className="button" onClick={onResetClick}>Reset</button>
                        <button className="button" onClick={onDownloadClick}>Pobierz PDF</button>
                        <Link to={getMapRoute()} className="button">Wróć do mapy</Link>
                    </div>
                    <Link to={getEndGameRoute()} className="button">Zakończ grę</Link>
                </div>
                <div className="under">
                    <div className="achievements">
                        <div className="acv-box">
                            {
                                acvList.map((a) => {

                                    const active = achievements.includes(a);

                                    return <div className="acv" key={a}>
                                        <div className="acv-wrapper">
                                            <div className="acv-wrapper-2">
                                                <img src={`/assets/achievements/${a}.png`} alt="" style={active ? {} : {
                                                    filter: 'grayscale(100%)'
                                                }} />
                                            </div>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                        <button className="button" onClick={onDownloadAvcClick}>Pobierz PDF</button>
                    </div>
                    <div className="instruction">
                        <ul>
                            <li>Poniżej polany znajduje się lista elementów, które zebraliście podczas gry. Aby ją przeglądać, użyjcie strzałek po bokach.</li>
                            <li>Zdobyte elementy polany możecie umieszczać na niej wielokrotnie np. po zdobyciu wiaty możecie postawić po jednej w każdym rogu polany.</li>
                            <li>Jeśli zmienicie zdanie, możecie usunąć element, przesuwając go do kosza. Nie oznacza to jego utraty z listy.</li>
                            <li>W dowolnym momencie możecie pobrać obraz polany. Później możecie go wydrukować. W tym celu użyjcie przycisku z napisem “Pobierz PDF”.</li>
                            <li>Jeśli chcecie rozpocząć projektowanie polany od nowa, kliknijcie przycisk “Reset”.</li>
                            <li>Po prawej stronie znajdują się odznaki, które zdobyłeś w ciągu gry, aby je wydrukować naciśnij Pobierz PDF.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Generator;
