import { CrosswordField } from ".";

const crossword: CrosswordField[] = [
    {
        type: 'label',
        position: { x: 4, y: 0 },
        text: '3',
    },
    {
        type: 'input',
        position: { x: 4, y: 1 },
        correct: 'ś',
        chain: [{ id: 'smieciarka', index: 0 }]
    },
    {
        type: 'input',
        position: { x: 4, y: 2 },
        correct: 'm',
        chain: [{ id: 'smieciarka', index: 1 }]
    },
    {
        type: 'input',
        position: { x: 4, y: 3 },
        correct: 'i',
        chain: [{ id: 'smieciarka', index: 2 }]
    },
    {
        type: 'input',
        position: { x: 4, y: 4 },
        correct: 'e',
        chain: [
            { id: 'smieciarka', index: 3 },
            { id: 'butelka', index: 3 }
        ]
    },
    {
        type: 'input',
        position: { x: 4, y: 5 },
        correct: 'c',
        chain: [{ id: 'smieciarka', index: 4 }]
    },
    {
        type: 'input',
        position: { x: 4, y: 6 },
        correct: 'i',
        chain: [{ id: 'smieciarka', index: 5 }]
    },
    {
        type: 'input',
        position: { x: 4, y: 7 },
        correct: 'a',
        chain: [{ id: 'smieciarka', index: 6 }]
    },
    {
        type: 'input',
        position: { x: 4, y: 8 },
        correct: 'r',
        chain: [{ id: 'smieciarka', index: 7 }, { id: 'sortownia', index: 2 }]
    },
    {
        type: 'input',
        position: { x: 4, y: 9 },
        correct: 'k',
        chain: [{ id: 'smieciarka', index: 8 }]
    },
    {
        type: 'input',
        position: { x: 4, y: 10 },
        correct: 'a',
        chain: [
            { id: 'smieciarka', index: 9 },
            { id: 'polar', index: 3 }
        ]
    },

    {
        type: 'label',
        position: { x: 0, y: 4 },
        text: '1'
    },
    {
        type: 'input',
        position: { x: 1, y: 4 },
        correct: 'b',
        chain: [{ id: 'butelka', index: 0 }]
    },
    {
        type: 'input',
        position: { x: 2, y: 4 },
        correct: 'u',
        chain: [{ id: 'butelka', index: 1 }]
    },
    {
        type: 'input',
        position: { x: 3, y: 4 },
        correct: 't',
        chain: [{ id: 'butelka', index: 2 }]
    },
    // E
    {
        type: 'input',
        position: { x: 5, y: 4 },
        correct: 'l',
        chain: [{ id: 'butelka', index: 4 }]
    },
    {
        type: 'input',
        position: { x: 6, y: 4 },
        correct: 'k',
        chain: [{ id: 'butelka', index: 5 }]
    },
    {
        type: 'input',
        position: { x: 7, y: 4 },
        correct: 'a',
        chain: [
            { id: 'butelka', index: 6 }
        ]
    },


    {
        type: 'label',
        position: { x: 0, y: 10 },
        text: '8',
    },
    {
        type: 'input',
        position: { x: 1, y: 10 },
        correct: 'p',
        chain: [{ id: 'polar', index: 0 }]
    },
    {
        type: 'input',
        position: { x: 2, y: 10 },
        correct: 'o',
        chain: [{ id: 'polar', index: 1 }]
    },
    {
        type: 'input',
        position: { x: 3, y: 10 },
        correct: 'l',
        chain: [{ id: 'polar', index: 2 }]
    },
    {
        type: 'input',
        position: { x: 5, y: 10 },
        correct: 'r',
        chain: [{ id: 'polar', index: 4 }]
    },



    {
        type: 'label',
        position: { x: 1, y: 8 },
        text: '5',
    },
    {
        type: 'input',
        position: { x: 2, y: 8 },
        correct: 's',
        chain: [{ id: 'sortownia', index: 0 }]
    },
    {
        type: 'input',
        position: { x: 3, y: 8 },
        correct: 'o',
        chain: [{ id: 'sortownia', index: 1 }]
    },
    {
        type: 'input',
        position: { x: 5, y: 8 },
        correct: 't',
        chain: [{ id: 'sortownia', index: 3 }]
    },
    {
        type: 'input',
        position: { x: 6, y: 8 },
        correct: 'o',
        chain: [{ id: 'sortownia', index: 4 }]
    },
    {
        type: 'input',
        position: { x: 7, y: 8 },
        correct: 'w',
        chain: [{ id: 'sortownia', index: 5 }]
    },
    {
        type: 'input',
        position: { x: 8, y: 8 },
        correct: 'n',
        chain: [{ id: 'sortownia', index: 6 }]
    },
    {
        type: 'input',
        position: { x: 9, y: 8 },
        correct: 'i',
        chain: [{ id: 'sortownia', index: 7 }]
    },
    {
        type: 'input',
        position: { x: 10, y: 8 },
        correct: 'a',
        extraClass: 'extra-border-right',
        chain: [{ id: 'sortownia', index: 8 }]
    },
    {
        type: 'input',
        position: { x: 11, y: 8 },
        correct: 'o',
        extraClass: 'extra-border-left',
        chain: [{ id: 'sortownia', index: 9 }]
    },
    {
        type: 'input',
        position: { x: 12, y: 8 },
        correct: 'd',
        chain: [{ id: 'sortownia', index: 10 }]
    },
    {
        type: 'input',
        position: { x: 13, y: 8 },
        correct: 'p',
        chain: [{ id: 'sortownia', index: 11 }]
    },
    {
        type: 'input',
        position: { x: 14, y: 8 },
        correct: 'a',
        chain: [{ id: 'sortownia', index: 12 }, { id: 'makulatura', index: 5 }]
    },
    {
        type: 'input',
        position: { x: 15, y: 8 },
        correct: 'd',
        chain: [{ id: 'sortownia', index: 13 }]
    },
    {
        type: 'input',
        position: { x: 16, y: 8 },
        correct: 'ó',
        chain: [{ id: 'sortownia', index: 14 }]
    },
    {
        type: 'input',
        position: { x: 17, y: 8 },
        correct: 'w',
        chain: [{ id: 'sortownia', index: 15 }]
    },


    // ==================================================================================

    {
        type: 'label',
        position: { x: 6, y: 10 },
        text: '2',
    },
    {
        type: 'input',
        position: { x: 7, y: 10 },
        correct: 'a',
        chain: [{ id: 'aluminium', index: 0 }]
    },
    {
        type: 'input',
        position: { x: 8, y: 10 },
        correct: 'l',
        chain: [{ id: 'aluminium', index: 1 }]
    },
    {
        type: 'input',
        position: { x: 9, y: 10 },
        correct: 'u',
        chain: [{ id: 'aluminium', index: 2 }]
    },
    {
        type: 'input',
        position: { x: 10, y: 10 },
        correct: 'm',
        chain: [{ id: 'aluminium', index: 3 }]
    },
    {
        type: 'input',
        position: { x: 11, y: 10 },
        correct: 'i',
        chain: [{ id: 'aluminium', index: 4 }]
    },
    {
        type: 'input',
        position: { x: 12, y: 10 },
        correct: 'n',
        chain: [{ id: 'aluminium', index: 5 }]
    },
    {
        type: 'input',
        position: { x: 13, y: 10 },
        correct: 'i',
        chain: [{ id: 'aluminium', index: 6 }]
    },
    {
        type: 'input',
        position: { x: 14, y: 10 },
        correct: 'u',
        chain: [{ id: 'aluminium', index: 7 }, { id: 'makulatura', index: 7 }]
    },
    {
        type: 'input',
        position: { x: 15, y: 10 },
        correct: 'm',
        chain: [{ id: 'aluminium', index: 8 }]
    },


    {
        type: 'label',
        position: { x: 10, y: 12 },
        text: '4',
    },
    {
        type: 'input',
        position: { x: 11, y: 12 },
        correct: 'h',
        chain: [{ id: 'huta', index: 0 }]
    },
    {
        type: 'input',
        position: { x: 12, y: 12 },
        correct: 'u',
        chain: [{ id: 'huta', index: 1 }]
    },
    {
        type: 'input',
        position: { x: 13, y: 12 },
        correct: 't',
        chain: [{ id: 'huta', index: 2 }]
    },
    {
        type: 'input',
        position: { x: 14, y: 12 },
        correct: 'a',
        chain: [{ id: 'huta', index: 3 }, { id: 'makulatura', index: 9 }]
    },

    //============================================================================================

    {
        type: 'label',
        position: { x: 9, y: 5 },
        text: '6',
    },
    {
        type: 'input',
        position: { x: 10, y: 5 },
        correct: 'u',
        chain: [{ id: 'upcykling', index: 0 }]
    },
    {
        type: 'input',
        position: { x: 11, y: 5 },
        correct: 'p',
        chain: [{ id: 'upcykling', index: 1 }]
    },
    {
        type: 'input',
        position: { x: 12, y: 5 },
        correct: 'c',
        chain: [{ id: 'upcykling', index: 2 }]
    },
    {
        type: 'input',
        position: { x: 13, y: 5 },
        correct: 'y',
        chain: [{ id: 'upcykling', index: 3 }]
    },
    {
        type: 'input',
        position: { x: 14, y: 5 },
        correct: 'k',
        chain: [{ id: 'upcykling', index: 4 }, { id: 'makulatura', index: 2 }]
    },
    {
        type: 'input',
        position: { x: 15, y: 5 },
        correct: 'l',
        chain: [{ id: 'upcykling', index: 5 }]
    },
    {
        type: 'input',
        position: { x: 16, y: 5 },
        correct: 'i',
        chain: [{ id: 'upcykling', index: 6 }]
    },
    {
        type: 'input',
        position: { x: 17, y: 5 },
        correct: 'n',
        chain: [{ id: 'upcykling', index: 7 }]
    },
    {
        type: 'input',
        position: { x: 18, y: 5 },
        correct: 'g',
        chain: [{ id: 'upcykling', index: 8 }]
    },

    //=========================================================================================

    {
        type: 'label',
        position: { x: 14, y: 2 },
        text: '7',
    },
    {
        type: 'input',
        position: { x: 14, y: 3 },
        correct: 'm',
        chain: [{ id: 'makulatura', index: 0 }]
    },
    {
        type: 'input',
        position: { x: 14, y: 4 },
        correct: 'a',
        chain: [{ id: 'makulatura', index: 1 }]
    },


    {
        type: 'input',
        position: { x: 14, y: 6 },
        correct: 'u',
        chain: [{ id: 'makulatura', index: 3 }]
    },
    {
        type: 'input',
        position: { x: 14, y: 7 },
        correct: 'l',
        chain: [{ id: 'makulatura', index: 4 }]
    },


    {
        type: 'input',
        position: { x: 14, y: 9 },
        correct: 't',
        chain: [{ id: 'makulatura', index: 6 }]
    },


    {
        type: 'input',
        position: { x: 14, y: 11 },
        correct: 'r',
        chain: [{ id: 'makulatura', index: 8 }]
    },

]

export default crossword;
