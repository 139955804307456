
import React, { useEffect, useRef, useState } from 'react';
import { GeoTask } from '../../../../../../../modules/game/types';
import GeoTrashTask from '../trash';
import createWidget from './create-widget';
import './style.scss';

type Props = {
    onComplete: () => void,
    task: GeoTask
}

const AnimalWidget: React.FC<Props> = ({ onComplete, task }) => {

    const widgetContainer = useRef<HTMLDivElement>(null);
    const [view, setView] = useState('animal');

    useEffect(() => {

        const gameConfig = {
            container: widgetContainer.current,
            onComplete: () => {setView('trash')}
        }

        const { destroy } = createWidget(gameConfig)

        return () => {
            destroy();
        }

    }, [onComplete, setView]);

    return <div className="geo-task-animal">
        {(view === 'animal') && <div ref={widgetContainer} className="gameContainer" style={{ width: '100%', margin: '0 auto', userSelect: 'none' }}></div>}
        {(view === 'trash') && <GeoTrashTask task={task} onComplete={onComplete} />}
    </div>
}

export default AnimalWidget;
