import * as PIXI from 'pixi.js-legacy';
import { WidgetConfig } from '../create-widget';

export default class TaskWidget extends PIXI.Application {
    public baseWidth: number;
    public baseHeight: number;

    constructor(width: number, height: number, private config: WidgetConfig) {
        super({
            width,
            height,
            transparent: true,
            antialias: true
        });

        this.baseHeight = height;
        this.baseWidth = width;

        this.loadAssets([
            ['image', this.config.image]
        ]).then((resources) => {
            this.initWidget();
        })
    }

    private canvas = document.createElement('canvas');
    private ctx = this.canvas.getContext('2d');

    private canvasTexture = PIXI.Texture.from(this.canvas);

    private initWidget() {
        const image = PIXI.Sprite.from('image');

        this.canvas.width = this.baseWidth;
        this.canvas.height = this.baseHeight;

        let isDrawing = false;
        const lastPos = new PIXI.Point();
        const ctx = this.ctx;
        const texture = this.canvasTexture;
        const path = new PIXI.Sprite(texture);

        this.stage.interactive = true;

        this.stage.on('pointerdown', (e: PIXI.InteractionEvent) => {
            lastPos.copyFrom(e.data.getLocalPosition(this.stage));
            isDrawing = true;
        });

        this.stage.on('pointerup', () => {
            isDrawing = false;
        })

        this.stage.on('pointermove', (e: PIXI.InteractionEvent) => {

            if (isDrawing) {
                const pos = e.data.getLocalPosition(this.stage).clone();

                const lineWidth = 10;

                if (ctx) {
                    ctx.beginPath();
                    ctx.lineWidth = 0;
                    ctx.arc(pos.x, pos.y, lineWidth/2, 0, 2 * Math.PI, false);
                    ctx.fillStyle = '#643726';
                    ctx.fill();

                    ctx.beginPath();
                    ctx.moveTo(lastPos.x, lastPos.y);
                    ctx.lineTo(pos.x, pos.y);

                    ctx.strokeStyle = '#643726';
                    ctx.lineWidth = lineWidth;
                    ctx.stroke();
                }

                lastPos.copyFrom(pos);
            }

            texture.update();
        });

        this.stage.addChild(image);
        this.stage.addChild(path);
    }

    public reset() {
        const ctx = this.ctx;
        ctx?.clearRect(0,0,this.baseWidth, this.baseHeight);
        this.canvasTexture.update();
    }

    private loadAssets(assets: any[]) {

        return new Promise(resolve => {
            assets.forEach(assetInfo => {
                this.loader.add(...assetInfo);
            })

            this.loader.load((loader, resource) => {
                resolve(null);
            })

            this.loader.onError.add((...args: any[]) => {
                console.error(...args);
            })

        })

    }

    public destroy() {
        super.destroy(true);
    }
}
