import React, { useCallback, useEffect, useState } from "react";
import { useReducer } from "react";
import useCamera from "../../../../modules/camera/use-camera";
import VideoComponent from "./video";
import CameraOn from '../../../../../assets/geo/camera-on.svg';
import CameraOff from '../../../../../assets/geo/camera-off.svg';
import CameraRotate from '../../../../../assets/geo/camera-rotate.svg';
import useConfig from "../../../../modules/config/hooks/use-config";
import useSetSettings from "../../../../modules/config/hooks/use-set-settings";

type Props = {
    onPermitionError: () => void
}

const BackgroundVideo: React.FC<Props> = ({ onPermitionError }) => {

    const { videoInputDevices, error, srcObject, nextDevice } = useCamera();
    const [permitionDenied, setPermitionDenied] = useState(false);

    const [showVideo, toggleVideo] = useReducer((state: boolean, action: boolean | null) => {
        return action === null ? !state : action;
    }, true);

    const showPermitionInfo = useCallback(() => {
        onPermitionError();
    }, [onPermitionError]);

    const onToggleVideoClick = useCallback(() => {
        if (permitionDenied) {
            showPermitionInfo()
        } else {
            toggleVideo(null)
        }
    }, [toggleVideo, showPermitionInfo, permitionDenied]);

    const onPermitionDenied = useCallback(() => {
        toggleVideo(false)
        setPermitionDenied(true);
        onPermitionError();
    }, [setPermitionDenied, onPermitionError, toggleVideo])

    useEffect(() => {
        if (error?.name === 'NotAllowedError') {
            onPermitionDenied();
        }
    }, [onPermitionDenied, error])

    return <>
        {showVideo && <VideoComponent srcObject={srcObject as MediaStream} />}

        <div className="background-controlls">
            <input type="image" src={showVideo ? CameraOff : CameraOn} onClick={onToggleVideoClick} alt="" />
            {showVideo && (videoInputDevices.length > 1) && <input type="image" src={CameraRotate} onClick={nextDevice} alt="" />}
        </div>
    </>

}

const BackgroundComponent: React.FC<Props> = ({ onPermitionError }) => {

    const { settings } = useConfig();
    const setSettings = useSetSettings();

    return <>
        {settings.tryCamera && <BackgroundVideo onPermitionError={onPermitionError} />}
        {!settings.tryCamera && <div className="background-controlls">
            <input type="image" src={CameraOn} onClick={() => setSettings({tryCamera: true})} alt="" />
        </div>}
    </>
}

export default BackgroundComponent;
