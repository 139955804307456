import * as PIXI from 'pixi.js-legacy';

export class Board extends PIXI.Container {
    private boardBackgroundSprite?: PIXI.Sprite;
    constructor(props: {
        texture: PIXI.Texture;
        onChange: () => void;
    }) {
        super();

        this.boardBackgroundSprite = new PIXI.Sprite(props.texture)
        this.addChild(this.boardBackgroundSprite);

        //@ts-ignore
        // window['xxxx'] = this;
    }

    public getYCorrection(): number {
        if (this.boardBackgroundSprite) {
            const top = this.getLocalBounds().y;
            return -top;
        }

        return 0;
    }

    public hideUX() {

    }
    public showUX() {
    }

    public getBoardSprite() {
        return this.boardBackgroundSprite;
    }
}
