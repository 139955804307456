import { GeoGameState, GeoGameAction, GEO_GAME_ACTION, initGeoGameModuleState } from ".";
import { VIEW } from "./types";

export default function GeoGameReducer(state: GeoGameState, action: GeoGameAction): GeoGameState {

    const { type, payload } = action;
    switch (type) {
        case GEO_GAME_ACTION.START_GAME:
            return {
                ...state,
                started: true,
            }

        case GEO_GAME_ACTION.SHOW_VIEW:
            const { view } = payload;

            return {
                ...state,
                view
            }

        case GEO_GAME_ACTION.RESTART:
            return {
                ...initGeoGameModuleState,
                started: true
            }

        case GEO_GAME_ACTION.SET_GAME_POINTS:
            if (state.gamePoints === null) {
                return {
                    ...state,
                    gamePoints: payload.gamePoints
                }
            }
            break;

        case GEO_GAME_ACTION.SHOW_TASK:
            return {
                ...state,
                view: VIEW.TAKS
            }

        case GEO_GAME_ACTION.COMPLETE_POINT:
            return {
                ...state,
                gamePoints: state.gamePoints?.map(p => {
                    if (p.id === payload.id) {
                        return {
                            ...p,
                            completed: true,
                        }
                    }

                    return { ...p };
                }) || null,
                view: VIEW.COMPASS
            }
    }

    return state;
}




/*
const gamePoints = state.gameMode === GEO_GAME_MODE.LIPKOW ?
                [
                    [52.28031610294377, 20.80738869265754],
                    [52.28085433858946, 20.808389156588472],
                    [52.28022913132655, 20.808692246197577],
                    [52.2805688119574, 20.807799070623847],
                    [52.2799879072685, 20.807852714802454]
                ].map((p, index) => {
                    return {
                        lat: p[0],
                        lng: p[1],
                        id: `${index}`,
                        completed: false
                    }
                })
                : getGamePointsBerings()
                    .map(v => getPoint(v, payload.distance, payload.position))
                    .filter(Boolean as any as <T>(x: T | null) => x is T)
                    .map((v, index) => {
                        return {
                            ...v,
                            id: `${index}`,
                            completed: false
                        }
                    })
*/
